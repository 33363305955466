import useCameraTypeUpdate from "./hooks/useCameraTypeUpdate";

const CameraTypeUpdate = () => {
  const hookMember = useCameraTypeUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <>
      {
        <>
          <div>
            <div className="input-table">
              <div>
                <label>
                  모델 이름<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={hookMember.modelName}
                  onChange={(e) => hookMember.setModelName(e.target.value)}
                />
              </div>
              <div>
                <label>업체</label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={hookMember.company}
                  onChange={(e) => hookMember.setCompany(e.target.value)}
                />
              </div>
              <div>
                <label>최대 가로 해상도</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.frameWidthMax}
                  onChange={(e) => hookMember.setFrameWidthMax(e.target.value)}
                />
              </div>
              <div>
                <label>최대 세로 해상도</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.frameHeightMax}
                  onChange={(e) => hookMember.setFrameHeightMax(e.target.value)}
                />
              </div>
              <div>
                <label>연결 타입</label>
                <select
                  name=""
                  id=""
                  value={hookMember.connectionType}
                  onChange={(e) => hookMember.setConnectionType(e.target.value)}
                >
                  <option value="USB">USB</option>
                  <option value="Ethernet">Ethernet</option>
                </select>
              </div>
              <div>
                <label>최대 FPS</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.maxFPS}
                  onChange={(e) => hookMember.setMaxFPS(e.target.value)}
                />
              </div>
              <div>
                <label>가격</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.price}
                  onChange={(e) => hookMember.setPrice(e.target.value)}
                />
              </div>
              <div>
                <label>대각선 화각</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.diagonalAngle}
                  onChange={(e) => hookMember.setDiagonalAngle(e.target.value)}
                />
              </div>
              <div>
                <label>가로 화각</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.horizontalAngle}
                  onChange={(e) =>
                    hookMember.setHorizontalAngle(e.target.value)
                  }
                />
              </div>
              <div>
                <label>세로 화각</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.verticalAngle}
                  onChange={(e) => hookMember.setVerticalAngle(e.target.value)}
                />
              </div>
            </div>
            <div className="buttonDiv">
              <button onClick={handleCancel}>취소</button>
              <button onClick={hookMember.updateCameraTypeData}>저장</button>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default CameraTypeUpdate;
