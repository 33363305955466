import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
  company: string;
  setCompany: any;
  adminName: string;
  setAdminName: any;
  adminPhone: string;
  setAdminPhone: any;
  adminPosition: string;
  setAdminPosition: any;
  remainMoney: number;
  setRemainMoney: any;

  errorMessage: any;

  createAdvertiser: () => void;
}

const useAdvertiserCreate = (): hookMember => {
  const navigate = useNavigate();

  const [company, setCompany] = useState<string>("");
  const [adminName, setAdminName] = useState<string>("");
  const [adminPhone, setAdminPhone] = useState<string>("");
  const [adminPosition, setAdminPosition] = useState<string>("");
  const [remainMoney, setRemainMoney] = useState<number>(0);

  const [errorMessage, setErrorMessage] = useState("");

  const createAdvertiser = async () => {
    try {
      if (adminName === "") {
        setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/advertiser/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              company,
              adminName,
              adminPhone,
              adminPosition,
              remainMoney,
            }),
          }
        );
        if (response.ok) {
          navigate("/adComponent/advertiser/advertiserList");
        }
      }
    } catch (error) {
      console.error("네트워크 기기 생성 중 오류 발생:", error);
    }
  };
  return {
    company,
    setCompany,
    adminName,
    setAdminName,
    adminPhone,
    setAdminPhone,
    adminPosition,
    setAdminPosition,
    remainMoney,
    setRemainMoney,
    createAdvertiser,
    errorMessage,
  };
};

export default useAdvertiserCreate;
