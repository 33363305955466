import { useNavigate, useParams } from "react-router-dom";
import FilterDropdown from "../../../../components/atoms/filterDropdown/FilterDropdown";
import { useMediaVersionHistory } from "../../hooks/useMediaVersionHistory";
import {
  CustomColumnProps,
  DynamicTable,
} from "../../../../components/table/dynamicTable";
import { countTrueValues } from "../../../../util/countTrueValues";
import { useMediaVersionHistoryDetail } from "../../hooks/useMediaVersionHistoryDetail";
import ListModal from "../../../../components/modal/list/list";

// 내부 컴포넌트 정의
export default function MediaVersionHistoryDetil() {
  const { mediaId } = useParams<{ mediaId: string }>(); // useParams의 타입을 명시적으로 지정
  const numericMediaId = mediaId ? Number(mediaId) : undefined; // mediaId가 존재하면 숫자로 변환
  const navigate = useNavigate();
  const {
    selectedMediaDetails,
    historyTableData,
    columns,
    setSelectedMediaDetails,
    searchTerm,
    setSearchTerm,
    setHistoryTableData,
    isOpenResourceHistory,
    setIsOpenResourceHistory,
    viewResourceList,
    selectedResource,
    resourceList,
  } = useMediaVersionHistoryDetail(numericMediaId);

  const resourceDummyData = [
    { name: "Resource 1", type: "Image", url: "#" },
    { name: "Resource 2", type: "Document", url: "#" },
    { name: "Resource 3", type: "Video", url: "#" },
  ];
  const CustomButton: React.FC<{ item: any }> = ({ item }) => (
    <button onClick={() => viewResourceList(item)}>리소스 보기</button>
  );

  let customColumns: CustomColumnProps[] = [];

  if (
    historyTableData &&
    historyTableData.length > 0 &&
    Object.keys(historyTableData[0]).length > 0
  ) {
    customColumns = [
      {
        index: Object.keys(historyTableData[0]).length,
        rowText: "리소스 리스트 보기",
        component: CustomButton,
      },
    ];
  }

  return (
    <div className="container">
      <div className="header">
        <h2>
          {selectedMediaDetails[0]?.name} -{" "}
          {selectedMediaDetails[0]?.authNumber}
        </h2>
        <FilterDropdown
          columns={columns}
          searchTerm={searchTerm}
          tableData={historyTableData}
          setTableData={setHistoryTableData}
          allData={selectedMediaDetails}
        />
      </div>
      <div className="table-container">
        <DynamicTable data={historyTableData} customColumns={customColumns} />
      </div>

      <ListModal
        isOpen={isOpenResourceHistory}
        onClose={() => setIsOpenResourceHistory(false)}
        title="Resource List"
        children={
          <ul>
            {resourceList.map((resource: any, index: any) => (
              <li
                key={index}
                style={{ color: resource.highlight ? "red" : "black" }}
              >
                <strong>{resource.name}</strong> - {resource.updatedAt} -{" "}
                {resource.versionNumber && (
                  <span>
                    {resource.isTest ? "테스트 버전 " : ""}
                    {resource.versionNumber} ( 업데이트 시점 )
                  </span>
                )}
              </li>
            ))}
          </ul>
        }
      ></ListModal>
    </div>
  );
}
