import { useEffect, useState } from "react";

enum CameraTypeProperties {
  MODEL_NAME = "모델명",
  COMPANY = "업체",
  FRAME_WIDTH_MAX = "최대 가로 해상도	",
  FRAME_HEIGHT_MAX = "최대 세로 해상도",
  CONNECTION_TYPE = "연결 타입",
  MAX_FPS = "최대 FPS",
  PRICE = "가격",
  DIAGONAL_ANGLE = "대각선 화각",
  HORIZONTAL_ANGLE = "가로 화각	",
  VERTICAL_ANGLE = "세로 화각",
}

const useCameraTypeList = (initialPage: number) => {
  const transformData = (
    rawData: any[]
  ): Record<CameraTypeProperties, any>[] => {
    return rawData.map((item) => ({
      [CameraTypeProperties.MODEL_NAME]: item.modelName,
      [CameraTypeProperties.COMPANY]: item.company,
      [CameraTypeProperties.FRAME_WIDTH_MAX]: item.frameWidthMax,
      [CameraTypeProperties.FRAME_HEIGHT_MAX]: item.frameHeightMax,
      [CameraTypeProperties.CONNECTION_TYPE]: item.connectionType,
      [CameraTypeProperties.MAX_FPS]: item.maxFPS,
      [CameraTypeProperties.PRICE]: item.price,
      [CameraTypeProperties.DIAGONAL_ANGLE]: item.diagonalAngle,
      [CameraTypeProperties.HORIZONTAL_ANGLE]: item.horizontalAngle,
      [CameraTypeProperties.VERTICAL_ANGLE]: item.verticalAngle,
    }));
  };
  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (cameraTypeId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/camera-type/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cameraTypeId: cameraTypeId }),
      })
        .then(async (response) => {
          if ((await response.json()) === 1451) {
            // 키 참조 에러처리
            alert("삭제에 실패했습니다.");
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          alert("삭제에 실패하였습니다.");
        });
    }
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/camera-type/list?page=${page}`
      );
      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }
      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/camera-type/list`
      );
      const countJsonData = await countResponse.json();

      const totalDataCount = countJsonData.data.count;
      setCount(totalDataCount);
      // 모든 데이터를 가져온 경우 fetch 종료
      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
    handleDeleteClick,
    isFetchingComplete,
  };
};

export default useCameraTypeList;
