import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
    cpvId: number;
    setCpvId: any;
    businessTypeId: number;
    setBusinessTypeId: any;
    businessTypeList: any;
    setBusinessTypeList: any;
    siteFieldId: number;
    setSiteFieldId: any;
    siteFieldList: any;
    setSiteFieldList: any;
    watchedUnitPrice: number;
    setWatchedUnitPrice: any;
    attentionedUnitPrice: number;
    setAttentionedUnitPrice: any;
    exposedUnitPrice: number;
    setExposedUnitPrice: any;

  errorMessage: any;

  updateCpvData: () => void;
}

const useCpvUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [cpvId, setCpvId] = useState<number>(0);
  const [businessTypeId, setBusinessTypeId] = useState<number>(0);
  const [businessTypeList, setBusinessTypeList] = useState([]);
  const [siteFieldId, setSiteFieldId] = useState<number>(0);
  const [siteFieldList, setSiteFieldList] = useState([]);
  const [watchedUnitPrice, setWatchedUnitPrice] = useState<number>(0);
  const [attentionedUnitPrice, setAttentionedUnitPrice] = useState<number>(0);
  const [exposedUnitPrice, setExposedUnitPrice] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.cpvId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/cpv/one?id=${params.cpvId}`
          );
          const jsonData = await response.json();
          const businessTypeListResponse = await fetch(
            `${process.env.REACT_APP_URL}/business-type/all`
          );
          const businessTypeListData = await businessTypeListResponse.json();
  
          setBusinessTypeList(businessTypeListData);
  
          const siteFieldListResponse = await fetch(
            `${process.env.REACT_APP_URL}/site-field/all`
          );
          const siteFieldListData = await siteFieldListResponse.json();
  
          setSiteFieldList(siteFieldListData);

          const {
            cpvId,
            businessTypeId,
            siteFieldId,
            watchedUnitPrice,
            attentionedUnitPrice,
            exposedUnitPrice,
          } = jsonData.data;

          setCpvId(cpvId);
          setBusinessTypeId(businessTypeId);
          setSiteFieldId(siteFieldId);
          setWatchedUnitPrice(watchedUnitPrice);
          setAttentionedUnitPrice(attentionedUnitPrice);
          setExposedUnitPrice(exposedUnitPrice);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.cpvId]);

  const updateCpvData = () => {
    const result = {
      cpvId,
      businessTypeId,
      siteFieldId,
      watchedUnitPrice,
      attentionedUnitPrice,
      exposedUnitPrice,
    };

    if (!businessTypeId || !siteFieldId) {
        setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
        return;
      }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/cpv/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cpvId,
            businessTypeId,
            siteFieldId,
            watchedUnitPrice,
            attentionedUnitPrice,
            exposedUnitPrice,
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/cpv/cpvList");
      }
    });
  };

  return {
    cpvId,
    setCpvId,
    businessTypeId,
    setBusinessTypeId,
    businessTypeList,
    setBusinessTypeList,
    siteFieldId,
    setSiteFieldId,
    siteFieldList,
    setSiteFieldList,
    watchedUnitPrice,
    setWatchedUnitPrice,
    attentionedUnitPrice,
    setAttentionedUnitPrice,
    exposedUnitPrice,
    setExposedUnitPrice,
    errorMessage,
    updateCpvData,
  };
};

export default useCpvUpdate;
