import useAdVideoInfoCreate from "./hooks/useAdVideoInfoCreate";

const AdVideoInfoCreate = () => {
  const hookMember = useAdVideoInfoCreate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>동영상 가로 해상도</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.frameWidth}
            onChange={(e) => hookMember.setFrameWidth(e.target.value)}
          />
        </div>
        <div>
          <label>동영상 세로 해상도</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.frameHeight}
            onChange={(e) => hookMember.setFrameHeight(e.target.value)}
          />
        </div>
        <div>
          <label>동영상 포맷</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.videoFormat}
            onChange={(e) => hookMember.setVideoFormat(e.target.value)}
          />
        </div>
        <div>
          <label>동영상 비율</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.ratio}
            onChange={(e) => hookMember.setRatio(e.target.value)}
          />
        </div>
        <div>
          <label>동영상 저장 경로</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.path}
            onChange={(e) => hookMember.setPath(e.target.value)}
          />
        </div>
        <div>
          <label>카테고리</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.category}
            onChange={(e) => hookMember.setCategory(e.target.value)}
          />
        </div>
        <div>
          <label>핵심 색상</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.focusColor}
            onChange={(e) => hookMember.setFocusColor(e.target.value)}
          />
        </div>
        <div>
          <label>키워드</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.keyword}
            onChange={(e) => hookMember.setKeyword(e.target.value)}
          />
        </div>
        <div>
          <label>분위기</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.vibe}
            onChange={(e) => hookMember.setVibe(e.target.value)}
          />
        </div>
        <div>
          <label>
            광고 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.adId}
            onChange={(e) => {
              hookMember.setAdId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.adList.data) &&
              hookMember.adList.data.map(
                (item: { adId: number; videoName: string }) => (
                  <option key={item.adId} value={item.adId}>
                    {item.videoName}
                  </option>
                )
              )}
          </select>
        </div>
        <div>
          <label>동영상 용량</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.volume}
            onChange={(e) => hookMember.setVolume(e.target.value)}
          />
        </div>
        <div>
          <label>총 프레임 수</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.videoFrameCount}
            onChange={(e) => hookMember.setVideoFrameCount(e.target.value)}
          />
        </div>
        <div>
          <label>동영상 길이</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.videoPlayTime}
            onChange={(e) => hookMember.setVideoPlayTime(e.target.value)}
          />
        </div>
        <div>
          <label>동적 특성</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.changedCount}
            onChange={(e) => hookMember.setChangedCount(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.createAdVideoInfo}>저장</button>
      </div>
    </div>
  );
};

export default AdVideoInfoCreate;
