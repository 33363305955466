import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
  medias: any;
  date: string;
  setDate: any;
  loading: boolean;
  sort: string;
  setSort: any;
}

interface medias {
  authNumber: number;
  name: string;
  app: string;
  data: string;
  network: string;
}

const useDailyDashboard = (): hookMember => {
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<medias[]>([]);
  const [medias, setMedias] = useState<medias[]>([]);
  const [date, setDate] = useState<string>("");
  const [sort, setSort] = useState<"" | "all" | "app" | "data" | "network">("");
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async (page: any, date: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/dashboard/daily?page=${page}&date=${date}`
      );
      const jsonData = await response.json();
      setLoading(false);
      setData(jsonData);
      //   console.log(jsonData);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      setData([]);
    }
  };

  useEffect(() => {
    if (date === "") {
      let now = new Date();
      let yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
      let yesterdayText = `${yesterday.getFullYear()}-${
        yesterday.getMonth() < 9
          ? "0" + (yesterday.getMonth() + 1)
          : yesterday.getMonth() + 1
      }-${
        yesterday.getDate() < 10
          ? "0" + yesterday.getDate()
          : yesterday.getDate()
      }`;

      setDate(yesterdayText);
    }

    setLoading(true);
    fetchData(page, date);
  }, [page, date]);

  useEffect(() => {
    if (sort === "") {
      setMedias(data);
    } else if (sort === "all") {
      let filter = data.filter((media) => {
        if (media.app !== "O" || media.data !== "O" || media.network !== "O") {
          return true;
        } else {
          return false;
        }
      });
      setMedias(filter);
    } else {
      let filter = data.filter((media) => {
        if (media[sort] !== "O") {
          return true;
        } else {
          return false;
        }
      });
      setMedias(filter);
    }
  }, [data, sort]);

  return { medias, date, setDate, loading, sort, setSort };
};

export default useDailyDashboard;
