import React from "react";

interface TableType {
  [key: string]: string;
}

interface CustomComponentProps {
  item: TableType;
  rowIndex: number;
}

interface CustomColumnProps {
  index: number;
  rowText?: string;
  component?: React.FC<CustomComponentProps>;
}

interface TableRowProps {
  item: TableType;
  customColumns?: CustomColumnProps[];
  rowIndex: number;
}

export const TableRow: React.FC<TableRowProps> = ({
  item,
  customColumns,
  rowIndex,
}) => {
  const values = Object.values(item);

  const rowElements: React.ReactNode[] = [];

  let valueIndex = 0;

  for (let i = 0; i < values.length + (customColumns?.length || 0); i++) {
    const customColumn = customColumns?.find((col) => col.index === i);

    if (customColumn) {
      rowElements.push(
        <td key={`custom-${i}`} className="custom-column">
          {customColumn.component && (
            <>
              <customColumn.component item={item} rowIndex={rowIndex} />
            </>
          )}
        </td>
      );
    } else {
      rowElements.push(<td key={`value-${i}`}>{values[valueIndex]}</td>);
      valueIndex++;
    }
  }

  return <tr>{rowElements}</tr>;
};
