import { useEffect, useState } from "react";

interface hookMember {
  mediaList: any[];
  media: number;
  setMedia: (media: number) => void;
  loading: boolean;
  date: string;
  setDate: (date: string) => void;
  pathArray: any[];
  createPathArray: (point: any) => void;
  initPathArray: () => void;
  returnPathArray: () => void;
  savePathArray: () => void;
  test: any[];
  strokeWeight: number;
  refresh: () => void;
  polyline: boolean;
  setPolyline: (polyline: boolean) => void;
  level: number;
  setLevel: (level: number) => void;
  polylineWeight: number;
  setPolylineWeight: (polylineWeight: number) => void;
}

const useDalgoTDashboard = (): hookMember => {
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [media, setMedia] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [date, setDate] = useState<string>("");
  const [pathArray, setPathArray] = useState<any[]>([]);
  const [test, setTest] = useState<any[]>([]);
  const [polyline, setPolyline] = useState<boolean>(true); // 경로 범위 표시 여부
  const [strokeWeight, setStrokeWeight] = useState<number>(0); // 경로 범위 두께 설정 (m)
  const [level, setLevel] = useState<number>(6); // 지도 레벨(줌) 1~7
  const [polylineWeight, setPolylineWeight] = useState<number>(100); // 경로 범위 두께 설정 (px)

  const fetchMediaList = async () => {
    if (!loading) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/media/byBusiness?service=DalgoT`
      );
      const jsonData = await response.json();

      if (jsonData.status == 200) {
        setMedia(jsonData.data[0].authNumber);
        setMediaList(jsonData.data);
        setLoading(false);
      } else {
        setMedia(0);
        setMediaList([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPathArray = async () => {
    if (!date) return;
    if (!media) return;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/dashboard/dalgoT/path?media=${media}&date=${date}`
      );
      const jsonData = await response.json();

      // console.log(jsonData);
      if (jsonData.status === 200) {
        setPathArray(jsonData.data);
      } else {
        setPathArray([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const savePathArray = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/dashboard/dalgoT/path?media=${media}&date=${date}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pathArray),
        }
      );
      const jsonData = await response.json();
      if (jsonData.status === 200) {
        alert(`${media}_PATH_${date}.json 저장 성공`);
        refresh();
      } else {
        alert("저장 실패");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const checkPathArray = async () => {
    if (!date) return;
    if (!media) return;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/dashboard/dalgoT/path_check?media=${media}&date=${date}`
      );
      const jsonData = await response.json();
      if (jsonData.status === 200) {
        setTest(jsonData.data);
      } else {
        setTest([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const createPathArray = (point: any) => {
    // console.log({ lat: point.getLat(), lng: point.getLng() });
    var a = JSON.parse(JSON.stringify(pathArray));
    a.push({ lat: point.getLat(), lng: point.getLng() });
    setPathArray(a);
  };

  const initPathArray = () => {
    setPathArray([]);
  };

  const returnPathArray = () => {
    var a = JSON.parse(JSON.stringify(pathArray));
    a.pop();
    setPathArray(a);
  };

  const refresh = () => {
    getPathArray();
    checkPathArray();
  };

  useEffect(() => {
    if (date === "") {
      const today = `${new Date().getFullYear()}-${
        new Date().getMonth() > 8
          ? new Date().getMonth() + 1
          : "0" + (new Date().getMonth() + 1)
      }-${
        new Date().getDate() > 9
          ? new Date().getDate()
          : "0" + new Date().getDate()
      }`;

      setDate(today);
    }

    fetchMediaList();
  }, []);

  useEffect(() => {
    getPathArray();
    checkPathArray();
  }, [media, date]);

  useEffect(() => {
    let weight = (15 * polylineWeight) / 2 ** level;
    setStrokeWeight(weight);
  }, [level, polylineWeight]);

  return {
    mediaList,
    media,
    setMedia,
    loading,
    date,
    setDate,
    pathArray,
    createPathArray,
    initPathArray,
    returnPathArray,
    savePathArray,
    test,
    strokeWeight,
    refresh,
    polyline,
    setPolyline,
    level,
    setLevel,
    polylineWeight,
    setPolylineWeight,
  };
};

export default useDalgoTDashboard;
