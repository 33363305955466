import useAdCorpFieldUpdate from "./hooks/useAdCorpFieldUpdate";

const AdCorpFieldUpdate = () => {
  const hookMember = useAdCorpFieldUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>
            섹션<span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.section}
            onChange={(e) => hookMember.setSection(e.target.value)}
          />
        </div>
        <div>
          <label>
            산업 그룹
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.industryGroup}
            onChange={(e) => hookMember.setIndustryGroup(e.target.value)}
          />
        </div>
        <div>
          <label>
            산업
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.industry}
            onChange={(e) => hookMember.setIndustry(e.target.value)}
          />
        </div>
        <div>
          <label>
            세부 산업
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.subIndustry}
            onChange={(e) => hookMember.setSubIndustry(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.updateAdCorpFieldData}>저장</button>
      </div>
    </div>
  );
};

export default AdCorpFieldUpdate;
