import { useEffect, useState } from "react";

const useList = () => {
  const [list, setList] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/media-point/list?page=${page}`
      );
      const jsonData = await response.json();

      setList(jsonData.data.list);
      setCount(jsonData.data.count);
      setLoading(false);
    } catch (error) {
      setList([]);
      setCount(0);
      console.log("Error", error);
    }
  };

  const deleteData = async (mediaId: number) => {
    if (window.confirm(`매체 좌표를 삭제하시겠습니까?`)) {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/media-point/delete`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: mediaId,
          }),
        }
      );
      alert("삭제가 완료되었습니다.");
    } else {
      alert("취소되었습니다.");
    }
  };

  useEffect(() => {
    fetchData(0);
  }, []);

  return {
    list,
    count,
    loading,
    deleteData,
  };
};

export default useList;
