import useAIDeviceTypeCreate from "./hooks/useAIDeviceTypeCreate";
import "../aiDevice/css/aiDeviceCreate.scss";

const AIDeviceTypeCreate = () => {
  const hookMember = useAIDeviceTypeCreate();

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && <div style={{ color: "red" }}>{hookMember.errorMessage}</div>}
        <div>
          <label>
            모델명<span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.modelName}
            onChange={(e) => hookMember.setModelName(e.target.value)}
          />
        </div>
        <div>
          <label>
            업체 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.aiDeviceCompanyId}
            onChange={(e) => {
              hookMember.setAIDeviceCompanyId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.aiDeviceCompanyList.data) &&
              hookMember.aiDeviceCompanyList.data.map((item: { aiDeviceCompanyId: number; name: string }) => (
                <option key={item.aiDeviceCompanyId} value={item.aiDeviceCompanyId}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label>가격</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.price}
            onChange={(e) => hookMember.setPrice(e.target.value)}
          />
        </div>
        <div>
          <label>라벨 Prefix</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.labelPrefix}
            onChange={(e) => hookMember.setLabelPrefix(e.target.value)}
          />
        </div>
        <div>
          <label>
            AI 기기 모듈 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.aiModuleId}
            onChange={(e) => {
              hookMember.setAIModuleId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.aiModuleList.data) &&
              hookMember.aiModuleList.data.map((item: { aiModuleId: number; name: string }) => (
                <option key={item.aiModuleId} value={item.aiModuleId}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.createAIDeviceType}>저장</button>
      </div>
    </div>
  );
};

export default AIDeviceTypeCreate;
