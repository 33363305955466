import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
  mediaList: any;
  setMediaList: any;
  mediaId: number;
  setMediaId: any;
  aiDeviceList: any;
  setAiDeviceList: any;
  aiDeviceId: any;
  setAiDeviceId: any;
  aiDeviceLabel: string;
  setAiDeviceLabel: any;
  networkDeviceId: number;
  setNetworkDeviceId: any;
  networkDeviceList: any;
  setNetworkDeviceList: any;
  networkDeviceLabel: string;
  setNetworkDeviceLabel: any;
  cameraId: number;
  setCameraId: any;
  cameraList: any;
  setCameraList: any;
  cameraLabel: string;
  setCameraLabel: any;
  comment: string;
  setComment: any;
  images: File[];
  setImages: any;
  videos: File[];
  setVideos: any;
  workDate: Date;
  setWorkDate: any;

  errorMessage: any;

  createInspection: () => void;
}

const useInspectionCreate = (): hookMember => {
  const navigate = useNavigate();

  const [mediaList, setMediaList] = useState([]);
  const [mediaId, setMediaId] = useState<number>(0);
  const [aiDeviceList, setAiDeviceList] = useState([]);
  const [aiDeviceId, setAiDeviceId] = useState<number>(0);
  const [networkDeviceId, setNetworkDeviceId] = useState<number>(0);
  const [networkDeviceList, setNetworkDeviceList] = useState([]);
  const [networkDeviceLabel, setNetworkDeviceLabel] = useState<string>("");
  const [cameraId, setCameraId] = useState<number>(0);
  const [cameraList, setCameraList] = useState([]);
  const [cameraLabel, setCameraLabel] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [images, setImages] = useState<File[]>([]);
  const [videos, setVideos] = useState<File[]>([]);
  const [aiDeviceLabel, setAiDeviceLabel] = useState<string>("");
  const [workDate, setWorkDate] = useState<Date>(new Date());

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const mediaListResponse = await fetch(
          `${process.env.REACT_APP_URL}/media/all`
        );
        const mediaListData = await mediaListResponse.json();

        setMediaList(mediaListData);

        const aiDeviceListResponse = await fetch(
          `${process.env.REACT_APP_URL}/ai-device/selectable`
        );
        const aiDeviceListData = await aiDeviceListResponse.json();

        setAiDeviceList(aiDeviceListData);

        const networkDeviceListResponse = await fetch(
          `${process.env.REACT_APP_URL}/network-device/all`
        );
        const networkDeviceListData = await networkDeviceListResponse.json();

        setNetworkDeviceList(networkDeviceListData);

        const cameraListResponse = await fetch(
          `${process.env.REACT_APP_URL}/camera/all`
        );
        const cameraListData = await cameraListResponse.json();

        setCameraList(cameraListData);

      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSelectData = async () => {
      const selectMediaResponse = await fetch(
        `${process.env.REACT_APP_URL}/media/one?id=${mediaId}`
      );

        const reportResponse = await fetch(
          `${process.env.REACT_APP_URL}/install-report/inspect-one?mediaId=${mediaId}&category=install`
        );

      const selectMediaData = await selectMediaResponse.json();
      const reportData = await reportResponse.json();

      if(selectMediaData && selectMediaData.data && reportData && reportData.data) {
        if (selectMediaData.data.aiDeviceId) {
          setAiDeviceLabel(selectMediaData.data.aiDevice.label);
          setAiDeviceId(selectMediaData.data.aiDeviceId);
        }
        if (reportData.data.cameraId) {
          setCameraId(reportData.data.cameraId);
          setCameraLabel(reportData.data.camera.label);
        }
        if (reportData.data.networkDeviceId) {
          setNetworkDeviceId(reportData.data.networkDeviceId);
          setNetworkDeviceLabel(reportData.data.networkDevice.label);
        }
      } else {
        setAiDeviceId(0);
        setAiDeviceLabel("");
        setCameraId(0);
        setCameraLabel("");
        setNetworkDeviceId(0);
        setNetworkDeviceLabel("");
      }
    };
    fetchSelectData();
  }, [mediaId]);

  const createInspection = async () => {
    try {
      if (!mediaId) {
        setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      } else {
        const commonBody = {
          mediaId,
          category: "inspection",
          comment,
          aiDeviceId,
          cameraId,
          networkDeviceId,
          workDate,
        };

        const imageFormData = new FormData();
        imageFormData.append("mediaId", mediaId.toString());
        imageFormData.append("category", "inspection");
        imageFormData.append("type", "images");
        images.forEach((image) => {
          imageFormData.append("files", image);
        });

        const videoFormData = new FormData();
        videoFormData.append("mediaId", mediaId.toString());
        videoFormData.append("category", "inspection");
        videoFormData.append("type", "videos");
        videos.forEach((video) => {
          videoFormData.append("files", video);
        });

        const response = await fetch(
          `${process.env.REACT_APP_URL}/install-report/create-inspection`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(commonBody),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to create actual image");
        }

        const imageResponse = await fetch(
          `${process.env.REACT_APP_URL}/install-report/upload-files`,
          {
            method: "POST",
            body: imageFormData,
          }
        );

        if (!imageResponse.ok) {
          throw new Error("Failed to upload images");
        }

        const videoResponse = await fetch(
          `${process.env.REACT_APP_URL}/install-report/upload-files`,
          {
            method: "POST",
            body: videoFormData,
          }
        );

        if (!videoResponse.ok) {
          throw new Error("Failed to upload videos");
        }

        navigate("/installReport/installReportList");
      }
    } catch (error) {
      setErrorMessage("네트워크 기기 생성 중 오류 발생");
    }
  };

  return {
    mediaList,
    setMediaList,
    mediaId,
    setMediaId,
    aiDeviceList,
    setAiDeviceList,
    aiDeviceId,
    setAiDeviceId,
    aiDeviceLabel,
    setAiDeviceLabel,
    networkDeviceId,
    setNetworkDeviceId,
    networkDeviceList,
    setNetworkDeviceList,
    cameraId,
    setCameraId,
    cameraList,
    setCameraList,
    networkDeviceLabel,
    setNetworkDeviceLabel,
    cameraLabel,
    setCameraLabel,
    comment,
    setComment,
    createInspection,
    images,
    setImages,
    videos,
    setVideos,
    workDate,
    setWorkDate,
    errorMessage,
  };
};

export default useInspectionCreate;
