import { useEffect, useState } from "react";
import axios from "axios";

interface CsvData {
  type: string;
  name: string;
  path: string;
}

const useCsvInquiryList = () => {
  const [selectedBusinessType, setSelectedBusinessType] = useState<string>("");
  const [selectedCsvType, setSelectedCsvType] = useState<string>("");
  const [csvTypeOptions, setCsvTypeOptions] = useState<string[]>([]);
  const [csvData, setCsvData] = useState<CsvData[]>([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [dateIncrement, setDateIncrement] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [selectedMediaNumber, setSelectedMediaNumber] = useState<string>("");
  const [mediaNumberOptions, setMediaNumberOptions] = useState<string[]>([]);
  const [dateArr, setDateArr] = useState<string[]>([]);
  const [fileLine, setFileLine] = useState<string>("");

  const setDateRangeFromToday = (days: number) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - days);
    setDateIncrement(days);
    setDateRange([startDate, endDate]);
  };

  const shiftDateRange = (direction: "left" | "right") => {
    let [start, end] = dateRange;

    if (!(start && end)) {
      start = new Date();
      end = new Date();
      end.setDate(start.getDate() + 1);
    }
    const shiftAmount = direction === "left" ? -dateIncrement : dateIncrement;

    const newStartDate = new Date(start.getTime());
    newStartDate.setDate(newStartDate.getDate() + shiftAmount);

    const newEndDate = new Date(end.getTime());
    newEndDate.setDate(newEndDate.getDate() + shiftAmount);

    setDateRange([newStartDate, newEndDate]);
  };

  useEffect(() => {
    const fetchCsvData = async () => {
      if (selectedBusinessType && selectedCsvType) {
        try {
          let [startDate, endDate] = dateRange;

          if (startDate) {
            // startDate에서 시간을 제거하고 날짜만 남김
            startDate = new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate()
            );
          }

          if (endDate) {
            // endDate에서 시간을 제거하고 날짜만 남김
            endDate = new Date(
              endDate.getFullYear(),
              endDate.getMonth(),
              endDate.getDate()
            );
          }

          const params: any = {
            businessType: selectedBusinessType,
            csvType: selectedCsvType,
          };

          if (startDate)
            params.startDate = startDate.toLocaleDateString("en-CA");
          if (endDate) params.endDate = endDate.toLocaleDateString("en-CA");

          if (selectedMediaNumber) {
            params.mediaNumber = selectedMediaNumber;
          }

          const response = await axios.get(
            `${process.env.REACT_APP_URL}/inquiry-server/get-csv`,
            { params }
          );

          setCsvData(response.data);
          setCurrentPage(1);
        } catch (error) {
          console.error("Error fetching csv data:", error);
        }
      }
    };

    fetchCsvData();
  }, [selectedBusinessType, selectedCsvType, dateRange, selectedMediaNumber]);

  const handleBusinessType = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const businessType = event.target.value;
    setSelectedBusinessType(businessType);
    const fileType = "csv";

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/inquiry-server/get-filter`,
        {
          params: { businessType, fileType },
        }
      );

      setCsvTypeOptions(response.data.types);
    } catch (error) {
      console.error("Error fetching csv types:", error);
    }
  };

  const handleMediaNumbers = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedMediaNumber(event.target.value);
  };

  const fetchFileLine = async (filePath: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/inquiry-server/get-file-line`,
        {
          params: { filePath },
        }
      );
      setFileLine(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    const fetchMediaNumber = async () => {
      if (selectedBusinessType && selectedCsvType) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_URL}/inquiry-server/get-filter`,
            {
              params: {
                businessType: selectedBusinessType,
                fileType: "csv",
              },
            }
          );
          setMediaNumberOptions(response.data.mediaNumbers);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchMediaNumber();
  }, [selectedBusinessType, selectedCsvType]);

  useEffect(() => {
    if (selectedMediaNumber) {
      fetchDateArr();
    }
  }, [selectedMediaNumber]);

  const fetchDateArr = async () => {
    if (selectedBusinessType && selectedCsvType && selectedMediaNumber) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/inquiry-server/get-file-date`,
          {
            params: {
              businessType: selectedBusinessType,
              fileType: "csv",
              filterType: selectedCsvType,
              mediaNumber: selectedMediaNumber,
            },
          }
        );
        setDateArr(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCsvType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCsvType(event.target.value);
  };

  const handleDownloadCsv = async (event: any) => {
    const filePath = event;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/inquiry-server/download-file`,
        {
          params: { filePath },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filePath.split("/").pop());
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error fetching download csv:", error);
    }
  };

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadCsv = async () => {
    if (!selectedFile) {
      alert("파일을 선택해주세요.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/data/inquiry-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("파일 업로드 성공");
      window.location.reload();
    } catch (error) {
      console.error("Error uploading csv file:", error);
      alert("파일 업로드 실패");
    }
  };

  const resetFilters = () => {
    setSelectedBusinessType("");
    setSelectedCsvType("");
    setSearchQuery("");
    setSelectedMediaNumber("");
    setCurrentPage(1);
  };

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const filteredCsvData = csvData.filter((csv) =>
    csv.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCsvData = filteredCsvData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredCsvData.length / itemsPerPage);

  return {
    selectedBusinessType,
    selectedCsvType,
    csvTypeOptions,
    csvData,
    dateRange,
    resetFilters,
    shiftDateRange,
    setDateRange,
    setDateRangeFromToday,
    handleBusinessType,
    handleCsvType,
    handleDownloadCsv,
    handleUploadCsv,
    handleFileChange,
    setSearchQuery: handleSearchQueryChange,
    searchQuery,
    currentCsvData,
    currentPage,
    totalPages,
    setCurrentPage,
    mediaNumberOptions,
    selectedMediaNumber,
    handleMediaNumbers,
    dateArr,
    fetchFileLine,
    fileLine
  };
};

export default useCsvInquiryList;
