import { useEffect, useState } from "react";

interface Camera {
  cameraId: number;
  label: string;
  currentStatus: string;
  currentFPS: number;
  currentFrameWidth: number;
  currentFrameHeight: number;
  aiDeviceId: number;
  cameraTypeId: number;
  aiDevice: string;
  cameraType: string;
}

enum CameraProperties {
  LABEL = "라벨",
  CURRENT_FRAME_WIDTH = "현재 해상도 가로",
  CURRENT_FRAME_HEIGHT = "현재 해상도 세로",
  AI_DEVICE = "AI 기기",
  CAMERA_TYPE = "카메라 종류",
  CURRENT_STATUS = "현재 상태",
  CURRENT_FPS = "현재 FPS",
}

const useCameraList = (initialPage: number) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (cameraId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/camera/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cameraId: cameraId }),
      })
        .then((response) => {
          if (response.ok) {
            window.location.reload();
          } else {
            alert("삭제에 실패하였습니다.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("삭제에 실패하였습니다.");
        });
    }
  };

  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const transformData = (cameraData: any[]): any[] => {
    return cameraData.map((camera: any) => ({
      [CameraProperties.LABEL]: camera.label,
      [CameraProperties.CURRENT_FRAME_WIDTH]: camera.currentFrameWidth,
      [CameraProperties.CURRENT_FRAME_HEIGHT]: camera.currentFrameHeight,
      [CameraProperties.AI_DEVICE]: camera.aiDevice
        ? camera.aiDevice.label
        : "N/A", // aiDevice 객체의 label 사용
      [CameraProperties.CAMERA_TYPE]: camera.cameraType
        ? camera.cameraType.modelName
        : "N/A", // cameraType 객체의 modelName 사용
      [CameraProperties.CURRENT_STATUS]: camera.currentStatus,
      [CameraProperties.CURRENT_FPS]: camera.currentFPS,
    }));
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/camera/list?page=${page}`
      );
      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      //transformData enum으로 정의된 컬럼으로 테이블 구성
      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/camera/list`
      );
      const countJsonData = await countResponse.json();

      const totalDataCount = countJsonData.data.count;
      setCount(totalDataCount);
      // 모든 데이터를 가져온 경우 fetch 종료
      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    handleDeleteClick,
    columns,
    tableData,
    setTableData,
    isFetchingComplete,
  };
};

export default useCameraList;
