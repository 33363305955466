import useCreate from "./hooks/useCreate";

const MediaPointCreate = () => {
  const hookMember = useCreate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <>
      {
        <>
          <div>
            <div className="input-table">
              <div>
                <label>매체</label>
                <select
                  name=""
                  id=""
                  value={hookMember.mediaId}
                  onChange={(e) => hookMember.setMediaId(e.target.value)}>
                  {hookMember.data.map((item: any, index: number) => {
                    return (
                      <option value={item.mediaId}>
                        {item.authNumber}) {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label>point1_x</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.inputData[0]}
                  onChange={(e) =>
                    hookMember.changeInput(0, Number(e.target.value))
                  }
                />
                {/* <div>{hookMember.data.point1_x}</div> */}
              </div>
              <div>
                <label>point1_y</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.inputData[1]}
                  onChange={(e) =>
                    hookMember.changeInput(1, Number(e.target.value))
                  }
                />
              </div>
              <div>
                <label>point2_x</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.inputData[2]}
                  onChange={(e) =>
                    hookMember.changeInput(2, Number(e.target.value))
                  }
                />
              </div>
              <div>
                <label>point2_y</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.inputData[3]}
                  onChange={(e) =>
                    hookMember.changeInput(3, Number(e.target.value))
                  }
                />
              </div>
            </div>
          </div>
          <div className="buttonDiv">
            <button onClick={handleCancel}>취소</button>
            <button onClick={hookMember.updateData}>저장</button>
          </div>
        </>
      }
    </>
  );
};

export default MediaPointCreate;
