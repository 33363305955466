// tree.tsx
import { Node } from "./node";

export const Tree = ({ tree, onClick, onRightClick, selectedPath }: any) => {
  return (
    <ul>
      {tree.children.map((node: any) => (
        <Node
          key={node.name}
          node={node}
          onClick={onClick}
          onRightClick={onRightClick}
          selectedPath={selectedPath}
          parentPath=""
        />
      ))}
    </ul>
  );
};
