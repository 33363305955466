import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  aiModuleId: number;
  setAiModuleId: any;
  name: string;
  setName: any;
  type: string;
  setType: any;
  company: string;
  setCompany: any;
  fp32: string;
  setFp32: any;
  fp16: string;
  setFp16: any;
  int8: string;
  setInt8: any;

  errorMessage: any;

  updateAiModuleData: () => void;
}

const useAiModuleUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [aiModuleId, setAiModuleId] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [fp32, setFp32] = useState<string>("");
  const [fp16, setFp16] = useState<string>("");
  const [int8, setInt8] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.aiModuleId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/ai-module/one?id=${params.aiModuleId}`
          );
          const jsonData = await response.json();

          const {
            aiModuleId,
            name,
            type,
            company,
            fp32,
            fp16,
            int8
          } = jsonData.data;

          setAiModuleId(aiModuleId);
          setName(name);
          setType(type);
          setCompany(company);
          setFp32(fp32);
          setFp16(fp16);
          setInt8(int8);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.aiModuleId]);

  const updateAiModuleData = () => {
    const result = {
      aiModuleId,
      name,
      type,
      company,
      fp32,
      fp16,
      int8
    };

    if (!name) {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/ai-module/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            aiModuleId,
            name,
            type,
            company,
            fp32,
            fp16,
            int8
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/aiComponent/aiModule/aiModuleList");
      }
    });
  };

  return {
    aiModuleId,
    setAiModuleId,
    name,
    setName,
    type,
    setType,
    company,
    setCompany,
    fp32,
    setFp32,
    fp16,
    setFp16,
    int8,
    setInt8,
    errorMessage,
    updateAiModuleData,
  };
};

export default useAiModuleUpdate;
