import useAiModuleCreate from "./hooks/useAiModuleCreate";

const AiModuleCreate = () => {
  const hookMember = useAiModuleCreate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>모듈명 <span className="required">*</span></label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.name}
            onChange={(e) => hookMember.setName(e.target.value)}
          />
        </div>
        <div>
          <label>종류</label>
          <select
            name=""
            id=""
            value={hookMember.type}
            onChange={(e) => hookMember.setType(e.target.value)}
          >
            <option value="">선택해 주세요</option>
            <option value="GPU">GPU</option>
            <option value="NPU">NPU</option>
            <option value="GPGPU">GPGPU</option>
          </select>
        </div>
        <div>
          <label>업체</label>
          <select
            name=""
            id=""
            value={hookMember.company}
            onChange={(e) => hookMember.setCompany(e.target.value)}
          >
            <option value="">선택해 주세요</option>
            <option value="NVIDIA">NVIDIA</option>
            <option value="HAILO">HAILO</option>
          </select>
        </div>
        <div>
          <label>FP32 성능</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.fp32}
            onChange={(e) => hookMember.setFp32(e.target.value)}
          />
        </div>
        <div>
          <label>FP16 성능</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.fp16}
            onChange={(e) => hookMember.setFp16(e.target.value)}
          />
        </div>
        <div>
          <label>INT8 성능</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.int8}
            onChange={(e) => hookMember.setInt8(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.createAiModule}>저장</button>
      </div>
    </div>
  );
};

export default AiModuleCreate;
