// VersionManager.tsx
import React from "react";
import useVersionManager from "../hooks/useVersionManager";
import { ContextMenu } from "./contextMenu";
import { Tree } from "./tree";
import { FileViewer } from "./fileViewer";
import "../css/versionManager.scss";
import { FileConflictDialog } from "./fileConflictDialog";
import MemoCard from "../../../components/modal/memoCard/memoCard";

const VersionManager = () => {
  const hookMember = useVersionManager();

  if (!hookMember.fileTree) return <div>Loading...</div>;

  return (
    <div className="file-tree-container">
      {hookMember.isOpenMemoModal && (
        <MemoCard
          title={"슬랙에 보낼 메시지"}
          description={"버전매니저 봇이 보낼 메세지를 작성해주세요"}
          memoText={hookMember.memoText}
          handleMemoChange={hookMember.handleMemoChange}
          handleSaveMemo={hookMember.handleSaveMemo}
          handleCancle={hookMember.handleCancelMemo}
        />
      )}

      {hookMember.isHashLoading && (
        <div className="loading-overlay">
          <div>
            <div className="spinner"></div>
          </div>
          <p className="loading-text">{hookMember.loadingText}</p>
        </div>
      )}
      {hookMember.isFileConflict && (
        <div>
          <FileConflictDialog
            isOpen={hookMember.isFileConflict}
            fileName={hookMember.conflictFileName}
            newVersion={hookMember.newFileVersion}
            onOverwrite={hookMember.handleOverwrite}
            onUpdateVersion={hookMember.handleUpdateVersion}
            onCancel={hookMember.handleCancel}
          ></FileConflictDialog>
        </div>
      )}

      <input
        type="file"
        ref={hookMember.fileInputRef}
        style={{ display: "none" }}
        onChange={hookMember.handleFileChange}
        key={hookMember.key}
      />
      {hookMember.uploadProgress > 0 && hookMember.uploadProgress !== 100 && (
        <div className="progress-bar-fixed">
          <div
            className="progress-bar-fill"
            style={{ width: `${hookMember.uploadProgress}%` }}
          >
            {hookMember.uploadProgress}%
          </div>
        </div>
      )}
      <div className="file-tree">
        <Tree
          tree={hookMember.fileTree}
          onClick={hookMember.handleFolderClick}
          onRightClick={hookMember.handleRightClick}
          selectedPath={hookMember.selectedPath}
        />
      </div>
      <div className="file-viewer">
        <FileViewer
          fileTree={hookMember.fileTree}
          selectedPath={hookMember.selectedPath}
        />
      </div>
      <ContextMenu
        position={hookMember.contextMenuPosition}
        isFile={hookMember.isFile}
        onUploadClick={hookMember.handleUploadClick}
        onCreateFolderClick={() => {}}
        onClose={hookMember.handleCloseContextMenu}
      />
    </div>
  );
};

export default VersionManager;
