import React, { useState } from "react";
import useLogInquiryList from "./hooks/useLogInquiryList";
import { useLogModal } from "./hooks/useLogModal";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "./css/logInquiryList.scss";
import LogModal from "./logModal";
import { ko } from "date-fns/locale";

registerLocale("ko", ko);

const LogInquiryList: React.FC = () => {
  const {
    selectedBusinessType,
    selectedLogType,
    logTypeOptions,
    dateRange,
    errorRatio,
    resetFilters,
    shiftDateRange,
    setDateRange,
    setDateRangeFromToday,
    handleBusinessType,
    handleLogType,
    handleDownloadLog,
    handleUploadLog,
    handleFileChange,
    setSearchQuery,
    searchQuery,
    currentLogData,
    currentPage,
    totalPages,
    setCurrentPage,
    fetchErrorRatio,
    mediaNumberOptions,
    selectedMediaNumber,
    handleMediaNumbers,
    dateArr,
  } = useLogInquiryList();

  const { isModalOpen, modalContent, openModalWithLogData, closeModal } =
    useLogModal();

  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const isDateWithoutLog = (date: Date) => {
    return dateArr.some((d) => (new Date(d)).toDateString() === date.toDateString());
  };

  return (
    <div className="inquiry-container">
      <h1>LOG 조회</h1>
      <select
        id="businessType"
        value={selectedBusinessType}
        onChange={handleBusinessType}
      >
        <option value="">비즈니스 타입 선택</option>
        <option value="DalgoD">DalgoD</option>
        <option value="DalgoT">DalgoT</option>
        <option value="BrandDP">BrandDP</option>
      </select>
      <select id="logType" value={selectedLogType} onChange={handleLogType}>
        <option value="">LOG 타입 선택</option>
        {logTypeOptions.map((logType, index) => (
          <option key={index} value={logType}>
            {logType}
          </option>
        ))}
      </select>
      <select
        id="mediaNumber"
        value={selectedMediaNumber}
        onChange={handleMediaNumbers}
      >
        <option value="">매체 번호 선택</option>
        {mediaNumberOptions.map((mediaNumber, index) => (
          <option key={index} value={mediaNumber}>
            {mediaNumber}
          </option>
        ))}
      </select>
      <input
        className="input-field"
        type="text"
        placeholder="파일명 검색"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <button onClick={resetFilters}>초기화</button>

      <p className="info-message">
        비즈니스 타입, LOG 타입, 매체 번호를 선택한 후 아래 날짜 선택 칸을
        클릭하면 파일의 존재 여부를 확인할 수 있습니다.
      </p>
      <div className="date-range-buttons">
        <button onClick={() => shiftDateRange("left")}>←</button>
        <ReactDatePicker
          selected={dateRange[0]}
          onChange={(update) =>
            setDateRange(update as [Date | null, Date | null])
          }
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          selectsRange
          dateFormat="yyyy/MM/dd"
          isClearable
          className="inquiry-datepicker"
          locale="ko"
          dayClassName={(date) =>
            isDateWithoutLog(date) ? "has-log-date" : "no-log-date"
          }
        />
        <button onClick={() => shiftDateRange("right")}>→</button>
        <button onClick={() => setDateRangeFromToday(1)}>지난 1일</button>
        <button onClick={() => setDateRangeFromToday(7)}>지난 7일</button>
        <button onClick={() => setDateRangeFromToday(30)}>지난 30일</button>
      </div>

      <div className="file-upload">
        <input type="file" accept=".log" onChange={handleFileChange} />
        <button onClick={handleUploadLog}>업로드 확인</button>
      </div>

      <table className="table-row-hover">
        <thead>
          <tr>
            <th>LOG</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {selectedBusinessType && selectedLogType ? (
            currentLogData.length > 0 ? (
              currentLogData.map((log, index) => (
                <tr
                  key={index}
                  onClick={() => openModalWithLogData(log)}
                  onMouseEnter={() => {
                    fetchErrorRatio(log.path);
                    setHoveredRow(log.path);
                  }}
                  onMouseLeave={() => setHoveredRow(null)}
                  className="log-row"
                >
                  <td>
                    {log.name}
                    <div
                      className="error-ratio-tooltip"
                      style={{
                        display: hoveredRow === log.path ? "block" : "none",
                      }}
                    >
                      에러 비율: {errorRatio} %
                    </div>
                  </td>
                  <td>
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDownloadLog(log.path);
                      }}
                    >
                      다운로드
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={2}
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  파일이 존재하지 않습니다.
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={2} style={{ textAlign: "center", padding: "20px" }}>
                비즈니스 타입과 LOG 타입을 선택해주세요.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination-controls">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          이전
        </button>
        <span>
          {currentPage} / {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          다음
        </button>
      </div>

      <LogModal
        isOpen={isModalOpen}
        onClose={closeModal}
        content={modalContent}
      />
    </div>
  );
};

export default LogInquiryList;
