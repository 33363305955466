import { useState, useEffect } from "react";

interface SortConfig {
  index: number | null;
  direction: "asc" | "desc" | null;
}

interface UseSortResult<T> {
  sortedData: T[];
  sortConfig: SortConfig;
  onSort: (index: number) => void;
}

export const useSort = <T extends { [key: string]: any }>(
  data: T[]
): UseSortResult<T> => {
  const [sortedData, setSortedData] = useState<T[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    index: null,
    direction: null,
  });

  useEffect(() => {
    setSortedData(data);
    setSortConfig({ index: null, direction: null });
  }, [data]);

  const onSort = (index: number) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig.index === index && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sorted = [...data].sort((a, b) => {
      const aValue = Object.values(a)[index];
      const bValue = Object.values(b)[index];

      // 숫자인 경우 숫자 비교
      if (!isNaN(parseFloat(aValue)) && !isNaN(parseFloat(bValue))) {
        const numA = parseFloat(aValue);
        const numB = parseFloat(bValue);

        if (numA < numB) {
          return direction === "asc" ? -1 : 1;
        }
        if (numA > numB) {
          return direction === "asc" ? 1 : -1;
        }
      } else {
        // 문자열인 경우 문자열 비교 (한글 또는 알파벳)
        if (aValue < bValue) {
          return direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === "asc" ? 1 : -1;
        }
      }

      return 0;
    });

    setSortConfig({ index, direction });
    setSortedData(sorted);
  };
  return { sortedData, sortConfig, onSort };
};
