import axios from "axios";

export const FileViewer = ({ fileTree, selectedPath }: any) => {
  const handleDownload = async () => {
    const token = sessionStorage.getItem("token");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/version/download`,
        {
          responseType: "blob", // Blob 형태로 응답받기 위해 설정
          params: { filePath: selectedPath },
          headers: { token },
        }
      );

      const disposition = response.headers["content-disposition"];
      const selectedItem = getItemAtPath(fileTree, selectedPath);
      let fileName = selectedItem.name;
      if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }

      // 브라우저에서 파일을 다운로드하도록 생성
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // 다운로드 받을 파일 이름 설정
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    } catch (error) {
      console.error("파일 다운로드 중 오류 발생:", error);
    }
  };

  const getItemAtPath = (tree: any, path: string) => {
    if (!tree || !path) return null; // 트리 또는 경로가 없으면 null 반환
    const parts = path.split("/").filter(Boolean); // 빈 문자열 제거
    let currentItem = tree;
    for (const part of parts) {
      if (currentItem.children) {
        currentItem = currentItem.children.find(
          (child: any) => child.name === part
        );
        if (!currentItem) {
          return null;
        }
      } else {
        return null;
      }
    }
    return currentItem;
  };

  const selectedItem = getItemAtPath(fileTree, selectedPath);
  if (selectedItem && !selectedItem.children) {
    return (
      <div className="file-content">
        <pre>{selectedItem.name} 을 선택하였습니다</pre>
        <button onClick={handleDownload}>다운로드 버튼</button>
      </div>
    );
  } else {
    return (
      <div className="file-content">
        <p>No file selected.</p>
      </div>
    );
  }
};
