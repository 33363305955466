import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  adId: number;
  setAdId: any;
  videoName: string;
  setVideoName: any;
  brand: string;
  setBrand: any;
  price: number;
  setPrice: any;
  remainMoney: number;
  setRemainMoney: any;
  showType: string;
  setShowType: any;
  purposeType: string;
  setPurposeType: any;
  geographicalScoreType: string;
  setGeographicalScoreType: any;
  customerType: string;
  setCustomerType: any;
  targetGender: string;
  setTargetGender: any;
  targetAge: string;
  setTargetAge: any;
  targetSeason: string;
  setTargetSeason: any;
  targetIncome: string;
  setTargetIncome: any;
  targetTime: string;
  setTargetTime: any;
  targetPlace: string;
  setTargetPlace: any;
  targetArea: string;
  setTargetArea: any;
  corpFieldId: number;
  setCorpFieldId: any;
  corpFieldList: any;
  setCorpFieldList: any;
  targetCorpField: number;
  setTargetCorpField: any;
  repeatTime: number;
  setRepeatTime: any;
  advertiserId: number;
  setAdvertiserId: any;
  advertiserList: any;
  setAdvertiserList: any;
  adFieldId: number;
  setAdFieldId: any;
  adFieldList: any;
  setAdFieldList: any;
  status: string;
  setStatus: any;
  playStartDate: string;
  setPlayStartDate: any;
  playEndDate: string;
  setPlayEndDate: any;
  isFree: number;
  setIsFree: any;
  createdAt: string;
  setCreatedAt: any;
  type: string;
  setType: any;

  errorMessage: any;

  updateAdData: () => void;
}

const useAdUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [adId, setAdId] = useState<number>(0);
  const [videoName, setVideoName] = useState<string>("");
  const [brand, setBrand] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [remainMoney, setRemainMoney] = useState<number>(0);
  const [showType, setShowType] = useState<string>("");
  const [purposeType, setPurposeType] = useState<string>("");
  const [geographicalScoreType, setGeographicalScoreType] =
    useState<string>("");
  const [customerType, setCustomerType] = useState<string>("");
  const [targetGender, setTargetGender] = useState<string>("");
  const [targetAge, setTargetAge] = useState<string>("");
  const [targetSeason, setTargetSeason] = useState<string>("");
  const [targetIncome, setTargetIncome] = useState<string>("");
  const [targetTime, setTargetTime] = useState<string>("");
  const [targetPlace, setTargetPlace] = useState<string>("");
  const [targetArea, setTargetArea] = useState<string>("");
  const [corpFieldId, setCorpFieldId] = useState<number>(0);
  const [corpFieldList, setCorpFieldList] = useState([]);
  const [targetCorpField, setTargetCorpField] = useState<number>(0);
  const [repeatTime, setRepeatTime] = useState<number>(0);
  const [advertiserId, setAdvertiserId] = useState<number>(0);
  const [advertiserList, setAdvertiserList] = useState([]);
  const [adFieldId, setAdFieldId] = useState<number>(0);
  const [adFieldList, setAdFieldList] = useState([]);
  const [status, setStatus] = useState<string>("");
  const [playStartDate, setPlayStartDate] = useState<string>("");
  const [playEndDate, setPlayEndDate] = useState<string>("");
  const [isFree, setIsFree] = useState<number>(0);
  const [createdAt, setCreatedAt] = useState<string>("");
  const [type, setType] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.adId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/ad/one?id=${params.adId}`
          );
          const jsonData = await response.json();

          const {
            adId,
            videoName,
            brand,
            price,
            showType,
            purposeType,
            remainMoney,
            geographicalScoreType,
            customerType,
            targetGender,
            targetAge,
            targetSeason,
            targetIncome,
            targetTime,
            targetPlace,
            targetArea,
            corpFieldId,
            targetCorpField,
            repeatTime,
            advertiserId,
            adFieldId,
            status,
            playStartDate,
            playEndDate,
            isFree,
            createdAt,
            type
          } = jsonData.data;

          setAdId(adId);
          setVideoName(videoName);
          setBrand(brand);
          setPrice(price);
          setRemainMoney(remainMoney);
          setShowType(showType);
          setPurposeType(purposeType);
          setGeographicalScoreType(geographicalScoreType);
          setCustomerType(customerType);
          setTargetGender(targetGender);
          setTargetAge(targetAge);
          setTargetSeason(targetSeason);
          setTargetIncome(targetIncome);
          setTargetTime(targetTime);
          setTargetPlace(targetPlace);
          setTargetArea(targetArea);
          setCorpFieldId(corpFieldId);
          setTargetCorpField(targetCorpField);
          setRepeatTime(repeatTime);
          setAdvertiserId(advertiserId);
          setAdFieldId(adFieldId);
          setStatus(status);
          setPlayStartDate(playStartDate);
          setPlayEndDate(playEndDate);
          setIsFree(isFree);
          setCreatedAt(createdAt);
          setType(type);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.adId]);

  const updateAdData = () => {
    const result = {
      adId,
      videoName,
      brand,
      price,
      showType,
      remainMoney,
      purposeType,
      geographicalScoreType,
      customerType,
      targetGender,
      targetAge,
      targetSeason,
      targetIncome,
      targetTime,
      targetPlace,
      targetArea,
      corpFieldId,
      targetCorpField,
      repeatTime,
      advertiserId,
      adFieldId,
      status,
      playStartDate,
      playEndDate,
      isFree,
      createdAt,
      type
    };

    if (videoName == "") {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(`${process.env.REACT_APP_URL}/ad/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          adId,
          videoName,
          brand,
          price,
          showType,
          purposeType,
          geographicalScoreType,
          customerType,
          targetGender,
          targetAge,
          targetSeason,
          targetIncome,
          targetTime,
          targetPlace,
          targetArea,
          corpFieldId,
          targetCorpField,
          repeatTime,
          advertiserId,
          adFieldId,
          status,
          playStartDate,
          playEndDate,
          isFree,
          createdAt,
          type
        }),
      });
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/adComponent/ad/adList");
      }
    });
  };

  return {
    adId,
    setAdId,
    videoName,
    setVideoName,
    brand,
    setBrand,
    price,
    setPrice,
    remainMoney,
    setRemainMoney,
    showType,
    setShowType,
    purposeType,
    setPurposeType,
    geographicalScoreType,
    setGeographicalScoreType,
    customerType,
    setCustomerType,
    targetGender,
    setTargetGender,
    targetAge,
    setTargetAge,
    targetSeason,
    setTargetSeason,
    targetIncome,
    setTargetIncome,
    targetTime,
    setTargetTime,
    targetPlace,
    setTargetPlace,
    targetArea,
    setTargetArea,
    corpFieldId,
    setCorpFieldId,
    corpFieldList,
    setCorpFieldList,
    targetCorpField,
    setTargetCorpField,
    repeatTime,
    setRepeatTime,
    advertiserId,
    setAdvertiserId,
    advertiserList,
    setAdvertiserList,
    adFieldId,
    setAdFieldId,
    adFieldList,
    setAdFieldList,
    status,
    setStatus,
    playStartDate,
    setPlayStartDate,
    playEndDate,
    setPlayEndDate,
    isFree,
    setIsFree,
    createdAt,
    setCreatedAt,
    type,
    setType,
    errorMessage,
    updateAdData,
  };
};

export default useAdUpdate;
