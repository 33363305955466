import { useEffect, useState } from "react";

const useInstallReportList = (initialPage: number) => {
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [data, setData] = useState<any[]>([]);
    const [count, setCount] = useState<number>();
    const [loading, setLoading] = useState(true);

    const handleDeleteClick = (installReportId: string) => {
        const result = window.confirm("삭제하시겠습니까?");
        if (result) {
          fetch(`${process.env.REACT_APP_URL}/install-report/delete`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ installReportId: installReportId }),
          })
          .then(async (response) => {
            if(await response.json() === 1451) { // 키 참조 에러처리
              alert("삭제에 실패했습니다.");
            } else {
              window.location.reload();
            }
          })
          .catch((error) => {
            // alert("삭제에 실패하였습니다.");
            window.location.reload();
          });
        }
      };

      const fetchData = async (page: any) => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/install-report/list?page=${page}`
          );
        
          const jsonData = await response.json();
          setData((prevData) => [...prevData, ...(jsonData.data.list || [])]);
      
          const countResponse = await fetch(
            `${process.env.REACT_APP_URL}/install-report/list`
          );
          
          if (!countResponse.ok) {
            throw new Error('Network request failed');
          }
      
          const countJsonData = await countResponse.json();
      
          setCount(countJsonData.data.count);
      
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchData(currentPage);
      }, [currentPage]);
    
      const nextPage = () => {
        setCurrentPage(currentPage + 1);
      };
    
      return {
        data,
        loading,
        currentPage,
        nextPage,
        count,
        handleDeleteClick,
      };
}

export default useInstallReportList;