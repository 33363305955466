import { useEffect, useState } from "react";

enum cpvProperties {
  "businessType.business" = "비즈니스",
  "siteField.middleCategory" = "장소 분류",
  EXPOSED_UNIT_PRICE = "노출 가격",
  WATCHED_UNIT_PRICE = "인지 가격",
  ATTENTIONED_UNIT_PRICE = "주목 가격",
}

const useCpvList = (initialPage: number) => {
  const transformData = (rawData: any[]): Record<cpvProperties, any>[] => {
    return rawData.map((item) => ({
      [cpvProperties["businessType.business"]]: item.businessType?.business,
      [cpvProperties["siteField.middleCategory"]]:
        item.siteField?.middleCategory,
      [cpvProperties.EXPOSED_UNIT_PRICE]: item.exposedUnitPrice,
      [cpvProperties.WATCHED_UNIT_PRICE]: item.watchedUnitPrice,
      [cpvProperties.ATTENTIONED_UNIT_PRICE]: item.attentionedUnitPrice,
    }));
  };
  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (cpvId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/cpv/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cpvId: cpvId }),
      })
        .then((response) => {
          if (response.ok) {
            window.location.reload();
          } else {
            alert("삭제에 실패하였습니다.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("삭제에 실패하였습니다.");
        });
    }
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/cpv/list?page=${page}`
      );

      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/cpv/list`
      );

      if (!countResponse.ok) {
        throw new Error("Network request failed");
      }

      const countJsonData = await countResponse.json();
      const totalDataCount = countJsonData.data.count;
      setCount(countJsonData.data.count);

      if (
        transformedData.length === 0 ||
        jsonData.data.count <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
    handleDeleteClick,
    isFetchingComplete,
  };
};

export default useCpvList;
