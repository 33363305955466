import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    label: string;
    setLabel: any;
    currentFrameWidth: number;
    setCurrentFrameWidth: any;
    currentFrameHeight: number;
    setCurrentFrameHeight: any;
    currentStatus: string;
    setCurrentStatus: any;
    currentFPS: number;
    setCurrentFPS: any;

    aiDeviceId: number;
    setAIDeviceId: any;
    aiDeviceList: any;
    setAIDeviceList: any;
    cameraTypeId: number;
    setCameraTypeId: any;
    cameraTypeList: any;
    setCameraTypeList: any

    errorMessage: any;
    createCamera: () => void;
}

const useCameraCreate = (): hookMember => {
    const navigate = useNavigate();

    const [label, setLabel] = useState<string>("");
    const [currentFrameWidth, setCurrentFrameWidth] = useState<number>(0);
    const [currentFrameHeight, setCurrentFrameHeight] = useState<number>(0);
    const [currentStatus, setCurrentStatus] = useState<string>("사용 안함");
    const [currentFPS, setCurrentFPS] = useState<number>(0);

    const [aiDeviceList, setAIDeviceList] = useState('');
    const [aiDeviceId, setAIDeviceId] = useState<number>(0);
    const [cameraTypeId, setCameraTypeId] = useState<number>(0);
    const [cameraTypeList, setCameraTypeList] = useState('');

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
          try {
            const aiDeviceListResponse = await fetch(`${process.env.REACT_APP_URL}/ai-device/all`);
              const aiDeviceListData = await aiDeviceListResponse.json();
    
            setAIDeviceList(aiDeviceListData);

            const cameraTypeListResponse = await fetch(`${process.env.REACT_APP_URL}/camera-type/all`);
            const cameraTypeListData = await cameraTypeListResponse.json();

            setCameraTypeList(cameraTypeListData);
          } catch (error) {
            console.log(error);
          }
        };
        fetchData();
      }, []);

    const createCamera = async () => {
        try {
            if (label === "" || !aiDeviceId || !cameraTypeId) {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.');
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/camera/create`, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    label,
                    currentFrameWidth,
                    currentFrameHeight,
                    currentStatus,
                    currentFPS,
                    aiDeviceId,
                    cameraTypeId
                })
            })
            if (response.ok) {
                navigate('/cameraComponent/camera/cameraList');
            } else {
                console.error('카메라 생성에 실패했습니다.');
            }
            }
        } catch (error) {
            console.error('카메라 생성 중 오류 발생:', error);
        }
    }

    return {
        label,
        setLabel,
        currentFrameWidth,
        setCurrentFrameWidth,
        currentFrameHeight,
        setCurrentFrameHeight,
        currentStatus,
        setCurrentStatus,
        currentFPS,
        setCurrentFPS,
        errorMessage,
        createCamera,
        cameraTypeId,
        setCameraTypeId,
        cameraTypeList,
        setCameraTypeList,
        aiDeviceId,
        setAIDeviceId,
        aiDeviceList,
        setAIDeviceList
    }
}

export default useCameraCreate;