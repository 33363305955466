import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  date: string;
  data: any;
  mediaId: number;
  loading: boolean;
  input: any;
  setInput: any;
  select: (date: any) => void;
  changeMedia: (m: any) => void;
  dateButton: (n: number) => void;
}

const useScheduleDetailDashboard = (): hookMember => {
  const params = useParams();
  const navigate = useNavigate();

  const [mediaId, setMediaId] = useState<number>(0);
  const [date, setDate] = useState<string>("");
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  const [input, setInput] = useState<string>("");

  const fetchData = async () => {
    if (!mediaId || !date) return;
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/schedule/detail?media=${mediaId}&date=${date}`
      );
      const jsonData = await response.json();

      if (jsonData.status !== 200) return;
      // console.log(jsonData.data);
      setData(jsonData.data);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [mediaId, date]);

  useEffect(() => {
    getData();
  }, [params.mediaId]);

  const getData = () => {
    let date = new URLSearchParams(window.location.search).get("date");
    if (date) {
      setDate(date);
    } else {
      navigate("/dashboard/schedule");
      return;
    }
    setMediaId(Number(params.mediaId));
    setInput(date);
  };

  const changeMedia = (media: any) => {
    navigate(`/dashboard/schedule/${media}?date=${input}`);
    getData();
  };

  const select = (date: any = "") => {
    navigate(`/dashboard/schedule/${mediaId}?date=${date ?? input}`);
    getData();
  };

  const dateButton = (n: number) => {
    let now = new Date(date).getTime();

    now += n * 24 * 60 * 60 * 1000;

    const datetext = `${new Date(now).getFullYear()}-${plus0(
      new Date(now).getMonth() + 1
    )}-${plus0(new Date(now).getDate())}`;

    navigate(`/dashboard/schedule/${mediaId}?date=${datetext}`);

    getData();
  };

  const plus0 = (n: any) => {
    if (n >= 10) return n;
    return "0" + n;
  };

  return {
    date,
    data,
    mediaId,
    loading,
    input,
    setInput,
    select,
    changeMedia,
    dateButton,
  };
};

export default useScheduleDetailDashboard;
