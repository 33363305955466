import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
  label: string;
  setLabel: any;
  serialNumber: string;
  setSerialNumber: any;
  currentStatus: string;
  setCurrentStatus: any;
  errorMessage: any;

  aiDeviceTypeId: number;
  setAIDeviceTypeId: any;
  aiDeviceTypeList: any;
  setAIDeviceTypeList: any;
  networkDeviceId: number;
  setNetworkDeviceId: any;
  networkDeviceList: any;
  setNetworkDeviceList: any;

  createAIDevice: () => void;
}

const useAIDeviceCreate = (): hookMember => {
  const navigate = useNavigate();

  const [label, setLabel] = useState<string>("");
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [currentStatus, setCurrentStatus] = useState<string>("사용 안함");

  const [errorMessage, setErrorMessage] = useState("");

  const [aiDeviceTypeId, setAIDeviceTypeId] = useState<number>(0);
  const [aiDeviceTypeList, setAIDeviceTypeList] = useState([]);
  const [networkDeviceId, setNetworkDeviceId] = useState<number>(0);
  const [networkDeviceList, setNetworkDeviceList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const aiDeviceTypeResponse = await fetch(
          `${process.env.REACT_APP_URL}/ai-device-type/all`
        );
        const aiDeviceTypeData = await aiDeviceTypeResponse.json();

        setAIDeviceTypeList(aiDeviceTypeData);

        const networkDeviceResponse = await fetch(
            `${process.env.REACT_APP_URL}/network-device/all`
        );
        const networkDeviceData = await networkDeviceResponse.json();

        setNetworkDeviceList(networkDeviceData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const createAIDevice = async () => {
    try {
      if (label === "" || !aiDeviceTypeId) {
        setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/ai-device/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              label,
              serialNumber,
              currentStatus,
              aiDeviceTypeId,
              networkDeviceId
            }),
          }
        );
        if (response.ok) {
          navigate("/aiComponent/aiDevice/aiDeviceList");
        } else {
            if(response.type == "cors"){
                setErrorMessage('네트워크 기기가 중복된 AI 기기가 존재합니다.');
            }
        }
      }
    } catch (error) {
      console.error("AI 기기 생성 중 오류 발생:", error);
    }
  };

  return {
    label,
    setLabel,
    serialNumber,
    setSerialNumber,
    currentStatus,
    setCurrentStatus,
    errorMessage,
    createAIDevice,
    aiDeviceTypeId,
    setAIDeviceTypeId,
    aiDeviceTypeList,
    networkDeviceId,
    setNetworkDeviceId,
    networkDeviceList,
    setNetworkDeviceList,
    setAIDeviceTypeList,
  };
};

export default useAIDeviceCreate;
