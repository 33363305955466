import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    label: string;
    setLabel: any;
    networkDeviceTypeId: number;
    setNetworkDeviceTypeId: any;
    networkDeviceTypeList: any;
    setNetworkDeviceTypeList: any;
    aiDeviceId: number;
    setAIDeviceId: any;
    aiDeviceList: any;
    setAIDeviceList: any;
    currentState: string;
    setCurrentState: any;
    errorMessage: any;

    createNetworkDevice: () => void;
}

const useNetworkDeviceCreate = (): hookMember => {
    const navigate = useNavigate();

    const [label, setLabel] = useState<string>("");
    const [networkDeviceTypeId, setNetworkDeviceTypeId] = useState<number>(0);
    const [aiDeviceId, setAIDeviceId] = useState<number>(0);
    const [currentState, setCurrentState] = useState<string>("");
    const [aiDeviceList, setAIDeviceList] = useState('');
    const [networkDeviceTypeList, setNetworkDeviceTypeList] = useState('');

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const aiDeviceListResponse = await fetch(`${process.env.REACT_APP_URL}/ai-device/all`);
                const aiDeviceListData = await aiDeviceListResponse.json();
    
                setAIDeviceList(aiDeviceListData);

                const networkDeviceTypeListResponse = await fetch(`${process.env.REACT_APP_URL}/network-device-type/all`);
                const networkDeviceTypeListData = await networkDeviceTypeListResponse.json();

                setNetworkDeviceTypeList(networkDeviceTypeListData);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const createNetworkDevice = async () => {
        try {
            if (label === "" || !aiDeviceId || !networkDeviceTypeId) {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.')
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/network-device/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        label,
                        networkDeviceTypeId,
                        aiDeviceId,
                        currentState
                    })
                })
                if(response.ok) {
                    navigate('/networkComponent/networkDevice/networkDeviceList');
                } else {
                    if(response.type == "cors"){
                        setErrorMessage('기기 종류 또는 AI 기기가 중복된 네트워크 기기가 존재합니다.');
                    }
                }
            }
        } catch (error) {
            console.error('네트워크 기기 생성 중 오류 발생:', error);
        }
    }
    return {
        label,
        setLabel,
        aiDeviceId,
        setAIDeviceId,
        networkDeviceTypeId,
        setNetworkDeviceTypeId,
        currentState,
        setCurrentState,
        aiDeviceList,
        setAIDeviceList,
        networkDeviceTypeList,
        setNetworkDeviceTypeList,
        createNetworkDevice,
        errorMessage
    }
}

export default useNetworkDeviceCreate;