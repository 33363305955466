import { useEffect, useState } from "react";
import useInstallUpdate from "./hooks/useInstallUpdate";
import axios from "axios";
import OvalSpinner from "../../components/atoms/loading/OvalSpinner";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";

const InstallUpdate = () => {
  const hookMember = useInstallUpdate();
  const [imagePreviewURLs, setImagePreviewURLs] = useState<string[]>([]);
  const [videoPreviewURLs, setVideoPreviewURLs] = useState<string[]>([]);
  const [updatedImagePreviewURLs, setUpdatedImagePreviewURLs] = useState<
    string[]
  >([]);
  const [updatedVideoPreviewURLs, setUpdatedVideoPreviewURLs] = useState<
    string[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const serverBaseUrl = process.env.REACT_APP_URL; // 서버 기본 URL

  const aiDeviceOptions = hookMember.aiDeviceList.data
    ? hookMember.aiDeviceList.data.map(
        (item: { aiDeviceId: number; label: string }) => ({
          value: item.aiDeviceId,
          label: item.label,
        })
      )
    : [];

    const cameraOptions = hookMember.cameraList.data
    ? hookMember.cameraList.data.map(
        (item: { cameraId: number; label: string }) => ({
          value: item.cameraId,
          label: item.label,
        })
      )
    : [];

    const networkDeviceOptions = hookMember.networkDeviceList.data
    ? hookMember.networkDeviceList.data.map(
        (item: { networkDeviceId: number; label: string }) => ({
          value: item.networkDeviceId,
          label: item.label,
        })
      )
    : [];

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const imageFiles = Array.from(files);
      hookMember.setImages(imageFiles);

      // 새 이미지 파일 미리보기 URL 생성
      const newImagePreviewURLs = imageFiles.map((file) =>
        URL.createObjectURL(file)
      );
      setUpdatedImagePreviewURLs((prevImagePreviewURLs) => [
        ...prevImagePreviewURLs,
        ...newImagePreviewURLs,
      ]);
    }
  };

  const handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const videoFiles = Array.from(files);
      hookMember.setVideos(videoFiles);

      // 새 비디오 파일 미리보기 URL 생성
      const newVideoPreviewURLs = videoFiles.map((file) =>
        URL.createObjectURL(file)
      );
      setUpdatedVideoPreviewURLs((prevVideoPreviewURLs) => [
        ...prevVideoPreviewURLs,
        ...newVideoPreviewURLs,
      ]);
    }
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      await hookMember.updateInstallData();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  const handleDeleteFile = async (url: string, type: string) => {
    const confirmation = window.confirm("정말 삭제하시겠습니까?");
    if (!confirmation) {
      return;
    }

    const urlParts = url.split("/");
    const filename = urlParts[urlParts.length - 1];
    try {
      await axios.post(
        `${process.env.REACT_APP_URL}/install-report/delete-files`,
        {
          mediaId: hookMember.mediaId,
          category: "install",
          type,
          filename,
        }
      );
      if (type === "images") {
        setUpdatedImagePreviewURLs(
          updatedImagePreviewURLs.filter((item) => item !== url)
        );
      } else if (type === "videos") {
        setUpdatedVideoPreviewURLs(
          updatedVideoPreviewURLs.filter((item) => item !== url)
        );
      }

      window.location.reload();
    } catch (error) {
      // console.error("파일을 삭제하는 중 오류 발생", error);
    }
  };

  // 컴포넌트 내부의 useEffect 함수 안에서 이미지를 서버에서 가져와서 표시하는 부분 수정
  useEffect(() => {
    const fetchFiles = async (type: string) => {
      if (hookMember.mediaId != 0) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_URL}/install-report/get-files`,
            {
              mediaId: hookMember.mediaId,
              category: "install",
              type,
            }
          );

          if (response.data && response.data.data) {
            const filePaths = response.data.data.map((file: any) => {
              return file.path;
            });

            if (type === "images") {
              setImagePreviewURLs(filePaths);
            } else if (type === "videos") {
              setVideoPreviewURLs(filePaths);
            }
          } else {
            // console.error("잘못된 응답 데이터:", response);
          }
        } catch (error) {
          // console.error("파일을 가져오는 중 오류 발생", error);
        }
      }
    };

    fetchFiles("images");
    fetchFiles("videos");
  }, [hookMember.mediaId]);
  
  const handleAiDeviceSelectChange = (selectedOption: any) => {
    hookMember.setAiDeviceId(selectedOption ? selectedOption.value : 0);
  };

  const handleCameraSelectChange = (selectedOption: any) => {
    hookMember.setCameraId(selectedOption ? selectedOption.value : 0);
  }

  const handleNetworkDeviceSelectChange = (selectedOption: any) => {
    hookMember.setNetworkDeviceId(selectedOption ? selectedOption.value : 0);
  }

  return (
    <div>
      {isLoading && <OvalSpinner />}
      <div className="input-table">
        <h1>설치 문서</h1>
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>매체</label>
          <span>{hookMember.mediaName}</span>
        </div>
        <div>
          <label>작업 날짜</label>
          <ReactDatePicker
            dateFormat="yyyy-MM-dd" // 날짜 형태
            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
            minDate={new Date("2000-01-01")} // minDate 이전 날짜 선택 불가
            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
            selected={hookMember.workDate}
            onChange={(date) => hookMember.setWorkDate(date)}
          />
        </div>
        <div>
          <label>ai 기기 <span className="required">*</span></label>
          <Select
            options={aiDeviceOptions}
            value={aiDeviceOptions.find(
              (option: { value: number }) =>
                option.value === hookMember.aiDeviceId
            )}
            onChange={handleAiDeviceSelectChange}
            placeholder={hookMember.aiDeviceId == 0 ? "AI 기기 검색" : hookMember.aiDeviceLabel}
            isClearable
            styles={{ container: (base) => ({ ...base, width: 300 }) }}
          />
        </div>
        <div>
          <label>카메라 <span className="required">*</span></label>
          <Select
            options={cameraOptions}
            value={cameraOptions.find(
              (option: { value: number }) =>
                option.value === hookMember.cameraId
            )}
            onChange={handleCameraSelectChange}
            placeholder="카메라 검색"
            isClearable
            styles={{ container: (base) => ({ ...base, width: 300 }) }}
          />
        </div>
        <div>
          <label>네트워크 기기 <span className="required">*</span></label>
          <Select
            options={networkDeviceOptions}
            value={networkDeviceOptions.find(
              (option: { value: number }) =>
                option.value === hookMember.networkDeviceId
            )}
            onChange={handleNetworkDeviceSelectChange}
            placeholder="네트워크 기기 검색"
            isClearable
            styles={{ container: (base) => ({ ...base, width: 300 }) }}
          />
        </div>
        <div>
          <label>비고란</label>
          <textarea
            name=""
            id=""
            value={hookMember.comment}
            onChange={(e) => hookMember.setComment(e.target.value)}
            className="largeTextArea"
          />
        </div>
        <div>
          <label>이미지 업로드</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            multiple
          />
          <div className="image-preview">
            {imagePreviewURLs.map((url, index) => (
              <div key={index} style={{ position: "relative" }}>
                <img
                  src={`${serverBaseUrl}${url}`}
                  alt={`Updated Image ${index}`}
                />
                <button
                  className="delete-button"
                  onClick={() => handleDeleteFile(url, "images")}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          <div className="image-preview">
            {updatedImagePreviewURLs.map((url, index) => (
              <div key={index} style={{ position: "relative" }}>
                <img src={url} alt={`Preview ${index}`} />
                <button
                  className="delete-button"
                  onClick={() => handleDeleteFile(url, "images")}
                >
                  X
                </button>
              </div>
            ))}
          </div>
        </div>
        <div>
          <label>영상 업로드</label>
          <input
            type="file"
            accept="video/*"
            onChange={handleVideoChange}
            multiple
          />
          <div className="video-preview">
            {videoPreviewURLs.map((url, index) => (
              <div key={index} style={{ position: "relative" }}>
                <video src={`${serverBaseUrl}${url}`} controls />
                <button
                  className="delete-button"
                  onClick={() => handleDeleteFile(url, "videos")}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          <div className="video-preview">
            {updatedVideoPreviewURLs.map((url, index) => (
              <div key={index} style={{ position: "relative" }}>
                <video src={url} controls />
                <button
                  className="delete-button"
                  onClick={() => handleDeleteFile(url, "videos")}
                >
                  X
                </button>
              </div>
            ))}
          </div>
        </div>
        <span style={{color: "navy"}}>* 영상 용량 제한: 100mb</span>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={handleUpdate}>저장</button>
      </div>
    </div>
  );
};

export default InstallUpdate;
