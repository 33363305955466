import React, { useState, ChangeEvent, useMemo } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import useDailyRealEffectGraphsList from "../hooks/useDailyRealEffectGraphsList";
import "../css/dailyRealEffectGraphsList.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

interface RealEffectData {
  period: string;
  mediaId: string;
  [key: string]: any;
}

interface DataItem {
  period: Date;
  mediaId: string;
  [key: string]: any;
}

const DailyRealEffectGraphsList: React.FC = () => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>([
    "maleTeenExposedCount",
  ]);
  const [mediaIdInput, setMediaIdInput] = useState<string>("");
  const [selectedGraphType, setSelectedGraphType] = useState<string>("line");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const mediaIds = useMemo(() => {
    return mediaIdInput
      .split(",")
      .map((id) => id.trim())
      .filter((id) => id !== "");
  }, [mediaIdInput]);

  const { data, loading, error } = useDailyRealEffectGraphsList({
    mediaIds,
    startDate,
    endDate,
  });

  const handleMetricChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setSelectedMetrics((prevMetrics) =>
      checked ? [...prevMetrics, value] : prevMetrics.filter((metric) => metric !== value)
    );
  };

  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value || "");
  };

  const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value || "");
  };

  const handleMediaIdInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMediaIdInput(e.target.value);
  };

  const handleGraphTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedGraphType(e.target.value);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const filteredData: DataItem[] = useMemo(() => {
    return data
      .filter((item: RealEffectData) => {
        const date = new Date(item.period);
        const isWithinDateRange =
          (!startDate || date >= new Date(startDate)) &&
          (!endDate || date <= new Date(endDate));
        return isWithinDateRange;
      })
      .map((item: RealEffectData) => ({
        ...item,
        period: new Date(item.period),
      }))
      .sort(
        (a: DataItem, b: DataItem) => a.period.getTime() - b.period.getTime()
      );
  }, [data, startDate, endDate]);

  const datasets = useMemo(() => {
    const uniqueMediaIds = Array.from(new Set(mediaIds));
    const allDatasets: any[] = [];

    uniqueMediaIds.forEach((mediaId) => {
      selectedMetrics.forEach((metric) => {
        allDatasets.push({
          label: `Media ID ${mediaId} - ${metric}`,
          data: filteredData
            .filter((item) => item.mediaId === mediaId)
            .map((item) => ({
              x: item.period,
              y: item[metric] as number,
            })),
          borderColor: `hsl(${Math.random() * 360}, 70%, 50%)`,
          backgroundColor: "rgba(136, 132, 216, 0.2)",
          pointBackgroundColor: "rgba(136, 132, 216, 0.2)",
          borderWidth: 2,
          tension: 0,
        });
      });
    });

    return allDatasets;
  }, [filteredData, selectedMetrics, mediaIds]);

  const chartData = {
    labels: filteredData.map((item) => item.period),
    datasets: datasets,
  };

  return (
    <div className="container">
      <h1 className="title">Daily Real Effect Graphs</h1>
      <div className="date-filters">
        <label className="filter-label">
          Start Date:
          <input
            type="date"
            className="date-input"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </label>
        <label className="filter-label">
          End Date:
          <input
            type="date"
            className="date-input"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </label>
      </div>
      <div className="controls">
        <div className="control-group">
          <label className="control-label">
            Media ID:
            <input
              type="text"
              className="media-input"
              value={mediaIdInput}
              onChange={handleMediaIdInputChange}
            />
          </label>
          <label className="control-label">
            Graph Type:
            <select
              className="graph-type-select"
              value={selectedGraphType}
              onChange={handleGraphTypeChange}
            >
              <option value="line">Line</option>
              <option value="bar">Bar</option>
            </select>
          </label>
        </div>
        <div className="metric-selector">
          <button
            className="dropdown-toggle"
            onClick={toggleDropdown}
          >
            Select Metrics
          </button>
          {isDropdownOpen && (
            <div className="metric-options">
              {[
                "maleTeenExposedCount",
                "maleYoungExposedCount",
                "maleMiddleExposedCount",
                "maleOldExposedCount",
                "femaleTeenExposedCount",
                "femaleYoungExposedCount",
                "femaleMiddleExposedCount",
                "femaleOldExposedCount",
                "maleTeenWatchedCount",
                "maleYoungWatchedCount",
                "maleMiddleWatchedCount",
                "maleOldWatchedCount",
                "femaleTeenWatchedCount",
                "femaleYoungWatchedCount",
                "femaleMiddleWatchedCount",
                "femaleOldWatchedCount",
                "maleTeenAttentionCount",
                "maleYoungAttentionCount",
                "maleMiddleAttentionCount",
                "maleOldAttentionCount",
                "femaleTeenAttentionCount",
                "femaleYoungAttentionCount",
                "femaleMiddleAttentionCount",
                "femaleOldAttentionCount",
              ].map((metric) => (
                <label key={metric} className="metric-option">
                  <input
                    type="checkbox"
                    value={metric}
                    checked={selectedMetrics.includes(metric)}
                    onChange={handleMetricChange}
                  />
                  {metric.replace(/([A-Z])/g, " $1").trim()}
                </label>
              ))}
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <p className="loading">Loading...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <>
          {selectedGraphType === "line" ? (
            <Line
              data={chartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top" as const,
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const value = (context.raw as { y: number }).y;
                        return `${context.dataset.label}: ${value}`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    type: "time",
                    time: {
                      unit: "day",
                      tooltipFormat: "MMM dd, yyyy",
                      displayFormats: {
                        day: "MMM dd, yyyy",
                      },
                    },
                    title: {
                      display: true,
                      text: "Date",
                    },
                  },
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          ) : (
            <Bar
              data={chartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top" as const,
                  },
                  tooltip: {
                    callbacks: {
                      label: (context: any) => {
                        const value = (context.raw as { y: number }).y;
                        return `${context.dataset.label}: ${value}`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    type: "time",
                    time: {
                      unit: "day",
                      tooltipFormat: "MMM dd, yyyy",
                      displayFormats: {
                        day: "MMM dd, yyyy",
                      },
                    },
                    title: {
                      display: true,
                      text: "Date",
                    },
                  },
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DailyRealEffectGraphsList;
