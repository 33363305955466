import { useState, useEffect } from "react";
import Select from "react-select";
import useInstallCreate from "./hooks/useInstallCreate";
import OvalSpinner from "../../components/atoms/loading/OvalSpinner";
import ReactDatePicker from "react-datepicker";

const InstallCreate = () => {
  const hookMember = useInstallCreate();
  const [imagePreviewURLs, setImagePreviewURLs] = useState<string[]>([]);
  const [videoPreviewURLs, setVideoPreviewURLs] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const mediaOptions = hookMember.mediaList.data
    ? hookMember.mediaList.data.map(
        (item: { mediaId: number; name: string }) => ({
          value: item.mediaId,
          label: item.name,
        })
      )
    : [];

  const aiDeviceOptions = hookMember.aiDeviceList.data
    ? hookMember.aiDeviceList.data.map(
        (item: { aiDeviceId: number; label: string }) => ({
          value: item.aiDeviceId,
          label: item.label,
        })
      )
    : [];

    const cameraOptions = hookMember.cameraList.data
    ? hookMember.cameraList.data.map(
        (item: { cameraId: number; label: string }) => ({
          value: item.cameraId,
          label: item.label,
        })
      )
    : [];

    const networkDeviceOptions = hookMember.networkDeviceList.data
    ? hookMember.networkDeviceList.data.map(
        (item: { networkDeviceId: number; label: string }) => ({
          value: item.networkDeviceId,
          label: item.label,
        })
      )
    : [];

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const imageFiles = Array.from(files);
      hookMember.setImages(imageFiles);

      // 이미지 파일을 미리보기 URL로 변환하여 설정
      const imageURLs = imageFiles.map((file) => URL.createObjectURL(file));
      setImagePreviewURLs(imageURLs);
    }
  };

  const handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const videoFiles = Array.from(files);
      hookMember.setVideos(videoFiles);

      // 비디오 파일을 미리보기 URL로 변환하여 설정
      const videoURLs = videoFiles.map((file) => URL.createObjectURL(file));
      setVideoPreviewURLs(videoURLs);
    }
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      await hookMember.createInstall();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  const handleMediaSelectChange = (selectedOption: any) => {
    hookMember.setMediaId(selectedOption ? selectedOption.value : 0);
  };

  const handleAiDeviceSelectChange = (selectedOption: any) => {
    hookMember.setAiDeviceId(selectedOption ? selectedOption.value : 0);
  };

  const handleCameraSelectChange = (selectedOption: any) => {
    hookMember.setCameraId(selectedOption ? selectedOption.value : 0);
  }

  const handleNetworkDeviceSelectChange = (selectedOption: any) => {
    hookMember.setNetworkDeviceId(selectedOption ? selectedOption.value : 0);
  }

  return (
    <div>
      {isLoading && <OvalSpinner />}
      <div className="input-table">
        <h1>설치 문서</h1>
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>매체 <span className="required">*</span></label>
          <Select
            options={mediaOptions}
            value={mediaOptions.find(
              (option: { value: number }) => option.value === hookMember.mediaId
            )}
            onChange={handleMediaSelectChange}
            placeholder="매체 검색"
            isClearable
            styles={{ container: (base) => ({ ...base, width: 300 }) }}
          />
        </div>
        <div>
          <label>작업 날짜</label>
          <ReactDatePicker
            dateFormat="yyyy-MM-dd" // 날짜 형태
            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
            minDate={new Date("2000-01-01")} // minDate 이전 날짜 선택 불가
            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
            selected={hookMember.workDate}
            onChange={(date) => hookMember.setWorkDate(date)}
          />
        </div>
        <div>
          <label>ai 기기 <span className="required">*</span></label>
          <Select
            options={aiDeviceOptions}
            value={aiDeviceOptions.find(
              (option: { value: number }) =>
                option.value === hookMember.aiDeviceId
            )}
            onChange={handleAiDeviceSelectChange}
            placeholder="AI 기기 검색"
            isClearable
            styles={{ container: (base) => ({ ...base, width: 300 }) }}
          />
        </div>
        <div>
          <label>카메라 <span className="required">*</span></label>
          <Select
            options={cameraOptions}
            value={cameraOptions.find(
              (option: { value: number }) =>
                option.value === hookMember.cameraId
            )}
            onChange={handleCameraSelectChange}
            placeholder="카메라 검색"
            isClearable
            styles={{ container: (base) => ({ ...base, width: 300 }) }}
          />
        </div>
        <div>
          <label>네트워크 기기 <span className="required">*</span></label>
          <Select
            options={networkDeviceOptions}
            value={networkDeviceOptions.find(
              (option: { value: number }) =>
                option.value === hookMember.networkDeviceId
            )}
            onChange={handleNetworkDeviceSelectChange}
            placeholder="네트워크 기기 검색"
            isClearable
            styles={{ container: (base) => ({ ...base, width: 300 }) }}
          />
        </div>
        <div>
          <label>비고란</label>
          <textarea
            name=""
            id=""
            value={hookMember.comment}
            onChange={(e) => hookMember.setComment(e.target.value)}
            className="largeTextArea"
          />
        </div>
        <div>
          <label>이미지 업로드</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            multiple
          />
          <div className="image-preview">
            {imagePreviewURLs.map((url, index) => (
              <img key={index} src={url} alt={`Preview ${index}`} />
            ))}
          </div>
        </div>
        <div>
          <label>영상 업로드</label>
          <input
            type="file"
            accept="video/*"
            onChange={handleVideoChange}
            multiple
          />
          <div className="video-preview">
            {videoPreviewURLs.map((url, index) => (
              <video key={index} src={url} controls />
            ))}
          </div>
        </div>
        <span style={{ color: "navy" }}>* 영상 용량 제한: 100mb</span>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={handleUpdate}>저장</button>
      </div>
    </div>
  );
};

export default InstallCreate;
