const sortedData = (data: any[], sortBy: string, sortDirection: string) => {
    return [...data].sort((a, b) => {
      const aValue = sortBy ? (sortBy.includes('.') ? getValue(a, sortBy) : a[sortBy]) : "";
      const bValue = sortBy ? (sortBy.includes('.') ? getValue(b, sortBy) : b[sortBy]) : "";
  
      // null 값을 가장 큰 값으로 간주하여 끝으로 이동
      if (aValue === null && bValue === null) {
        return 0;
      } else if (aValue === null) {
        return 1;
      } else if (bValue === null) {
        return -1;
      }
  
      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
      } else if (typeof aValue === "string" && typeof bValue === "string") {
        return sortDirection === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
      }
    });
  };
  
  // 점 표기법을 사용하여 중첩된 객체에서 값을 가져오는 함수
  const getValue = (object: any, key: string) => {
    const keys = key.split('.');
    let value = object;
    for (const k of keys) {
      if (value === null || value === undefined) {
        return null;
      }
      value = value[k];
    }
    return value;
  };
  
  export default sortedData;
  