import { RouterProvider } from "react-router-dom";
import useAppRoute from "./useAppRoute";
import { useEffect } from "react";

const AppRoute = () => {
  const hookMember = useAppRoute();

  return <RouterProvider router={hookMember.router} />;
};

export default AppRoute;
