import { useEffect, useState } from "react";

enum AdFieldProperties {
  MAJOR_CATEGORY = "대분류",
  MIDDLE_CATEGORY = "중분류",
  TARGET_AGE = "대상 연령",
  TARGET_MALE_RATIO = "	남성 비율",
  TARGET_FEMALE_RATIO = "여성 비율",
}

const useAdFieldList = (initialPage: number) => {
  const transformData = (rawData: any[]): Record<AdFieldProperties, any>[] => {
    return rawData.map((item) => ({
      [AdFieldProperties.MAJOR_CATEGORY]: item.majorCategory,
      [AdFieldProperties.MIDDLE_CATEGORY]: item.middleCategory,
      [AdFieldProperties.TARGET_AGE]: item.targetAge,
      [AdFieldProperties.TARGET_MALE_RATIO]: item.targetMaleRatio,
      [AdFieldProperties.TARGET_FEMALE_RATIO]: item.targetFemaleRatio,
    }));
  };
  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (adFieldId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/ad-field/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ adFieldId: adFieldId }),
      })
        .then(async (response) => {
          if ((await response.json()) === 1451) {
            // 키 참조 에러처리
            alert("삭제에 실패했습니다.");
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          alert("삭제에 실패하였습니다.");
        });
    }
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/ad-field/list?page=${page}`
      );

      const jsonData = await response.json();

      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/ad-field/list`
      );

      if (!countResponse.ok) {
        throw new Error("Network request failed");
      }

      const countJsonData = await countResponse.json();

      const totalDataCount = countJsonData.data.count;
      setCount(totalDataCount);
      // 모든 데이터를 가져온 경우 fetch 종료
      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
    handleDeleteClick,
    isFetchingComplete,
  };
};

export default useAdFieldList;
