import useAdFieldUpdate from "./hooks/useAdFieldUpdate";

const AdFieldUpdate = () => {
  const hookMember = useAdFieldUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>
            대분류<span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.majorCategory}
            onChange={(e) => hookMember.setMajorCategory(e.target.value)}
          />
        </div>
        <div>
          <label>
            중분류
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.middleCategory}
            onChange={(e) => hookMember.setMiddleCategory(e.target.value)}
          />
        </div>
        <div>
          <label>
            대상 연령
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.targetAge}
            onChange={(e) => hookMember.setTargetAge(e.target.value)}
          />
        </div>
        <div>
          <label>
            남성 비율
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.targetMaleRatio}
            onChange={(e) => hookMember.setTargetMaleRatio(e.target.value)}
          />
        </div>
        <div>
          <label>
            여성 비율
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.targetFemaleRatio}
            onChange={(e) => hookMember.setTargetFemaleRatio(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.updateAdFieldData}>저장</button>
      </div>
    </div>
  );
};

export default AdFieldUpdate;
