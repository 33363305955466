import useNetworkDeviceUpdate from "./hooks/useNetworkDeviceUpdate";

const NetworkDeviceUpdate = () => {
  const hookMember = useNetworkDeviceUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>
            네트워크 기기 라벨<span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.label}
            onChange={(e) => hookMember.setLabel(e.target.value)}
          />
        </div>
        <div>
          <label>
            기기 종류 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.networkDeviceTypeId}
            onChange={(e) => {
              hookMember.setNetworkDeviceTypeId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.networkDeviceTypeList.data) &&
              hookMember.networkDeviceTypeList.data.map(
                (item: { networkDeviceTypeId: number; name: string }) => (
                  <option
                    key={item.networkDeviceTypeId}
                    value={item.networkDeviceTypeId}
                  >
                    {item.name}
                  </option>
                )
              )}
          </select>
        </div>
        <div>
          <label>
            AI 기기 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.aiDeviceId}
            onChange={(e) => {
              hookMember.setAIDeviceId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.aiDeviceList.data) &&
              hookMember.aiDeviceList.data.map(
                (item: { aiDeviceId: number; label: string }) => (
                  <option key={item.aiDeviceId} value={item.aiDeviceId}>
                    {item.label}
                  </option>
                )
              )}
          </select>
        </div>
        <div>
          <label>
            현재 상태 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.currentState}
            onChange={(e) => hookMember.setCurrentState(e.target.value)}
          >
            <option value="">선택해 주세요</option>
            <option value="정상">정상</option>
            <option value="비정상">비정상</option>
          </select>
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.updateNetworkDeviceData}>저장</button>
      </div>
    </div>
  );
};

export default NetworkDeviceUpdate;
