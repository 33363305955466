import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  aiDeviceCompanyId: number;
  setAiDeviceCompanyId: any;
  name: string;
  setName: any;

  errorMessage: any;

  updateAiDeviceCompanyData: () => void;
}

const useAiDeviceCompanyUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [aiDeviceCompanyId, setAiDeviceCompanyId] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.aiDeviceCompanyId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/ai-device-company/one?id=${params.aiDeviceCompanyId}`
          );
          const jsonData = await response.json();

          const { aiDeviceCompanyId, name } = jsonData.data;

          setAiDeviceCompanyId(aiDeviceCompanyId);
          setName(name);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.aiDeviceCompanyId]);

  const updateAiDeviceCompanyData = () => {
    const result = {
      aiDeviceCompanyId,
      name,
    };

    if (!name) {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/ai-device-company/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            aiDeviceCompanyId,
            name,
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/aiComponent/aiDeviceCompany/aiDeviceCompanyList");
      }
    });
  };

  return {
    aiDeviceCompanyId,
    setAiDeviceCompanyId,
    name,
    setName,
    errorMessage,
    updateAiDeviceCompanyData,
  };
};

export default useAiDeviceCompanyUpdate;
