import { ListModal } from "../../../components/modal/list/list";
import { SearchBar } from "../../../components/searchBar/searchBar";
import {
  CustomColumnProps,
  DynamicTable,
} from "../../../components/table/dynamicTable";
import { useMediaVersionHistory } from "../hooks/useMediaVersionHistory";
import "../css/mediaVersionHistory.scss";

import { useEffect, useState } from "react";
import FilterDropdown from "../../../components/atoms/filterDropdown/FilterDropdown";
import { countTrueValues } from "../../../util/countTrueValues";

export default function MediaVersionHistory() {
  const {
    searchTerm,
    setSearchTerm,
    mediaTableData,
    columns,
    viewVersionHistory,
    viewResourceHistory,
    mediaItems,
    setMediaTableData,
  } = useMediaVersionHistory();

  const CustomHistoryDetailButton: React.FC<{ item: any }> = ({ item }) => (
    <button onClick={() => viewVersionHistory(item)}>버전 이력</button>
  );

  let customColumns: CustomColumnProps[] = [];

  if (
    mediaTableData &&
    mediaTableData.length > 0 &&
    Object.keys(mediaTableData[0]).length > 0
  ) {
    customColumns = [
      {
        index: Object.keys(mediaTableData[0]).length,
        rowText: "상세 이력 보기",
        component: CustomHistoryDetailButton,
      },
    ];
  }

  return (
    <div className="container">
      <>
        <div className="header">
          <h1>Version History</h1>
          <FilterDropdown
            columns={columns}
            searchTerm={searchTerm}
            tableData={mediaTableData}
            allData={mediaItems}
            setTableData={setMediaTableData}
          />
        </div>
        <div className="table-container">
          <DynamicTable data={mediaTableData} customColumns={customColumns} />
        </div>
      </>
    </div>
  );
}
