import useDalgoTDashboard from "./hooks/useDalgoTDashboard";
import "./css/useDalgoTDashboard.scss";
import {
  Map,
  Circle,
  Polyline,
  MapMarker,
  CustomOverlayMap,
  MapInfoWindow,
} from "react-kakao-maps-sdk";

const DalgoTDashboard: any = () => {
  const hookMember = useDalgoTDashboard();

  return (
    <div id="dalgoTdashboard">
      <div>
        <div>
          <h1 style={{ margin: "0px" }}>달고 T - 일간 데이터 관제</h1>
          <input
            type="date"
            value={hookMember.date}
            pattern="yyyy-MM-dd"
            onChange={(e) => {
              hookMember.setDate(e.target.value);
            }}
          />
          <select
            id="media_select"
            onChange={(e) => hookMember.setMedia(Number(e.target.value))}>
            {hookMember.mediaList.map((item: any, index: number) => {
              return (
                <option value={item.authNumber} key={index}>
                  {item.authNumber}) {item.name}
                </option>
              );
            })}
          </select>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{hookMember.lastUpdate}</div>
            <div id="dalgoT_legend">
              {hookMember.legend.map((item, index) => {
                return (
                  <div key={index}>
                    <label>{item.text}</label>
                    <div style={{ backgroundColor: item.color }}></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div style={{ position: "relative" }}>
          {!hookMember.loading ? (
            hookMember.gps.length === 0 ? (
              <div>데이터가 없습니다.</div>
            ) : (
              <>
                <Map
                  center={hookMember.gps[hookMember.gps.length - 1].point} // 지도의 중심 좌표
                  style={{ width: "100%", height: "100%" }} // 지도 크기
                  level={5}>
                  {hookMember.infoWindow && (
                    <div id="dalgoT_infoWindow">
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button
                          onClick={() => hookMember.createInfoWindow(false)}>
                          X
                        </button>
                      </div>
                      <div>시간 : {hookMember.infoWindow.time}</div>
                      <div>속도 : {hookMember.infoWindow.speed}km/h</div>
                      <div>이동거리 : {hookMember.infoWindow.distance}m</div>
                      <div>
                        거리당 인구 :{" "}
                        {hookMember.infoWindow.exposed2.toFixed(1)}명
                      </div>
                    </div>
                  )}
                  <div id="dalgoT_colorButton">
                    <button
                      onClick={() => hookMember.setCircleColor(0)}
                      className={
                        hookMember.circleColor === 0 ? "selected" : ""
                      }>
                      속도
                    </button>
                    {/* <button onClick={() => hookMember.setCircleColor(1)}>인구</button> */}
                    <button
                      onClick={() => hookMember.setCircleColor(2)}
                      className={
                        hookMember.circleColor === 2 ? "selected" : ""
                      }>
                      거리당 인구
                    </button>
                  </div>
                  {hookMember.gps.map((item, index) => {
                    var color;
                    if (hookMember.circleColor === 0) {
                      color = item.speed > 50 ? "red" : "#88F";
                    } else if (hookMember.circleColor === 1) {
                      color =
                        item.exposed > 30
                          ? "#F00"
                          : item.exposed > 20
                          ? "#F33"
                          : item.exposed > 10
                          ? "#F66"
                          : "#F99";
                    } else if (hookMember.circleColor === 2) {
                      color =
                        item.exposed2 > 3
                          ? "#F00"
                          : item.exposed2 > 2
                          ? "#F33"
                          : item.exposed2 > 1
                          ? "#F66"
                          : "#F99";
                    }

                    if (index == 0 || index == hookMember.gps.length - 1) {
                      let marker_text = index == 0 ? "시작" : "종료";
                      return (
                        <CustomOverlayMap
                          zIndex={99}
                          key={index}
                          position={item.point}>
                          <div
                            style={{
                              padding: "2px",
                              backgroundColor: "white",
                              border: "1px solid black",
                            }}>
                            {marker_text}
                          </div>
                        </CustomOverlayMap>
                      );
                    }

                    return (
                      <Circle
                        zIndex={color === "red" ? 98 : 0}
                        key={index}
                        center={item.point}
                        radius={hookMember.selectedIdx === item.idx ? 40 : 20}
                        strokeWeight={1}
                        strokeColor={"#000000"}
                        strokeOpacity={
                          hookMember.selectedIdx === item.idx ? 1 : 0
                        }
                        strokeStyle={"solid"}
                        fillColor={color}
                        fillOpacity={0.8}
                        // onClick={() => hookMember.createInfoWindow(item)}
                        onMouseover={() => hookMember.createInfoWindow(item)}
                        // onMouseout={() => hookMember.createInfoWindow(false)}
                      />
                    );
                  })}
                </Map>
              </>
            )
          ) : (
            <div>
              <p>Loading ...</p>
            </div>
          )}
        </div>
      </div>
      {!hookMember.loading ? (
        hookMember.gps.length === 0 ? (
          <div></div>
        ) : (
          <>
            <div id="dalgoT_scroll">
              <div>
                <h2>운행 정보</h2>
                <table id="dalgoT_info_table">
                  <thead>
                    <tr>
                      <td></td>
                      <td>시작시간</td>
                      <td>종료시간</td>
                      <td>운행시간</td>
                      <td>휴식시간</td>
                      <td>
                        <input
                          type="radio"
                          name="info"
                          value=""
                          checked={hookMember.radioIndex === ""}
                          onChange={(e) => {
                            hookMember.setRadioIndex(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {hookMember.driveInfo.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.idx}</td>
                          <td>{item.start}</td>
                          <td>{item.end}</td>
                          <td>{item.play}</td>
                          <td>{item.wait}</td>
                          <td>
                            {index < hookMember.driveInfo.length - 1 && (
                              <input
                                type="radio"
                                name="info"
                                value={index}
                                checked={
                                  hookMember.radioIndex === String(index)
                                }
                                onChange={(e) => {
                                  hookMember.setRadioIndex(e.target.value);
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <h2>과속 로그</h2>
              <div>
                <table id="dalgoT_info_table">
                  <thead>
                    <tr>
                      <td></td>
                      <td>시간</td>
                      <td>속도</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {hookMember.speedLog.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.time}</td>
                          <td>{item.speed} km/h</td>
                          <td>
                            <button
                              onClick={() => hookMember.createInfoWindow(item)}>
                              선택
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )
      ) : (
        <div>
          <p></p>
        </div>
      )}
    </div>
  );
};

export default DalgoTDashboard;
