import React from "react";
import "./css/schedulerComponent.scss";
import { NavLink } from "react-router-dom";

const SchedulerComponents = (data: any) => {
  // console.log(data.ads);

  const sum: any = {
    all: 0,
    ACCOUNT: 0,
    ADDD: 0,
    MEDIA_PR: 0,
    PUBLIC: 0,
    DUMMY: 0,
  };

  const count: any = {
    all: 0,
    ACCOUNT: 0,
    ADDD: 0,
    MEDIA_PR: 0,
    PUBLIC: 0,
    DUMMY: 0,
  };

  const adList: any = {};

  data.data.adList.map((item: any) => {
    if (!data.ads[item]) {
      return;
    }
    var category = data.ads[item].category;
    sum.all += data.ads[item]?.playSecond;
    sum[category] += data.ads[item]?.playSecond;
    count.all++;
    count[category]++;

    var adId = data.ads[item].adId;
    if (!adList[adId]) {
      adList[adId] = {
        name: data.ads[item]?.name,
        category: category,
        time: 0,
        count: 0,
      };
    }

    adList[adId].time += data.ads[item]?.playSecond;
    adList[adId].count++;
  });

  const category_color: any = {
    ACCOUNT: "#eed4cc",
    MEDIA_PR: "#cceedd",
    PUBLIC: "#bbccdd",
    ADDD: "#eeeecc",
    DUMMY: "#ccbbdd",
  };

  return (
    <div className="scheduler-component">
      <table>
        <thead>
          <tr>
            <th colSpan={4}>{data.data.hour}시</th>
          </tr>
          <tr>
            <td></td>
            <td>ID</td>
            <td>광고명</td>
            <td>시간</td>
          </tr>
        </thead>
        <tbody>
          {data.data.adList.map((item: any, index: number) => {
            if (!data.ads[item]) {
              return;
            }

            var hovertext = `downloadId: ${data.ads[item].downloadId}, format: ${data.ads[item].downloadFormat}`;

            return (
              <tr
                className="hovertext"
                data-hover={hovertext}
                key={index}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  backgroundColor: data.ads[item]
                    ? category_color[data.ads[item].category]
                    : "",
                }}>
                <td>{item}</td>
                <td>{data.ads[item]?.name}</td>
                <td>{data.ads[item]?.playSecond}</td>
              </tr>
            );
          })}
          <tr>
            <th colSpan={4}>
              <div>
                총 길이 : {timeText(sum.all)} ({count.all}회)
              </div>
              <div>
                구좌 광고 : {timeText(sum.ACCOUNT)}({count.ACCOUNT}회)
              </div>
              <div>
                매체 자체 광고 : {timeText(sum.MEDIA_PR)} ({count.MEDIA_PR}회)
              </div>
              <div>
                애드 광고 : {timeText(sum.ADDD)} ({count.ADDD}회)
              </div>
              <div>
                공익 광고 : {timeText(sum.PUBLIC)} ({count.PUBLIC}회)
              </div>
              <div>
                더미 광고 :{timeText(sum.DUMMY)} ({count.DUMMY}회)
              </div>
            </th>
          </tr>
          <tr>
            <td></td>
            <td>ID</td>
            <td>광고명</td>
            <td>시간(횟수)</td>
          </tr>
          {Object.entries(adList).map((item: any, index: number) => {
            return (
              <tr
                key={index}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  backgroundColor: category_color[item[1].category],
                }}>
                <td></td>
                <td>{item[0]}</td>
                <td>{item[1].name}</td>
                <td>
                  {timeText(item[1].time)} ({item[1].count})
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const timeText = (second: number) => {
  if (second < 60) {
    return second + "초";
  } else {
    return `${Math.floor(second / 60)}분 ${second % 60}초`;
  }
};

// ADDD: 0,
//     MEDIA_PR: 0,
//     PUBLIC: 0,
//     DUMMY: 0,

export default SchedulerComponents;
