import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    frameWidth: number;
    setFrameWidth: any;
    frameHeight: number;
    setFrameHeight: any;
    videoFormat: string;
    setVideoFormat: any;
    ratio: string;
    setRatio: any;
    path: string;
    setPath: any;
    category: string;
    setCategory: any;
    focusColor: string;
    setFocusColor: any;
    keyword: string;
    setKeyword: any;
    vibe: string;
    setVibe: any;
    adId: number;
    setAdId: any;
    adList: any;
    setAdList: any;
    volume: string;
    setVolume: any;
    videoFrameCount: number;
    setVideoFrameCount: any;
    videoPlayTime: number;
    setVideoPlayTime: any;
    changedCount: number;
    setChangedCount: any;

    errorMessage: any;

    createAdVideoInfo: () => void;
}

const useAdVideoInfoCreate = (): hookMember => {
    const navigate = useNavigate();

    const [frameWidth, setFrameWidth] = useState<number>(0);
    const [frameHeight, setFrameHeight] = useState<number>(0);
    const [videoFormat, setVideoFormat] = useState<string>("");
    const [ratio, setRatio] = useState<string>("");
    const [path, setPath] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [focusColor, setFocusColor] = useState<string>("");
    const [keyword, setKeyword] = useState<string>("");
    const [vibe, setVibe] = useState<string>("");
    const [adId, setAdId] = useState<number>(0);
    const [adList, setAdList] = useState([]);
    const [volume, setVolume] = useState<string>("");
    const [videoFrameCount, setVideoFrameCount] = useState<number>(0);
    const [videoPlayTime, setVideoPlayTime] = useState<number>(0);
    const [changedCount, setChangedCount] = useState<number>(0);

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const adListResponse = await fetch(`${process.env.REACT_APP_URL}/ad/all`);
                const adListData = await adListResponse.json();
    
                setAdList(adListData);

            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const createAdVideoInfo = async () => {
        try {
            if (!adId) {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.')
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/ad-video-info/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        frameWidth,
                        frameHeight,
                        videoFormat,
                        ratio,
                        path,
                        category,
                        focusColor,
                        keyword,
                        vibe,
                        adId,
                        volume,
                        videoFrameCount,
                        videoPlayTime,
                        changedCount
                    })
                })
                if(response.ok) {
                    navigate('/adComponent/AdVideoInfo/AdVideoInfoList');
                }
            }
        } catch (error) {
            console.error('네트워크 기기 생성 중 오류 발생:', error);
        }
    }
    return {
        frameWidth,
        setFrameWidth,
        frameHeight,
        setFrameHeight,
        videoFormat,
        setVideoFormat,
        ratio,
        setRatio,
        path,
        setPath,
        category,
        setCategory,
        focusColor,
        setFocusColor,
        keyword,
        setKeyword,
        vibe,
        setVibe,
        adId,
        setAdId,
        adList,
        setAdList,
        volume,
        setVolume,
        videoFrameCount,
        setVideoFrameCount,
        videoPlayTime,
        setVideoPlayTime,
        changedCount,
        setChangedCount,
        createAdVideoInfo,
        errorMessage
    }
}

export default useAdVideoInfoCreate;