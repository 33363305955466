import axios from "axios";

const fetchProfile = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return null;
    }
    const response = await axios.get(
      `${process.env.REACT_APP_URL}/auth/profile`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    return null;
  }
};

export default fetchProfile;
