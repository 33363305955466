import { useState, useEffect, useRef } from "react";
import axios, { AxiosProgressEvent } from "axios";
import { calculateNextVersion } from "../../../util/version";

interface HookMember {
  selectedPath: string;
  fileTree: any;
  contextMenuPosition: { x: number; y: number } | null;
  isFile: boolean;
  uploadProgress: number;
  isHashLoading: boolean;
  isFileConflict: boolean;
  handleFolderClick: (path: string) => void;
  handleRightClick: (
    event: React.MouseEvent,
    path: string,
    isFile: boolean
  ) => void;
  handleUploadClick: () => void;
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  handleCloseContextMenu: () => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  loadingText: string;
  handleOverwrite: () => void;
  handleUpdateVersion: () => void;
  handleCancel: () => void;
  conflictFileName: string;
  newFileVersion: string;
  key: any;
  isOpenMemoModal: boolean;
  memoText: string;
  handleMemoChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSaveMemo: () => void;
  handleCancelMemo: () => void;
}

const CHUNK_SIZE = 1 * 1024 * 1024; // 1MB

const useVersionManager = (): HookMember => {
  const [selectedPath, setSelectedPath] = useState<string>("/");
  const [fileTree, setFileTree] = useState<any>(null);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [isFile, setIsFile] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isHashLoading, setIsHashLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loadingText, setLoadingText] = useState<string>("");
  const [isFileConflict, setIsFileConflict] = useState(false);
  const [conflictFileName, setConflictFileName] = useState("");
  const [newFileVersion, setNewFileVersion] = useState("");
  const [key, setKey] = useState(0);
  const [isOpenMemoModal, setIsOpenMemoModal] = useState(false);
  const [memoText, setMemoText] = useState("");

  useEffect(() => {
    fetchFileTree();
  }, []);

  useEffect(() => {
    if (!isHashLoading) return;
    const interval = startLoadingAnimation();
    return () => clearInterval(interval);
  }, [isHashLoading]);

  const fetchFileTree = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/version/directory-tree`
      );
      setFileTree(response.data);
    } catch (error) {
      console.error("Error fetching file tree:", error);
    }
  };

  const startLoadingAnimation = () => {
    let dotCount = 0;
    return setInterval(() => {
      setLoadingText("해시값 로딩중" + ".".repeat(dotCount));
      dotCount = (dotCount + 1) % 7;
    }, 1000);
  };

  const handleFolderClick = (path: string) => setSelectedPath(path);

  const handleRightClick = (
    event: React.MouseEvent,
    path: string,
    isFile: boolean
  ) => {
    if (uploadProgress > 0) return;
    event.preventDefault();
    setSelectedPath(path);
    setContextMenuPosition({ x: event.clientX, y: event.clientY });
    setIsFile(isFile);
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
    setContextMenuPosition(null);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return resetFileInput();

    const pathSegments = selectedPath.split("/");
    const isVersionFolder = ["VERSIONS", "TESTVERSIONS"].includes(
      pathSegments[2]
    );
    const isResourceFolder = ["RESOURCES", "TESTRESOURCES"].includes(
      pathSegments[2]
    );

    if (isVersionFolder && pathSegments[3]) {
      await handleVersionFile(file, pathSegments);
    } else if (isResourceFolder) {
      await handleResourceFile(file);
    } else {
      alert("올바른 폴더를 선택해주세요.");
      resetFileInput();
    }
  };

  const handleVersionFile = async (file: any, pathSegments: any) => {
    const versionRegex = createVersionRegex(pathSegments);
    const currentPathFiles = findFilesInPath(fileTree, selectedPath);

    if (!versionRegex.test(file.name)) {
      return alertInvalidFileName(pathSegments);
    }

    if (currentPathFiles.includes(file.name)) {
      handleFileConflict(file, currentPathFiles, versionRegex);
    } else {
      setIsOpenMemoModal(true);
    }
  };

  const createVersionRegex = (pathSegments: any) => {
    return new RegExp(
      `^${pathSegments[1]}_${pathSegments[3]}_\\d+\\.\\d+\\.\\d+`
    );
  };

  const alertInvalidFileName = (pathSegments: any) => {
    alert(
      `파일 이름이 올바른 형식이 아닙니다: ${pathSegments[1]}_${pathSegments[3]}_x.x.x`
    );
  };

  const handleFileConflict = (
    file: any,
    currentPathFiles: any,
    versionRegex: any
  ) => {
    const versionFiles = currentPathFiles.filter((name: any) =>
      versionRegex.test(name)
    );
    const nextVersion = calculateNextVersion(versionFiles);
    setNewFileVersion(nextVersion);
    setConflictFileName(file.name);
    setIsFileConflict(true);
  };

  const handleOverwrite = async () => {
    setIsFileConflict(false);
    if (!fileInputRef.current?.files?.[0]) {
      return alert("업로드할 파일이 선택되지 않았습니다.");
    }
    setNewFileVersion("");
    setIsOpenMemoModal(true);
  };

  const handleCancel = () => {
    resetConflictState();
  };

  const handleUpdateVersion = async () => {
    setIsFileConflict(false);
    if (!fileInputRef.current?.files?.[0]) {
      return alert("업로드할 파일이 선택되지 않았습니다.");
    }
    setIsOpenMemoModal(true);
  };

  const handleSaveMemo = async () => {
    if (!fileInputRef.current?.files?.[0]) {
      return alert("업로드할 파일이 선택되지 않았습니다.");
    }

    setIsOpenMemoModal(false);

    const file = fileInputRef.current.files[0];
    if (newFileVersion !== "") {
      const newFileName = newFileVersion;
      const newFile = new File([file], newFileName, { type: file.type });
      await uploadFileChunks(newFile);
    } else {
      await uploadFileChunks(file);
    }

    resetConflictState();
  };

  const handleResourceFile = async (file: any) => {
    await uploadFileChunks(file);
  };

  const handleCloseContextMenu = () => {
    setContextMenuPosition(null);
    setSelectedPath("/");
  };

  const findFilesInPath = (fileTree: any, path: any) => {
    const pathSegments = path.split("/");
    let currentNode = fileTree;
    for (const segment of pathSegments) {
      if (segment === "") continue;
      currentNode = currentNode.children.find(
        (child: any) => child.name === segment
      );
      if (!currentNode) return [];
    }
    return currentNode.children.map((file: any) => file.name);
  };

  const uploadFileChunks = async (file: any) => {
    setIsHashLoading(true);
    const token = sessionStorage.getItem("token");

    try {
      const sha256Hash = await fetchFileHash(file, token);
      setIsHashLoading(false);

      const response = await uploadChunks(file, sha256Hash, token);

      fetchFileTree();
      resetFileInput();
      setUploadProgress(0);
    } catch (error) {
      handleUploadError(error);
    }
  };

  const fetchFileHash = async (file: any, token: string | null) => {
    const formDataForHash = new FormData();
    formDataForHash.append("file", file);

    const hashResponse = await axios.post(
      `${process.env.REACT_APP_URL}/version/hash`,
      formDataForHash,
      { headers: { token } }
    );

    return hashResponse.data.hash;
  };

  const uploadChunks = async (
    file: any,
    sha256Hash: string,
    token: string | null
  ) => {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const chunk = file.slice(
        chunkIndex * CHUNK_SIZE,
        (chunkIndex + 1) * CHUNK_SIZE
      );
      const formData = createChunkFormData(
        chunk,
        file,
        chunkIndex,
        totalChunks,
        sha256Hash
      );

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/version/upload-chunk`,
          formData,
          {
            headers: { token },
            onUploadProgress: (progressEvent) =>
              updateUploadProgress(progressEvent, chunkIndex, totalChunks),
          }
        );

        // 마지막 청크 업로드 후 응답 상태와 메시지 검사
        if (chunkIndex === totalChunks - 1) {
          if (response.status === 201 && response.data.status === 500) {
            alert(response.data.message); // DB 문제로 인한 오류 메시지 출력
          } else if (response.data.status === 200) {
            alert(response.data.message);
          }
        }
      } catch (error) {
        // 네트워크 오류나 서버 오류 처리
        alert("파일 업로드 중 오류가 발생했습니다: ");
      }
    }
  };
  const createChunkFormData = (
    chunk: Blob,
    file: File,
    chunkIndex: number,
    totalChunks: number,
    sha256Hash: string
  ) => {
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("chunkIndex", chunkIndex.toString());
    formData.append("totalChunks", totalChunks.toString());
    formData.append("originalName", file.name);
    formData.append("path", selectedPath);
    formData.append("sha256Hash", sha256Hash);
    if (memoText) formData.append("memo", memoText.toString());
    return formData;
  };

  const updateUploadProgress = (
    progressEvent: any, // 여기서 `any`로 설정하여 TypeScript 오류 방지
    chunkIndex: number,
    totalChunks: number
  ) => {
    const axiosProgressEvent = progressEvent as AxiosProgressEvent; // 타입 캐스팅

    if (!axiosProgressEvent?.total) return;
    const percentCompleted = Math.round(
      (axiosProgressEvent.loaded * 100) / axiosProgressEvent.total
    );
    setUploadProgress(
      Math.round(
        (chunkIndex / totalChunks) * 100 + percentCompleted / totalChunks
      )
    );
  };

  const handleUploadError = (error: any) => {
    setIsHashLoading(false);
    setUploadProgress(0);
    resetFileInput();

    if (axios.isAxiosError(error)) {
      if (error.response?.status === 500) {
        alert("서버 오류가 발생했습니다. 관리자에게 문의하세요.");
        console.error("500 Internal Server Error:", error.response.data);
      } else if (error.response?.status === 403) {
        alert("권한이 없는 계정입니다.");
      } else {
        console.error("파일 업로드 중 오류 발생:", error);
        alert("파일 업로드 중 오류가 발생했습니다.");
      }
    } else {
      console.error("알 수 없는 오류 발생:", error);
      alert("알 수 없는 오류가 발생했습니다.");
    }
  };

  const resetFileInput = () => setKey((prevKey) => prevKey + 1); //같은 파일명일때 업로드 버튼동작하지않는 경우를 해결

  const resetConflictState = () => {
    setIsFileConflict(false);
    setMemoText("");
    setIsOpenMemoModal(false);
    setNewFileVersion("");
    setConflictFileName("");
  };

  return {
    selectedPath,
    isFileConflict,
    fileTree,
    contextMenuPosition,
    isFile,
    uploadProgress,
    isHashLoading,
    handleFolderClick,
    handleRightClick,
    handleUploadClick,
    handleFileChange,
    loadingText,
    handleCloseContextMenu,
    fileInputRef,
    handleOverwrite,
    handleUpdateVersion,
    handleCancel,
    conflictFileName,
    newFileVersion,
    key,
    isOpenMemoModal,
    memoText,
    handleMemoChange: (e) => setMemoText(e.target.value),
    handleSaveMemo,
    handleCancelMemo: resetConflictState,
  };
};

export default useVersionManager;
