import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  advertiserId: number;
  setAdvertiserId: any;
  company: string;
  setCompany: any;
  adminName: string;
  setAdminName: any;
  adminPhone: string;
  setAdminPhone: any;
  adminPosition: string;
  setAdminPosition: any;
  remainMoney: number;
  setRemainMoney: any;

  errorMessage: any;

  updateAdvertiserData: () => void;
}

const useAdvertiserUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [advertiserId, setAdvertiserId] = useState<number>(0);
  const [company, setCompany] = useState<string>("");
  const [adminName, setAdminName] = useState<string>("");
  const [adminPhone, setAdminPhone] = useState<string>("");
  const [adminPosition, setAdminPosition] = useState<string>("");
  const [remainMoney, setRemainMoney] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.advertiserId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/advertiser/one?id=${params.advertiserId}`
          );
          const jsonData = await response.json();

          const {
            advertiserId,
            company,
            adminName,
            adminPhone,
            adminPosition,
            remainMoney,
          } = jsonData.data;

          setAdvertiserId(advertiserId);
          setCompany(company);
          setAdminName(adminName);
          setAdminPhone(adminPhone);
          setAdminPosition(adminPosition);
          setRemainMoney(remainMoney);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.advertiserId]);

  const updateAdvertiserData = () => {
    const result = {
      advertiserId,
      company,
      adminName,
      adminPhone,
      adminPosition,
      remainMoney,
    };

    if (!adminName) {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/advertiser/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            advertiserId,
            company,
            adminName,
            adminPhone,
            adminPosition,
            remainMoney,
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/adComponent/advertiser/advertiserList");
      }
    });
  };

  return {
    advertiserId,
    setAdvertiserId,
    company,
    setCompany,
    adminName,
    setAdminName,
    adminPhone,
    setAdminPhone,
    adminPosition,
    setAdminPosition,
    remainMoney,
    setRemainMoney,
    errorMessage,
    updateAdvertiserData,
  };
};

export default useAdvertiserUpdate;
