import { useState, useEffect } from "react";
import Select from "react-select";
import useActualImageCreate from "./hooks/useActualImageCreate";
import OvalSpinner from "../../components/atoms/loading/OvalSpinner";
import ReactDatePicker from "react-datepicker";

const ActualImageCreate = () => {
  const hookMember = useActualImageCreate();
  const [imagePreviewURLs, setImagePreviewURLs] = useState<string[]>([]);
  const [videoPreviewURLs, setVideoPreviewURLs] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const imageFiles = Array.from(files);
      hookMember.setImages(imageFiles);

      // 이미지 파일을 미리보기 URL로 변환하여 설정
      const imageURLs = imageFiles.map((file) => URL.createObjectURL(file));
      setImagePreviewURLs(imageURLs);
    }
  };

  const handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const videoFiles = Array.from(files);
      hookMember.setVideos(videoFiles);

      // 비디오 파일을 미리보기 URL로 변환하여 설정
      const videoURLs = videoFiles.map((file) => URL.createObjectURL(file));
      setVideoPreviewURLs(videoURLs);
    }
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      await hookMember.createActualImage();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <div>
      {isLoading && <OvalSpinner />}
      <div className="input-table">
        <h1>실사 문서</h1>
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>
            실사 매체 <span className="required">*</span>
          </label>
          <input
            value={hookMember.actualImageName}
            onChange={(e) => hookMember.setActualImageName(e.target.value)}
          ></input>
        </div>
        <div>
          <label>작업 날짜</label>
          <ReactDatePicker
            dateFormat="yyyy-MM-dd" // 날짜 형태
            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
            minDate={new Date("2000-01-01")} // minDate 이전 날짜 선택 불가
            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
            selected={hookMember.workDate}
            onChange={(date) => hookMember.setWorkDate(date)}
          />
        </div>
        <div>
          <label>비고란</label>
          <textarea
            name=""
            id=""
            value={hookMember.comment}
            onChange={(e) => hookMember.setComment(e.target.value)}
            className="largeTextArea"
          />
        </div>
        <div>
          <label>이미지 업로드</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            multiple
          />
          <div className="image-preview">
            {imagePreviewURLs.map((url, index) => (
              <img key={index} src={url} alt={`Preview ${index}`} />
            ))}
          </div>
        </div>
        <div>
          <label>영상 업로드</label>
          <input
            type="file"
            accept="video/*"
            onChange={handleVideoChange}
            multiple
          />
          <div className="video-preview">
            {videoPreviewURLs.map((url, index) => (
              <video key={index} src={url} controls />
            ))}
          </div>
        </div>
        <span style={{ color: "navy" }}>* 영상 용량 제한: 100mb</span>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={handleUpdate}>저장</button>
      </div>
    </div>
  );
};

export default ActualImageCreate;
