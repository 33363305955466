import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  networkDeviceTypeId: number;
  setNetworkDeviceTypeId: any;
  name: string;
  setName: any;
  connectionType: string;
  setConnectionType: any;
  devicePrice: number;
  setDevicePrice: any;
  monthlyFee: number;
  setMonthlyFee: any;
  labelPrefix: string;
  setLabelPrefix: any;
  company: string;
  setCompany: any;
  modelName: string;
  setModelName: any;
  errorMessage: any;

  updateNetworkDeviceTypeData: () => void;
}

const useNetworkDeviceTypeUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [networkDeviceTypeId, setNetworkDeviceTypeId] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [connectionType, setConnectionType] = useState<string>("");
  const [devicePrice, setDevicePrice] = useState<number>(0);
  const [monthlyFee, setMonthlyFee] = useState<number>(0);
  const [labelPrefix, setLabelPrefix] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [modelName, setModelName] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.networkDeviceTypeId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/network-device-type/one?id=${params.networkDeviceTypeId}`
          );
          const jsonData = await response.json();

          const {
            networkDeviceTypeId,
            name,
            connectionType,
            devicePrice,
            monthlyFee,
            labelPrefix,
            company,
            modelName
          } = jsonData.data;

          setNetworkDeviceTypeId(networkDeviceTypeId);
          setName(name);
          setConnectionType(connectionType);
          setDevicePrice(devicePrice);
          setMonthlyFee(monthlyFee);
          setLabelPrefix(labelPrefix);
          setCompany(company);
          setModelName(modelName);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.networkDeviceTypeId]);

  const updateNetworkDeviceTypeData = () => {
    const result = {
      networkDeviceTypeId,
      name,
      connectionType,
      devicePrice,
      monthlyFee,
      labelPrefix,
      company,
      modelName
    };

    if (name == "") {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/network-device-type/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            networkDeviceTypeId,
            name,
            connectionType,
            devicePrice,
            monthlyFee,
            labelPrefix,
            company,
            modelName
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/networkComponent/networkDeviceType/networkDeviceTypeList");
      }
    });
  };

  return {
    networkDeviceTypeId,
    setNetworkDeviceTypeId,
    name,
    setName,
    connectionType,
    setConnectionType,
    devicePrice,
    setDevicePrice,
    monthlyFee,
    setMonthlyFee,
    labelPrefix,
    setLabelPrefix,
    company,
    setCompany,
    modelName,
    setModelName,
    errorMessage,
    updateNetworkDeviceTypeData,
  };
};

export default useNetworkDeviceTypeUpdate;