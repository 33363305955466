import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
  actualImageName: string;
  setActualImageName: any;
  comment: string;
  setComment: any;
  images: File[];
  setImages: any;
  videos: File[];
  setVideos: any;
  workDate: Date;
  setWorkDate: any;
  
  errorMessage: any;

  createActualImage: () => void;
}

const useActualImageCreate = (): hookMember => {
  const navigate = useNavigate();

  const [actualImageName, setActualImageName] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [images, setImages] = useState<File[]>([]);
  const [videos, setVideos] = useState<File[]>([]);
  const [workDate, setWorkDate] = useState<Date>(new Date());

  const [errorMessage, setErrorMessage] = useState("");

  const createActualImage = async () => {
    try {
      if (!actualImageName) {
        setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      } else {
        const commonBody = {
          actualImageName,
          category: "actualImage",
          comment,
          workDate,
        };

        const imageFormData = new FormData();
        imageFormData.append("mediaId", actualImageName);
        imageFormData.append("category", "actualImage");
        imageFormData.append("type", "images");
        images.forEach((image) => {
          imageFormData.append('files', image);
        });

        const videoFormData = new FormData();
        videoFormData.append("mediaId",actualImageName);
        videoFormData.append("category", "actualImage");
        videoFormData.append("type", "videos");
        videos.forEach((video) => {
          videoFormData.append('files', video);
        });

        const response = await fetch(
          `${process.env.REACT_APP_URL}/install-report/create-actual-image`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(commonBody),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to create actual image');
        }

        const imageResponse = await fetch(
          `${process.env.REACT_APP_URL}/install-report/upload-files`,
          {
            method: "POST",
            body: imageFormData,
          }
        );

        if (!imageResponse.ok) {
          throw new Error('Failed to upload images');
        }

        const videoResponse = await fetch(
          `${process.env.REACT_APP_URL}/install-report/upload-files`,
          {
            method: "POST",
            body: videoFormData,
          }
        );

        if (!videoResponse.ok) {
          throw new Error('Failed to upload videos');
        }

        navigate("/installReport/installReportList");
      }
    } catch (error) {
      setErrorMessage("네트워크 기기 생성 중 오류 발생");
    }
  };
  
  return {
    actualImageName,
    setActualImageName,
    comment,
    setComment,
    createActualImage,
    images,
    setImages,
    videos,
    setVideos,
    workDate,
    setWorkDate,
    errorMessage,
  };
};

export default useActualImageCreate;
