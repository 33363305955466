// 파일명에서 버전 정보 및 파일명만 추출하는 함수
export const extractFilename = (filename: string): string => {
  const nameMatch = filename.match(/(\w+)_(\d+\.\d+\.\d+)/);
  return nameMatch ? nameMatch[1] : ""; // 파일명 추출 (버전과 확장자 제외)
};

// 버전 정보를 추출하고 버전 배열로 변환하는 함수
export const parseVersion = (filename: string): [number[], string] => {
  const versionMatch = filename.match(/_(\d+)\.(\d+)\.(\d+)(\.[a-zA-Z]+)?$/);
  const version = versionMatch
    ? [
        parseInt(versionMatch[1]),
        parseInt(versionMatch[2]),
        parseInt(versionMatch[3]),
      ]
    : [0, 0, 0];
  const extension = versionMatch && versionMatch[4] ? versionMatch[4] : ""; // 확장자 저장
  return [version, extension];
};

// 버전을 내림차순으로 정렬하는 함수
export const compareVersions = (
  a: [number[], string],
  b: [number[], string]
): number => {
  const aVersion = a[0];
  const bVersion = b[0];
  for (let i = 0; i < Math.min(aVersion.length, bVersion.length); i++) {
    if (aVersion[i] !== bVersion[i]) {
      return bVersion[i] - aVersion[i]; // 내림차순 정렬
    }
  }
  return bVersion.length - aVersion.length;
};

export const calculateNextVersion = (files: string[]): string => {
  const versionsWithExtensions = files.map(parseVersion).sort(compareVersions);
  const [latestVersion, extension] = versionsWithExtensions[0]; // 최신 버전과 확장자 추출
  const filename = extractFilename(
    files.find(
      (file) => parseVersion(file)[0].join(".") === latestVersion.join(".")
    ) || ""
  ); // 최신 버전 파일명 추출

  latestVersion[2] += 1; // 패치 버전 증가

  const newVersionString =
    extension !== ""
      ? `${filename}_${latestVersion.join(".")}${extension}`
      : `${filename}_${latestVersion.join(".")}`;

  return newVersionString;
};
