import React from "react";
import "./list.scss"; // SCSS 파일을 임포트하세요.

export const ListModal = ({ isOpen, onClose, children, title }: any) => {
  if (!isOpen) return null;

  return (
    <div className="modal-container" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2 className="modal-title"> {title}</h2>
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <button className="button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListModal;
