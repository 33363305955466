import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  installReportId: number;
  setInstallReportId: any;
  mediaList: any;
  setMediaList: any;
  mediaId: number;
  setMediaId: any;
  mediaName: string;
  setMediaName: any;
  aiDeviceList: any;
  setAiDeviceList: any;
  aiDeviceLabel: string;
  setAiDeviceLabel: any;
  aiDeviceId: number;
  setAiDeviceId: any;
  networkDeviceId: number;
  setNetworkDeviceId: any;
  networkDeviceList: any;
  setNetworkDeviceList: any;
  networkDeviceLabel: string;
  setNetworkDeviceLabel: any;
  cameraId: number;
  setCameraId: any;
  cameraList: any;
  setCameraList: any;
  cameraLabel: string;
  setCameraLabel: any;
  comment: string;
  setComment: any;
  images: File[];
  setImages: any;
  videos: File[];
  setVideos: any;
  workDate: Date;
  setWorkDate: any;

  errorMessage: any;

  updateInstallData: () => void;
}

const useInstallUpdate = (): hookMember => {
  const params = useParams();
  const navigate = useNavigate();

  const [installReportId, setInstallReportId] = useState<number>(0);
  const [mediaList, setMediaList] = useState([]);
  const [mediaId, setMediaId] = useState<number>(0);
  const [mediaName, setMediaName] = useState<string>("");
  const [aiDeviceList, setAiDeviceList] = useState([]);
  const [aiDeviceId, setAiDeviceId] = useState<number>(0);
  const [aiDeviceLabel, setAiDeviceLabel] = useState<string>("");
  const [networkDeviceId, setNetworkDeviceId] = useState<number>(0);
  const [networkDeviceList, setNetworkDeviceList] = useState([]);
  const [networkDeviceLabel, setNetworkDeviceLabel] = useState<string>("");
  const [cameraId, setCameraId] = useState<number>(0);
  const [cameraList, setCameraList] = useState([]);
  const [cameraLabel, setCameraLabel] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [images, setImages] = useState<File[]>([]);
  const [videos, setVideos] = useState<File[]>([]);
  const [workDate, setWorkDate] = useState<Date>(new Date());

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.installReportId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/install-report/update-one?id=${params.installReportId}`
          );
          const jsonData = await response.json();
          const mediaListResponse = await fetch(
            `${process.env.REACT_APP_URL}/media/all`
          );
          const mediaListData = await mediaListResponse.json();

          setMediaList(mediaListData);

          const aiDeviceListResponse = await fetch(
            `${process.env.REACT_APP_URL}/ai-device/selectable`
          );
          const aiDeviceListData = await aiDeviceListResponse.json();
  
          setAiDeviceList(aiDeviceListData);

          const networkDeviceListResponse = await fetch(
            `${process.env.REACT_APP_URL}/network-device/all`
          );
          const networkDeviceListData = await networkDeviceListResponse.json();
  
          setNetworkDeviceList(networkDeviceListData);
  
          const cameraListResponse = await fetch(
            `${process.env.REACT_APP_URL}/camera/all`
          );
          const cameraListData = await cameraListResponse.json();
  
          setCameraList(cameraListData);

          const {
            installReportId,
            mediaId,
            comment,
            aiDeviceId,
            networkDeviceId,
            cameraId,
            workDate,
          } = jsonData.data;

          setInstallReportId(installReportId);
          setMediaId(mediaId);
          setComment(comment);
          setAiDeviceId(aiDeviceId);
          setAiDeviceLabel(jsonData.data.aiDevice.label);
          setMediaName(jsonData.data.media.name);
          setNetworkDeviceId(networkDeviceId);
          setCameraId(cameraId);
          setNetworkDeviceLabel(jsonData.data.networkDevice.label);
          setCameraLabel(jsonData.data.camera.label);
          setWorkDate(workDate);
        }
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, [params.installreportId]);

  const updateInstallData = async () => {
    const result = {
      installReportId,
      mediaId,
      comment,
      aiDeviceId,
      networkDeviceId,
      cameraId,
      workDate,
    };

    if (!mediaId) {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
    } else {
      const commonBody = {
        installReportId,
        mediaId,
        category: "install",
        comment,
        aiDeviceId,
        networkDeviceId,
        cameraId,
        workDate,
      };

      const imageFormData = new FormData();
      imageFormData.append("mediaId", mediaId.toString());
      imageFormData.append("category", "install");
      imageFormData.append("type", "images");
      images.forEach((image) => {
        imageFormData.append('files', image);
      });

      const videoFormData = new FormData();
      videoFormData.append("mediaId", mediaId.toString());
      videoFormData.append("category", "install");
      videoFormData.append("type", "videos");
      videos.forEach((video) => {
        videoFormData.append('files', video);
      });

      const response = await fetch(
        `${process.env.REACT_APP_URL}/install-report/update-install`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(commonBody),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to create install');
      }

      const imageResponse = await fetch(
        `${process.env.REACT_APP_URL}/install-report/upload-files`,
        {
          method: "POST",
          body: imageFormData,
        }
      );

      if (!imageResponse.ok) {
        throw new Error('Failed to upload images');
      }

      const videoResponse = await fetch(
        `${process.env.REACT_APP_URL}/install-report/upload-files`,
        {
          method: "POST",
          body: videoFormData,
        }
      );

      if (!videoResponse.ok) {
        throw new Error('Failed to upload videos');
      }

      navigate("/installReport/installReportList");

    }
  };

  return {
    installReportId,
    setInstallReportId,
    mediaList,
    setMediaList,
    mediaId,
    setMediaId,
    mediaName,
    setMediaName,
    aiDeviceList,
    setAiDeviceList,
    aiDeviceId,
    setAiDeviceId,
    aiDeviceLabel,
    setAiDeviceLabel,
    networkDeviceId,
    setNetworkDeviceId,
    networkDeviceList,
    setNetworkDeviceList,
    cameraId,
    setCameraId,
    cameraList,
    setCameraList,
    networkDeviceLabel,
    setNetworkDeviceLabel,
    cameraLabel,
    setCameraLabel,
    comment,
    setComment,
    updateInstallData,
    images,
    setImages,
    videos,
    setVideos,
    workDate,
    setWorkDate,
    errorMessage,
  };
};

export default useInstallUpdate;
