import useDalgoTDashboard from "./hooks/useDalgoTPath";
import "./css/useDalgoTPath.scss";
import {
  Map,
  Circle,
  Polyline,
  MapMarker,
  CustomOverlayMap,
  MapInfoWindow,
} from "react-kakao-maps-sdk";

const DalgoTPath: any = () => {
  const hookMember = useDalgoTDashboard();

  return (
    <div id="dalgoTpath">
      <h1 style={{ margin: "0px" }}>달고 T - 경로 관리(제작중)</h1>
      <div>
        <input
          type="date"
          value={hookMember.date}
          onChange={(e) => {
            hookMember.setDate(e.target.value);
          }}
        />
        <select
          id="media_select"
          onChange={(e) => hookMember.setMedia(Number(e.target.value))}>
          {hookMember.mediaList.map((item: any, index: number) => {
            return (
              <option value={item.authNumber} key={index}>
                {item.authNumber}) {item.name}
              </option>
            );
          })}
        </select>
        <button onClick={() => hookMember.refresh()}>새로고침</button>
      </div>
      {!hookMember.loading && (
        <>
          <div>
            <div id="dalgoPath_colorButton">
              <button
                onClick={() => {
                  hookMember.setPolyline(!hookMember.polyline);
                }}
                className={hookMember.polyline ? "selected" : ""}>
                범위 표시
              </button>
              <input
                type="range"
                max={200}
                min={50}
                step={10}
                value={hookMember.polylineWeight}
                onChange={(e) => {
                  hookMember.setPolylineWeight(Number(e.target.value));
                }}
              />
              <label>{hookMember.polylineWeight}m</label>
            </div>
            <Map
              center={{ lat: 37.48538416666667, lng: 127.036308 }}
              style={{ width: "100%", height: "600px" }} // 지도 크기
              level={hookMember.level}
              onZoomChanged={(e) => hookMember.setLevel(e.getLevel())}
              minLevel={7}
              onClick={(_, mouseEvent) => {
                hookMember.createPathArray(mouseEvent.latLng);
              }}>
              {hookMember.polyline && (
                <Polyline
                  path={hookMember.pathArray}
                  strokeWeight={hookMember.strokeWeight}
                  strokeColor="black"></Polyline>
              )}

              {hookMember.test.map((item, index) => {
                return (
                  <Circle
                    key={index}
                    center={{ lat: item[1], lng: item[2] }}
                    radius={item[6] > hookMember.polylineWeight ? 24 : 6}
                    strokeWeight={0}
                    zIndex={item[6] > hookMember.polylineWeight ? 9999 : 1}
                    fillColor={
                      item[6] > hookMember.polylineWeight ? "red" : "blue"
                    }
                    fillOpacity={
                      item[6] > hookMember.polylineWeight ? 1 : 1
                    }></Circle>
                );
              })}
            </Map>
          </div>
          <div>
            <button
              onClick={() => {
                hookMember.initPathArray();
              }}>
              초기화
            </button>
            <button
              onClick={() => {
                hookMember.returnPathArray();
              }}>
              뒤로가기
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                hookMember.savePathArray();
              }}>
              저장
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default DalgoTPath;
