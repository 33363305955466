import React, { useEffect, useState } from "react";
import {useLocation} from "react-router-dom";
import style from "./FilterDropdown.module.scss";
import SearchColumnDropdown from "./searchColumnDropdown";

interface Item {
  [key: string]: string | number | boolean;
}

export function FilterDropdown({
  columns,
  tableData,
  setTableData,
  allData,
}: any) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSearchColumns, setSelectedSearchColumns] = useState(columns[0]);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const [selectedColumns, setSelectedColumns] = useState<{
    [key: string]: boolean;
  }>(
    columns.reduce((acc: any, column: any) => {
      acc[column] = true;
      return acc;
    }, {} as { [key: string]: boolean })
  );

  const pageId = location.pathname;
  const localStorageKey = `selectedColumns_${location.pathname}`;

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const savedColumns = localStorage.getItem(localStorageKey);
    if (savedColumns) {
      try {
        const parsedColumns = JSON.parse(savedColumns);
        setSelectedColumns(parsedColumns);
      } catch (error) {
        console.error("Error parsing saved column settings:", error);
      }
    } else {
      const defaultColumns = columns.reduce((acc: any, column: any) => {
        acc[column] = true;
        return acc;
      }, {});
      setSelectedColumns(defaultColumns);
    }
  }, [columns, localStorageKey]);

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;
    setSelectedColumns((prev: any) => {
      const updatedColumns = { ...prev, [name]: checked };
      localStorage.setItem(localStorageKey, JSON.stringify(updatedColumns));
      return updatedColumns;
    });
  };

  useEffect(() => {
    if (!allData) return;

    const filteredData = allData.filter((item: any) => {
      if (selectedSearchColumns && searchTerm) {
        const value = item[selectedSearchColumns];
        return (
          value !== undefined &&
          value !== null &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      return true;
    });

    const filteredTableData: Item[] = filteredData.map((item: Item) => {
      const filteredItem: Partial<Item> = {};
      Object.keys(item).forEach((key: keyof Item) => {
        if (selectedColumns[key]) {
          filteredItem[key] = item[key];
        }
      });
      return filteredItem as Item;
    });

    setTableData(filteredTableData);
  }, [searchTerm, selectedSearchColumns, allData, selectedColumns]);

  useEffect(() => {
    setSearchTerm("");
  }, [selectedSearchColumns]);

  return (
    <div className={style.dropdown}>
      <button onClick={toggleDropdown}>
        <FilterIcon />
        Filter
      </button>
      {isOpen && (
        <div className={style["dropdown-menu"]}>
          <SearchColumnDropdown
            columns={columns}
            selectedColumn={selectedSearchColumns}
            setSelectedColumn={setSelectedSearchColumns}
          />
          <input
            type="text"
            placeholder="Search columns"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className={style["checkbox-list"]}>
            {columns.map((column: any) => (
              <label key={column}>
                <input
                  type="checkbox"
                  name={column}
                  checked={selectedColumns[column]}
                  onChange={handleCheckboxChange}
                />
                <span>{column}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterDropdown;

function FilterIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
    </svg>
  );
}
