import React, { useState } from "react";
import style from "./SearchColumnDropdown.module.scss";

export function SearchColumnDropdown({
  columns,
  selectedColumn,
  setSelectedColumn,
}: any) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (column: any) => {
    setSelectedColumn(column);
    setIsOpen(false); // 컬럼 선택 후 드롭다운 닫기
  };

  return (
    <div className={style.dropdown}>
      <button onClick={toggleDropdown}>
        <FilterIcon />
        {selectedColumn || "컬럼 선택"}
      </button>
      {isOpen && (
        <div className={style["dropdown-menu"]}>
          {columns.map((column: any) => (
            <button
              key={column}
              onClick={() => handleSelect(column)}
              className={style["dropdown-item"]}
            >
              {column}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchColumnDropdown;

function FilterIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
    </svg>
  );
}
