import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    name: string;
    setName: any;
    connectionType: string;
    setConnectionType: any;
    devicePrice: number;
    setDevicePrice: any;
    monthlyFee: number;
    setMonthlyFee: any;
    labelPrefix: string;
    setLabelPrefix: any;
    company: string;
    setCompany: any;
    modelName: string;
    setModelName: any;

    errorMessage: any;

    createNetworkDeviceType: () => void;
}

const useNetworkDeviceTypeCreate = (): hookMember => {
    const navigate = useNavigate();

    const [name, setName] = useState<string>("");
    const [connectionType, setConnectionType] = useState<string>("");
    const [devicePrice, setDevicePrice] = useState<number>(0);
    const [monthlyFee, setMonthlyFee] = useState<number>(0);
    const [labelPrefix, setLabelPrefix] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [modelName, setModelName] = useState<string>("");

    const [errorMessage, setErrorMessage] = useState('');

    const createNetworkDeviceType = async () => {
        try {
            if (name === "" ) {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.')
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/network-device-type/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name,
                        connectionType,
                        devicePrice,
                        monthlyFee,
                        labelPrefix,
                        company,
                        modelName
                    })
                })
                if(response.ok) {
                    navigate('/networkComponent/networkDeviceType/networkDeviceTypeList');
                } else {
                    console.error('네트워크 기기 종류 생성에 실패했습니다.');
                    // console.error(response);
                }
            }
        } catch (error) {
            console.error('네트워크 기기 종류 생성 중 오류 발생:', error);
        }
    }
    return {
        name,
        setName,
        connectionType,
        setConnectionType,
        devicePrice,
        setDevicePrice,
        monthlyFee,
        setMonthlyFee,
        labelPrefix,
        setLabelPrefix,
        company,
        setCompany,
        modelName,
        setModelName,
        createNetworkDeviceType,
        errorMessage
    }
}

export default useNetworkDeviceTypeCreate;