import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    name: string;
    setName: any;
    modelName: string;
    setModelName: any;
    price: number;
    setPrice: any;
    deviceType: string;
    setDeviceType: any;
    displayType: string;
    setDisplayType: any;
    width: number;
    setWidth: any;
    height: number;
    setHeight: any;
    depth: number;
    setDepth: any;
    resolutionWidth: number;
    setResolutionWidth: any;
    resolutionHeight: number;
    setResolutionHeight: any;
    brand: string;
    setBrand: any;

    errorMessage: any;

    createMediaDevice: () => void;
}

const useMediaDeviceCreate = (): hookMember => {
    const navigate = useNavigate();

    const [name, setName] = useState<string>("");
    const [modelName, setModelName] = useState<string>("");
    const [price, setPrice] = useState<number>(0);
    const [deviceType, setDeviceType] = useState<string>("");
    const [displayType, setDisplayType] = useState<string>("");
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [depth, setDepth] = useState<number>(0);
    const [resolutionWidth, setResolutionWidth] = useState<number>(0);
    const [resolutionHeight, setResolutionHeight] = useState<number>(0);
    const [brand, setBrand] = useState<string>("");

    const [errorMessage, setErrorMessage] = useState('');

    const createMediaDevice = async () => {
        try {
            if (name === "" || !deviceType || !displayType) {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.')
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/media-device/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name,
                        modelName,
                        price,
                        deviceType,
                        displayType,
                        width,
                        height,
                        depth,
                        resolutionWidth,
                        resolutionHeight,
                        brand
                    })
                })
                if(response.ok) {
                    navigate('/mediaComponent/mediaDevice/mediaDeviceList');
                }
            }
        } catch (error) {
            console.error('매체 기기 생성 중 오류 발생:', error);
        }
    }
    return {
        name,
        setName,
        modelName,
        setModelName,
        price,
        setPrice,
        deviceType,
        setDeviceType,
        displayType,
        setDisplayType,
        width,
        setWidth,
        height,
        setHeight,
        depth,
        setDepth,
        resolutionWidth,
        setResolutionWidth,
        resolutionHeight,
        setResolutionHeight,
        brand,
        setBrand,
        createMediaDevice,
        errorMessage
    }
}

export default useMediaDeviceCreate;