import useDailyDashboard from "./hooks/useDailyDashboard";
import "./css/dailyDashboard.scss";

const DailyDashboard: any = () => {
  const hookMember = useDailyDashboard();

  return (
    <div className="daily-table">
      <h1>일간 데이터 관제</h1>
      {!hookMember.loading ? (
        <div>
          <div>
            <div>날짜</div>
            <input
              type="date"
              value={hookMember.date}
              onChange={(e) => {
                hookMember.setDate(e.target.value);
              }}
            />
            <div></div>
            <div>필터 </div>
            <select
              value={hookMember.sort}
              onChange={(e) => {
                hookMember.setSort(e.target.value);
              }}>
              <option value="">모든 매체</option>
              <option value="all">전체 이상 매체</option>
              <option value="app">CSV 이상 매체</option>
              {/* <option value="image">IMG 이상 매체</option> */}
              <option value="data">DATA 이상 매체</option>
              <option value="network">NETWORK 이상 매체</option>
            </select>
          </div>
          <table>
            <thead>
              <tr>
                <th>매체명</th>
                <th>매체번호</th>
                <th>CSV</th>
                {/* <th>IMAGE</th> */}
                <th>DATA</th>
                <th>NETWORK</th>
              </tr>
            </thead>
            <tbody>
              {hookMember.medias.length === 0 ? (
                <tr>
                  <td colSpan={6}>해당 매체가 없습니다.</td>
                </tr>
              ) : (
                hookMember.medias.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.authNumber}</td>
                      <td>{colorDiv(item.app)}</td>
                      {/* <td>{colorDiv(item.image)}</td> */}
                      <td>{colorDiv(item.data)}</td>
                      <td>{colorDiv(item.network)}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <p>Loading ...</p>
        </div>
      )}
    </div>
  );
};

const colorDiv: any = (status: "O" | "X" | "err") => {
  // const backgroundColor = status === "O" ? "#4ADE80" : "#EF4444";
  let backgroundColor = {
    O: "#4ADE80",
    X: "#EF4444",
    err: "#FFA500",
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          backgroundColor: backgroundColor[status],
          width: "20px",
          height: "20px",
          borderRadius: "10px",
        }}>
        {" "}
      </div>
    </div>
  );
};

export default DailyDashboard;
