import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
  name: string;
  setName: any;
  address: string;
  setAddress: any;
  latitude: number;
  setLatitude: any;
  longitude: number;
  setLongitude: any;
  adminName: string;
  setAdminName: any;
  adminPhoneNumber: string;
  setAdminPhoneNumber: any;
  adminPosition: string;
  setAdminPosition: any;
  dynamicScore: number;
  setDynamicScore: any;
  incomeClass: string;
  setIncomeClass: any;
  monday: string;
  setMonday: any;
  tuesday: string;
  setTuesday: any;
  wednesday: string;
  setWednesday: any;
  thursday: string;
  setThursday: any;
  friday: string;
  setFriday: any;
  saturday: string;
  setSaturday: any;
  sunday: string;
  setSunday: any;
  breakTime: string;
  setBreakTime: any;
  holidayWorking: string;
  setHolidayWorking: any;

  siteFieldId: number;
  setSiteFieldId: any;
  fieldList: any;
  setFieldList: any;

  errorMessage: any;
  createSite: () => void;
}

const useSiteCreate = (): hookMember => {
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [longitude, setLongitude] = useState<number>(0);
  const [latitude, setLatitude] = useState<number>(0);
  const [adminName, setAdminName] = useState<string>("");
  const [adminPhoneNumber, setAdminPhoneNumber] = useState<string>("");
  const [adminPosition, setAdminPosition] = useState<string>("");
  const [dynamicScore, setDynamicScore] = useState<number>(0);
  const [incomeClass, setIncomeClass] = useState<string>("");
  const [monday, setMonday] = useState<string>("");
  const [tuesday, setTuesday] = useState<string>("");
  const [wednesday, setWednesday] = useState<string>("");
  const [thursday, setThursday] = useState<string>("");
  const [friday, setFriday] = useState<string>("");
  const [saturday, setSaturday] = useState<string>("");
  const [sunday, setSunday] = useState<string>("");
  const [breakTime, setBreakTime] = useState<string>("");
  const [holidayWorking, setHolidayWorking] = useState<string>("break");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [siteFieldId, setSiteFieldId] = useState<number>(0);
  const [fieldList, setFieldList] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fieldListResponse = await fetch(
          `${process.env.REACT_APP_URL}/site-field/all`
        );
        const fieldListData = await fieldListResponse.json();

        setFieldList(fieldListData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const validateTimeFormat = (time: string): boolean => {
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)-([01]\d|2[0-3]):([0-5]\d)$/;
    return timePattern.test(time);
  };

  const createSite = async () => {
    try {
      if (name === "" || !siteFieldId) {
        setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
        return;
      }

      const days: { [key: string]: string } = {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        breakTime
      };
      const dayNames: { [key: string]: string } = {
        monday: "월요일",
        tuesday: "화요일",
        wednesday: "수요일",
        thursday: "목요일",
        friday: "금요일",
        saturday: "토요일",
        sunday: "일요일",
        breakTime: "점심시간"
      };
      
      for (const day in days) {
        if (days.hasOwnProperty(day) && days[day].trim() === "") {
          setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
          return;
        }
      }

      for (const day in days) {
        if (days.hasOwnProperty(day) && !validateTimeFormat(days[day])) {
          if (days[day].trim() === "") continue; // 빈 공백인 경우 넘어감
          if (day === "breakTime" && days[day] === "none") continue;
          if (day !== "breakTime" && days[day] === "closed") continue;
          setErrorMessage(`잘못된 시간 형식: ${dayNames[day]}`);
          return;
        }
      }

      const response = await fetch(`${process.env.REACT_APP_URL}/site/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          address,
          longitude,
          latitude,
          adminName,
          adminPhoneNumber,
          adminPosition,
          dynamicScore,
          incomeClass,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
          breakTime,
          holidayWorking,
          siteFieldId,
        }),
      });

      if (response.ok) {
        navigate("/siteComponent/site/list");
      } else {
        setErrorMessage("장소 생성에 실패했습니다.");
        console.error("장소 생성에 실패했습니다.");
      }
    } catch (error) {
      setErrorMessage("장소 생성 중 오류 발생");
      console.error("장소 생성 중 오류 발생:", error);
    }
  };

  return {
    name,
    setName,
    address,
    setAddress,
    longitude,
    setLongitude,
    latitude,
    setLatitude,
    adminName,
    setAdminName,
    adminPhoneNumber,
    setAdminPhoneNumber,
    adminPosition,
    setAdminPosition,
    dynamicScore,
    setDynamicScore,
    incomeClass,
    setIncomeClass,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thursday,
    setThursday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    sunday,
    setSunday,
    breakTime,
    setBreakTime,
    holidayWorking,
    setHolidayWorking,
    errorMessage,
    createSite,
    fieldList,
    setFieldList,
    siteFieldId,
    setSiteFieldId,
  };
};

export default useSiteCreate;
