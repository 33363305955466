import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
    siteFieldId: number;
    setSiteFieldId: any;
    middleCategory: string;
    setMiddleCategory: any;
    majorCategory: string;
    setMajorCategory: any;

    updateSiteFieldData: () => void;
}

const useSiteFieldUpdate = (): hookMember => {
    const params = useParams();

    const navigate = useNavigate();
    const [siteFieldId, setSiteFieldId] = useState<number>(0);
    const [middleCategory, setMiddleCategory] = useState<string>("");
    const [majorCategory, setMajorCategory] = useState<string>("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.siteFieldId) {
                    const response = await fetch(`${process.env.REACT_APP_URL}/site-field/one?id=${params.siteFieldId}`);
                    const jsonData = await response.json();

                    const { siteFieldId, middleCategory, majorCategory} = jsonData.data;

                    setSiteFieldId(siteFieldId);
                    setMiddleCategory(middleCategory);
                    setMajorCategory(majorCategory);

                }
            } catch(error) {
                console.log(error);
            }
        }; 
        fetchData();
    }, [params.siteFieldId]);

    const updateSiteFieldData = () => {
        const result = {
            siteFieldId,
            middleCategory,
            majorCategory
        }

        const fetchData = async () => {
            const res = await fetch(`${process.env.REACT_APP_URL}/site-field/update`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    siteFieldId,
                    middleCategory,
                    majorCategory
                }),
            });

            const result = res.json();
            return result;
        }
        fetchData().then((res) => {
            if (res.status == 200) {
                navigate('/siteComponent/siteField/siteFieldList')
            }
        });
    }
    

    return {
        siteFieldId,
        setSiteFieldId,
        middleCategory,
        setMiddleCategory,
        majorCategory,
        setMajorCategory,
        updateSiteFieldData,
    }
}

export default useSiteFieldUpdate;