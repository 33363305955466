import useAdvertiserCreate from "./hooks/useAdvertiserCreate";

const AdvertiserCreate = () => {
  const hookMember = useAdvertiserCreate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>회사명</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.company}
            onChange={(e) => hookMember.setCompany(e.target.value)}
          />
        </div>
        <div>
          <label>
            담당자명 <span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.adminName}
            onChange={(e) => hookMember.setAdminName(e.target.value)}
          />
        </div>
        <div>
          <label>담당자 연락처</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.adminPhone}
            onChange={(e) => hookMember.setAdminPhone(e.target.value)}
          />
        </div>
        <div>
          <label>담당자 직위</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.adminPosition}
            onChange={(e) => hookMember.setAdminPosition(e.target.value)}
          />
        </div>
        <div>
          <label>남은 요금</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.remainMoney}
            onChange={(e) => hookMember.setRemainMoney(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.createAdvertiser}>저장</button>
      </div>
    </div>
  );
};

export default AdvertiserCreate;
