import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  adVideoInfoId: number;
  setAdVideoInfoId: any;
  frameWidth: number;
  setFrameWidth: any;
  frameHeight: number;
  setFrameHeight: any;
  videoFormat: string;
  setVideoFormat: any;
  ratio: string;
  setRatio: any;
  path: string;
  setPath: any;
  category: string;
  setCategory: any;
  focusColor: string;
  setFocusColor: any;
  keyword: string;
  setKeyword: any;
  vibe: string;
  setVibe: any;
  adId: number;
  setAdId: any;
  adList: any;
  setAdList: any;
  volume: string;
  setVolume: any;
  videoFrameCount: number;
  setVideoFrameCount: any;
  videoPlayTime: number;
  setVideoPlayTime: any;
  changedCount: number;
  setChangedCount: any;

  errorMessage: any;

  updateAdVideoInfoData: () => void;
}

const useAdVideoInfoUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [adVideoInfoId, setAdVideoInfoId] = useState<number>(0);
  const [frameWidth, setFrameWidth] = useState<number>(0);
  const [frameHeight, setFrameHeight] = useState<number>(0);
  const [videoFormat, setVideoFormat] = useState<string>("");
  const [ratio, setRatio] = useState<string>("");
  const [path, setPath] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [focusColor, setFocusColor] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");
  const [vibe, setVibe] = useState<string>("");
  const [adId, setAdId] = useState<number>(0);
  const [adList, setAdList] = useState([]);
  const [volume, setVolume] = useState<string>("");
  const [videoFrameCount, setVideoFrameCount] = useState<number>(0);
  const [videoPlayTime, setVideoPlayTime] = useState<number>(0);
  const [changedCount, setChangedCount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.adVideoInfoId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/ad-video-info/one?id=${params.adVideoInfoId}`
          );
          const jsonData = await response.json();
          const adListResponse = await fetch(
            `${process.env.REACT_APP_URL}/ad/all`
          );
          const adListData = await adListResponse.json();

          setAdList(adListData);

          const {
            adVideoInfoId,
            frameWidth,
            frameHeight,
            videoFormat,
            ratio,
            path,
            category,
            focusColor,
            keyword,
            vibe,
            adId,
            volume,
            videoFrameCount,
            videoPlayTime,
            changedCount
          } = jsonData.data;

          setAdVideoInfoId(adVideoInfoId);
          setFrameWidth(frameWidth);
          setFrameHeight(frameHeight);
          setVideoFormat(videoFormat);
          setRatio(ratio);
          setPath(path);
          setCategory(category);
          setFocusColor(focusColor);
          setKeyword(keyword);
          setVibe(vibe);
          setAdId(adId.adId);
          setVolume(volume);
          setVideoFrameCount(videoFrameCount);
          setVideoPlayTime(videoPlayTime);
          setChangedCount(changedCount);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.adVideoInfoId]);

  const updateAdVideoInfoData = () => {
    const result = {
      adVideoInfoId,
      frameWidth,
      frameHeight,
      videoFormat,
      ratio,
      path,
      category,
      focusColor,
      keyword,
      vibe,
      adId,
      volume,
      videoFrameCount,
      videoPlayTime,
      changedCount
    };

    if (!adId) {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/ad-video-info/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            adVideoInfoId,
            frameWidth,
            frameHeight,
            videoFormat,
            ratio,
            path,
            category,
            focusColor,
            keyword,
            vibe,
            adId,
            volume,
            videoFrameCount,
            videoPlayTime,
            changedCount
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/adComponent/AdVideoInfo/AdVideoInfoList");
      }
    });
  };

  return {
    adVideoInfoId,
    setAdVideoInfoId,
    frameWidth,
    setFrameWidth,
    frameHeight,
    setFrameHeight,
    videoFormat,
    setVideoFormat,
    ratio,
    setRatio,
    path,
    setPath,
    category,
    setCategory,
    focusColor,
    setFocusColor,
    keyword,
    setKeyword,
    vibe,
    setVibe,
    adId,
    setAdId,
    adList,
    setAdList,
    volume,
    setVolume,
    videoFrameCount,
    setVideoFrameCount,
    videoPlayTime,
    setVideoPlayTime,
    changedCount,
    setChangedCount,
    errorMessage,
    updateAdVideoInfoData,
  };
};

export default useAdVideoInfoUpdate;
