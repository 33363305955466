import { useEffect, useState } from "react";

enum HourlyEffectProperties {
  FK_MEDIA_ID = "fk_media_id",
  MEDIA_NAME = "매체 이름",
  UNIQUE_ID = "고유 번호",
  DATE = "날짜",
  PLAYED_COUNT = "played_count",
  EXPOSED_POPULATION = "노출 인구",
  WATCHED_POPULATION = "시청 인구",
  ATTENTION_POPULATION = "주목 인구",
  MALE = "남성",
  FEMALE = "여성",
  TEEN = "0-10대",
  YOUNG = "20-30대",
  MIDDLE = "40-50대",
  OLD = "60대 이상",

  // 남성 - 10대
  MALE_TEEN_EXPOSED_COUNT = "노출 10대 남성",
  MALE_TEEN_WATCHED_COUNT = "시청 10대 남성",
  MALE_TEEN_ATTENTION_COUNT = "주목 10대 남성",
  MALE_TEEN_EXPOSED_TIME = "노출 시간 10대 남성",
  MALE_TEEN_WATCHED_TIME = "시청 시간 10대 남성",
  MALE_TEEN_ATTENTION_TIME = "주목 시간 10대 남성",

  // 남성 - 20-30대
  MALE_YOUNG_EXPOSED_COUNT = "노출 20~30대 남성",
  MALE_YOUNG_WATCHED_COUNT = "시청 20~30대 남성",
  MALE_YOUNG_ATTENTION_COUNT = "주목 20~30대 남성",
  MALE_YOUNG_EXPOSED_TIME = "노출 시간 20~30대 남성",
  MALE_YOUNG_WATCHED_TIME = "시청 시간 20~30대 남성",
  MALE_YOUNG_ATTENTION_TIME = "주목 시간 20~30대 남성",

  // 남성 - 40-50대
  MALE_MIDDLE_EXPOSED_COUNT = "노출 40~50대 남성",
  MALE_MIDDLE_WATCHED_COUNT = "시청 40~50대 남성",
  MALE_MIDDLE_ATTENTION_COUNT = "주목 40~50대 남성",
  MALE_MIDDLE_EXPOSED_TIME = "노출 시간 40~50대 남성",
  MALE_MIDDLE_WATCHED_TIME = "시청 시간 40~50대 남성",
  MALE_MIDDLE_ATTENTION_TIME = "주목 시간 40~50대 남성",

  // 남성 - 60대 이상
  MALE_OLD_EXPOSED_COUNT = "노출 60대 이상 남성",
  MALE_OLD_WATCHED_COUNT = "시청 60대 이상 남성",
  MALE_OLD_ATTENTION_COUNT = "주목 60대 이상 남성",
  MALE_OLD_EXPOSED_TIME = "노출 시간 60대 이상 남성",
  MALE_OLD_WATCHED_TIME = "시청 시간 60대 이상 남성",
  MALE_OLD_ATTENTION_TIME = "주목 시간 60대 이상 남성",

  // 여성 - 10대
  FEMALE_TEEN_EXPOSED_COUNT = "노출 10대 여성",
  FEMALE_TEEN_WATCHED_COUNT = "시청 10대 여성",
  FEMALE_TEEN_ATTENTION_COUNT = "주목 10대 여성",
  FEMALE_TEEN_EXPOSED_TIME = "노출 시간 10대 여성",
  FEMALE_TEEN_WATCHED_TIME = "시청 시간 10대 여성",
  FEMALE_TEEN_ATTENTION_TIME = "주목 시간 10대 여성",

  // 여성 - 20-30대
  FEMALE_YOUNG_EXPOSED_COUNT = "노출 20~30대 여성",
  FEMALE_YOUNG_WATCHED_COUNT = "시청 20~30대 여성",
  FEMALE_YOUNG_ATTENTION_COUNT = "주목 20~30대 여성",
  FEMALE_YOUNG_EXPOSED_TIME = "노출 시간 20~30대 여성",
  FEMALE_YOUNG_WATCHED_TIME = "시청 시간 20~30대 여성",
  FEMALE_YOUNG_ATTENTION_TIME = "주목 시간 20~30대 여성",

  // 여성 - 40-50대
  FEMALE_MIDDLE_EXPOSED_COUNT = "노출 40~50대 여성",
  FEMALE_MIDDLE_WATCHED_COUNT = "시청 40~50대 여성",
  FEMALE_MIDDLE_ATTENTION_COUNT = "주목 40~50대 여성",
  FEMALE_MIDDLE_EXPOSED_TIME = "노출 시간 40~50대 여성",
  FEMALE_MIDDLE_WATCHED_TIME = "시청 시간 40~50대 여성",
  FEMALE_MIDDLE_ATTENTION_TIME = "주목 시간 40~50대 여성",

  // 여성 - 60대 이상
  FEMALE_OLD_EXPOSED_COUNT = "노출 60대 이상 여성",
  FEMALE_OLD_WATCHED_COUNT = "시청 60대 이상 여성",
  FEMALE_OLD_ATTENTION_COUNT = "주목 60대 이상 여성",
  FEMALE_OLD_EXPOSED_TIME = "노출 시간 60대 이상 여성",
  FEMALE_OLD_WATCHED_TIME = "시청 시간 60대 이상 여성",
  FEMALE_OLD_ATTENTION_TIME = "주목 시간 60대 이상 여성",
}

const useHourlyEffectList = (initialPage: number) => {
  const transformData = (
    rawData: any[]
  ): Record<HourlyEffectProperties, any>[] => {
    return rawData.map((item) => ({
      [HourlyEffectProperties.FK_MEDIA_ID]: item.fk_media_id,
      [HourlyEffectProperties.MEDIA_NAME]: item["매체 이름"],
      [HourlyEffectProperties.UNIQUE_ID]: item["고유 번호"],
      [HourlyEffectProperties.DATE]: item.날짜,
      [HourlyEffectProperties.PLAYED_COUNT]: item.played_count,
      [HourlyEffectProperties.EXPOSED_POPULATION]: item["노출 인구"],
      [HourlyEffectProperties.WATCHED_POPULATION]: item["시청 인구"],
      [HourlyEffectProperties.ATTENTION_POPULATION]: item["주목 인구"],
      [HourlyEffectProperties.MALE]: item["남성"],
      [HourlyEffectProperties.FEMALE]: item["여성"],
      [HourlyEffectProperties.TEEN]: item["0-10대"],
      [HourlyEffectProperties.YOUNG]: item["20-30대"],
      [HourlyEffectProperties.MIDDLE]: item["40-50대"],
      [HourlyEffectProperties.OLD]: item["60대 이상"],
      [HourlyEffectProperties.MALE_TEEN_EXPOSED_COUNT]:
        item.male_teen_exposed_count,
      [HourlyEffectProperties.MALE_TEEN_WATCHED_COUNT]:
        item.male_teen_watched_count,
      [HourlyEffectProperties.MALE_TEEN_ATTENTION_COUNT]:
        item.male_teen_attention_count,
      [HourlyEffectProperties.MALE_YOUNG_EXPOSED_COUNT]:
        item.male_young_exposed_count,
      [HourlyEffectProperties.MALE_YOUNG_WATCHED_COUNT]:
        item.male_young_watched_count,
      [HourlyEffectProperties.MALE_YOUNG_ATTENTION_COUNT]:
        item.male_young_attention_count,
      [HourlyEffectProperties.MALE_MIDDLE_EXPOSED_COUNT]:
        item.male_middle_exposed_count,
      [HourlyEffectProperties.MALE_MIDDLE_WATCHED_COUNT]:
        item.male_middle_watched_count,
      [HourlyEffectProperties.MALE_MIDDLE_ATTENTION_COUNT]:
        item.male_middle_attention_count,
      [HourlyEffectProperties.MALE_OLD_EXPOSED_COUNT]:
        item.male_old_exposed_count,
      [HourlyEffectProperties.MALE_OLD_WATCHED_COUNT]:
        item.male_old_watched_count,
      [HourlyEffectProperties.MALE_OLD_ATTENTION_COUNT]:
        item.male_old_attention_count,
      [HourlyEffectProperties.FEMALE_TEEN_EXPOSED_COUNT]:
        item.female_teen_exposed_count,
      [HourlyEffectProperties.FEMALE_TEEN_WATCHED_COUNT]:
        item.female_teen_watched_count,
      [HourlyEffectProperties.FEMALE_TEEN_ATTENTION_COUNT]:
        item.female_teen_attention_count,
      [HourlyEffectProperties.FEMALE_YOUNG_EXPOSED_COUNT]:
        item.female_young_exposed_count,
      [HourlyEffectProperties.FEMALE_YOUNG_WATCHED_COUNT]:
        item.female_young_watched_count,
      [HourlyEffectProperties.FEMALE_YOUNG_ATTENTION_COUNT]:
        item.female_young_attention_count,
      [HourlyEffectProperties.FEMALE_MIDDLE_EXPOSED_COUNT]:
        item.female_middle_exposed_count,
      [HourlyEffectProperties.FEMALE_MIDDLE_WATCHED_COUNT]:
        item.female_middle_watched_count,
      [HourlyEffectProperties.FEMALE_MIDDLE_ATTENTION_COUNT]:
        item.female_middle_attention_count,
      [HourlyEffectProperties.FEMALE_OLD_EXPOSED_COUNT]:
        item.female_old_exposed_count,
      [HourlyEffectProperties.FEMALE_OLD_WATCHED_COUNT]:
        item.female_old_watched_count,
      [HourlyEffectProperties.FEMALE_OLD_ATTENTION_COUNT]:
        item.female_old_attention_count,
      [HourlyEffectProperties.MALE_TEEN_EXPOSED_TIME]:
        item.male_teen_exposed_time,
      [HourlyEffectProperties.MALE_TEEN_WATCHED_TIME]:
        item.male_teen_watched_time,
      [HourlyEffectProperties.MALE_TEEN_ATTENTION_TIME]:
        item.male_teen_attention_time,
      [HourlyEffectProperties.MALE_YOUNG_EXPOSED_TIME]:
        item.male_young_exposed_time,
      [HourlyEffectProperties.MALE_YOUNG_WATCHED_TIME]:
        item.male_young_watched_time,
      [HourlyEffectProperties.MALE_YOUNG_ATTENTION_TIME]:
        item.male_young_attention_time,
      [HourlyEffectProperties.MALE_MIDDLE_EXPOSED_TIME]:
        item.male_middle_exposed_time,
      [HourlyEffectProperties.MALE_MIDDLE_WATCHED_TIME]:
        item.male_middle_watched_time,
      [HourlyEffectProperties.MALE_MIDDLE_ATTENTION_TIME]:
        item.male_middle_attention_time,
      [HourlyEffectProperties.MALE_OLD_EXPOSED_TIME]:
        item.male_old_exposed_time,
      [HourlyEffectProperties.MALE_OLD_WATCHED_TIME]:
        item.male_old_watched_time,
      [HourlyEffectProperties.MALE_OLD_ATTENTION_TIME]:
        item.male_old_attention_time,
      [HourlyEffectProperties.FEMALE_TEEN_EXPOSED_TIME]:
        item.female_teen_exposed_time,
      [HourlyEffectProperties.FEMALE_TEEN_WATCHED_TIME]:
        item.female_teen_watched_time,
      [HourlyEffectProperties.FEMALE_TEEN_ATTENTION_TIME]:
        item.female_teen_attention_time,
      [HourlyEffectProperties.FEMALE_YOUNG_EXPOSED_TIME]:
        item.female_young_exposed_time,
      [HourlyEffectProperties.FEMALE_YOUNG_WATCHED_TIME]:
        item.female_young_watched_time,
      [HourlyEffectProperties.FEMALE_YOUNG_ATTENTION_TIME]:
        item.female_young_attention_time,
      [HourlyEffectProperties.FEMALE_MIDDLE_EXPOSED_TIME]:
        item.female_middle_exposed_time,
      [HourlyEffectProperties.FEMALE_MIDDLE_WATCHED_TIME]:
        item.female_middle_watched_time,
      [HourlyEffectProperties.FEMALE_MIDDLE_ATTENTION_TIME]:
        item.female_middle_attention_time,
      [HourlyEffectProperties.FEMALE_OLD_EXPOSED_TIME]:
        item.female_old_exposed_time,
      [HourlyEffectProperties.FEMALE_OLD_WATCHED_TIME]:
        item.female_old_watched_time,
      [HourlyEffectProperties.FEMALE_OLD_ATTENTION_TIME]:
        item.female_old_attention_time,
    }));
  };

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/hourly-effect/get-view?page=${page}`
      );
      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
  };
};

export default useHourlyEffectList;
