import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  networkDeviceId: number;
  setNetworkDeviceId: any;
  label: string;
  setLabel: any;
  networkDeviceTypeId: number;
  setNetworkDeviceTypeId: any;
  networkDeviceTypeList: any;
  setNetworkDeviceTypeList: any;
  aiDeviceId: number;
  setAIDeviceId: any;
  aiDeviceList: any;
  setAIDeviceList: any;
  currentState: string;
  setCurrentState: any;

  errorMessage: any;

  updateNetworkDeviceData: () => void;
}

const useNetworkDeviceUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [networkDeviceId, setNetworkDeviceId] = useState<number>(0);
  const [label, setLabel] = useState<string>("");
  const [networkDeviceTypeId, setNetworkDeviceTypeId] = useState<number>(0);
  const [aiDeviceId, setAIDeviceId] = useState<number>(0);
  const [currentState, setCurrentState] = useState<string>("");
  const [aiDeviceList, setAIDeviceList] = useState("");
  const [networkDeviceTypeList, setNetworkDeviceTypeList] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.networkDeviceId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/network-device/one?id=${params.networkDeviceId}`
          );
          const jsonData = await response.json();

          const aiDeviceListResponse = await fetch(
            `${process.env.REACT_APP_URL}/ai-device/all`
          );
          const aiDeviceListData = await aiDeviceListResponse.json();

          const networkDeviceTypeListResponse = await fetch(
            `${process.env.REACT_APP_URL}/network-device-type/all`
          );
          const networkDeviceTypeListData =
            await networkDeviceTypeListResponse.json();

          const {
            networkDeviceId,
            label,
            networkDeviceTypeId,
            aiDeviceId,
            currentState,
          } = jsonData.data;

          setNetworkDeviceId(networkDeviceId);
          setLabel(label);
          setNetworkDeviceTypeId(networkDeviceTypeId);
          setAIDeviceId(aiDeviceId);
          setCurrentState(currentState);
          setAIDeviceList(aiDeviceListData);
          setNetworkDeviceTypeList(networkDeviceTypeListData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.networkDeviceId]);

  const updateNetworkDeviceData = () => {
    const result = {
      networkDeviceId,
      label,
      aiDeviceId,
      currentState,
      networkDeviceTypeId,
    };

    if (label == "" || !aiDeviceId || !networkDeviceTypeId) {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/network-device/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            networkDeviceId,
            label,
            aiDeviceId,
            currentState,
            networkDeviceTypeId,
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/networkComponent/networkDevice/networkDeviceList");
      } else {
        if(res.statusCode == 500){
            setErrorMessage('기기 종류 또는 AI 기기가 중복된 네트워크 기기가 존재합니다.');
        }
    }
    });
  };

  return {
    networkDeviceId,
    setNetworkDeviceId,
    label,
    setLabel,
    networkDeviceTypeId,
    setNetworkDeviceTypeId,
    aiDeviceId,
    setAIDeviceId,
    currentState,
    setCurrentState,
    errorMessage,
    updateNetworkDeviceData,
    aiDeviceList,
    setAIDeviceList,
    networkDeviceTypeList,
    setNetworkDeviceTypeList,
  };
};

export default useNetworkDeviceUpdate;