import { useEffect, useRef, useState } from "react";
import axios from "axios";
import useHourlyEffectList from "./hooks/useHourlyEffectList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import FilterDropdown from "../../../components/atoms/filterDropdown/FilterDropdown";
import { DynamicTable } from "../../../components/table/dynamicTable";

const HourlyEffectList = () => {
  const {
    data,
    loading,
    currentPage,
    nextPage,
    tableData,
    setTableData,
    columns,
  } = useHourlyEffectList(0);

  const [isFetching, setIsFetching] = useState(false);
  const [sortBy, setSortBy] = useState("hourly_effect_id");
  const [sortDirection, setSortDirection] = useState("asc");
  const observer = useRef<IntersectionObserver | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  

  useEffect(() => {
    if (loading) return;

    const tableElement = document.getElementById("table");
    const scrollTriggerDivElement =
      document.getElementById("scroll-trigger-div");

    if (tableElement && scrollTriggerDivElement) {
      scrollTriggerDivElement.style.width = tableElement.clientWidth + "px";
    }

    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting) {
        loadMoreData();
      }
    };

    observer.current = new IntersectionObserver(handleObserver, {
      threshold: 0.2,
    });
    const scrollTriggerElement = document.getElementById("scroll-trigger");
    if (scrollTriggerElement) {
      observer.current.observe(scrollTriggerElement);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, currentPage, isFetching]);

  const loadMoreData = async () => {
    if (!isFetching) {
      setIsFetching(true);
      await nextPage();
      setIsFetching(false);
    }
  };

  

  const downloadExcel = async () => {
    alert("다운로드가 진행 중입니다.");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/hourly-effect/download-excel?tableName=hourly_effect_view&id=hourly_effect_id&startDate=${startDate}&endDate=${endDate}`,
        {},
        {
          responseType: "blob",
        }
      );
      // 파일 다운로드를 위한 Blob 생성
      const blob = new Blob([response.data], { type: "text/xlsx" });
      const url = window.URL.createObjectURL(blob);

      // 다운로드 링크 생성 및 클릭
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "시간_데이터_테이블.xlsx");
      document.body.appendChild(link);
      link.click();

      // 생성된 URL 해제
      window.URL.revokeObjectURL(url);
    } catch (error) {
      alert("날짜를 모두 선택해 주세요.");
    }
  };
  
  return (
    <div>
      {loading ? (
        <p>Loading ...</p>
      ) : (
        <div className="container">
          <div>
              <label>시작일</label>
              <DatePicker
                locale={ko}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat={"yyyy-MM-dd"}
              />
            </div>
            <div>
              <label>종료일</label>
              <DatePicker
                locale={ko}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat={"yyyy-MM-dd"}
              />
            </div>
          <>
            <button className="excelButton" onClick={downloadExcel}>
              엑셀 다운로드
            </button>
            <div className="header">
              <h1>일간 데이터</h1>
              <FilterDropdown
                columns={columns}
                searchTerm={searchTerm}
                tableData={tableData}
                allData={data}
                setTableData={setTableData}
              />
            </div>
            <div className="table-container">
              <DynamicTable data={tableData} />
              <div id="scroll-trigger-div">
                <div id="scroll-trigger" />
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default HourlyEffectList;
