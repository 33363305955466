import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
  modelName: string;
  setModelName: any;
  company: string;
  setCompany: any;
  price: number;
  setPrice: any;
  labelPrefix: string;
  setLabelPrefix: any;

  aiModuleId: number;
  setAIModuleId: any;
  aiModuleList: any;
  setAIModuleList: any;

  aiDeviceCompanyId: number;
  setAIDeviceCompanyId: any;
  aiDeviceCompanyList: any;
  setAIDeviceCompanyList: any;

  errorMessage: any;
  createAIDeviceType: () => void;
}

const useAIDeviceTypeCreate = (): hookMember => {
  const navigate = useNavigate();

  const [modelName, setModelName] = useState<string>("");
  const [company, setCompany] = useState<string>("Advantech");
  const [price, setPrice] = useState<number>(0);
  const [labelPrefix, setLabelPrefix] = useState<string>("");

  const [aiModuleId, setAIModuleId] = useState<number>(0);
  const [aiModuleList, setAIModuleList] = useState([]);

  const [aiDeviceCompanyId, setAIDeviceCompanyId] = useState<number>(0);
  const [aiDeviceCompanyList, setAIDeviceCompanyList] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const aiModuleListResponse = await fetch(
          `${process.env.REACT_APP_URL}/ai-module/all`
        );
        const aiModuleListData = await aiModuleListResponse.json();

        setAIModuleList(aiModuleListData);

        const aiDeviceCompanyListReponse = await fetch(
            `${process.env.REACT_APP_URL}/ai-device-company/all`
          );
          const aiDeviceCompanyListData = await aiDeviceCompanyListReponse.json();
  
          setAIDeviceCompanyList(aiDeviceCompanyListData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

  }, []);

  const createAIDeviceType = async () => {
    try {
      if (modelName === "" || !aiModuleId || !aiDeviceCompanyId) {
        setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/ai-device-type/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              modelName,
              company,
              price,
              labelPrefix,
              aiModuleId,
              aiDeviceCompanyId
            }),
          }
        );
        if (response.ok) {
          navigate("/aiComponent/aiDeviceType/aiDeviceTypeList");
        } else {
          console.error("AI 기기 생성에 실패했습니다.");
        }
      }
    } catch (error) {
      console.error("AI 기기 생성 중 오류 발생:", error);
    }
  };

  return {
    modelName,
    setModelName,
    company,
    setCompany,
    price,
    setPrice,
    labelPrefix,
    setLabelPrefix,
    errorMessage,
    createAIDeviceType,
    aiModuleId,
    setAIModuleId,
    aiModuleList,
    setAIModuleList,
    aiDeviceCompanyId,
    setAIDeviceCompanyId,
    aiDeviceCompanyList,
    setAIDeviceCompanyList
  };
};

export default useAIDeviceTypeCreate;
