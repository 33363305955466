import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  installReportId: number;
  setInstallReportId: any;
  mediaList: any;
  setMediaList: any;
  mediaId: number;
  setMediaId: any;
  mediaName: string;
  setMediaName: any;
  aiDeviceList: any;
  setAiDeviceList: any;
  aiDeviceLabel: string;
  setAiDeviceLabel: any;
  aiDeviceId: number;
  setAiDeviceId: any;
  networkDeviceId: number;
  setNetworkDeviceId: any;
  networkDeviceList: any;
  setNetworkDeviceList: any;
  networkDeviceLabel: string;
  setNetworkDeviceLabel: any;
  cameraId: number;
  setCameraId: any;
  cameraList: any;
  setCameraList: any;
  cameraLabel: string;
  setCameraLabel: any;
  comment: string;
  setComment: any;
  images: File[];
  setImages: any;
  videos: File[];
  setVideos: any;
  workDate: Date;
  setWorkDate: any;

  errorMessage: any;

  updateInspectionData: () => void;
}

const useInspectionUpdate = (): hookMember => {
  const params = useParams();
  const navigate = useNavigate();

  const [installReportId, setInstallReportId] = useState<number>(0);
  const [mediaList, setMediaList] = useState([]);
  const [mediaId, setMediaId] = useState<number>(0);
  const [mediaName, setMediaName] = useState<string>("");
  const [aiDeviceList, setAiDeviceList] = useState([]);
  const [aiDeviceId, setAiDeviceId] = useState<number>(0);
  const [aiDeviceLabel, setAiDeviceLabel] = useState<string>("");
  const [networkDeviceId, setNetworkDeviceId] = useState<number>(0);
  const [networkDeviceList, setNetworkDeviceList] = useState([]);
  const [networkDeviceLabel, setNetworkDeviceLabel] = useState<string>("");
  const [cameraId, setCameraId] = useState<number>(0);
  const [cameraList, setCameraList] = useState([]);
  const [cameraLabel, setCameraLabel] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [images, setImages] = useState<File[]>([]);
  const [videos, setVideos] = useState<File[]>([]);
  const [workDate, setWorkDate] = useState<Date>(new Date());

  const [errorMessage, setErrorMessage] = useState("");

  const BASE_URL = process.env.REACT_APP_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.installReportId) {
          const response = await fetch(
            `${BASE_URL}/install-report/update-one?id=${params.installReportId}`
          );
          if (!response.ok) {
            throw new Error('Failed to fetch install report data');
          }
          const jsonData = await response.json();
          const mediaListResponse = await fetch(`${BASE_URL}/media/all`);
          if (!mediaListResponse.ok) {
            throw new Error('Failed to fetch media list');
          }
          const mediaListData = await mediaListResponse.json();

          setMediaList(mediaListData);

          const aiDeviceListResponse = await fetch(
            `${BASE_URL}/ai-device/selectable`
          );
          if (!aiDeviceListResponse.ok) {
            throw new Error('Failed to fetch AI device list');
          }
          const aiDeviceListData = await aiDeviceListResponse.json();
  
          setAiDeviceList(aiDeviceListData);
          
          const networkDeviceListResponse = await fetch(
            `${process.env.REACT_APP_URL}/network-device/all`
          );
          const networkDeviceListData = await networkDeviceListResponse.json();
  
          setNetworkDeviceList(networkDeviceListData);
  
          const cameraListResponse = await fetch(
            `${process.env.REACT_APP_URL}/camera/all`
          );
          const cameraListData = await cameraListResponse.json();
  
          setCameraList(cameraListData);

          const {
            installReportId,
            mediaId,
            comment,
            aiDeviceId,
            aiDevice,
            media,
            networkDeviceId,
            cameraId,
            workDate,
          } = jsonData.data;

          setInstallReportId(installReportId);
          setMediaId(mediaId);
          setComment(comment);
          setAiDeviceId(aiDeviceId);
          setAiDeviceLabel(aiDevice.label);
          setMediaName(media.name);
          setNetworkDeviceId(networkDeviceId);
          setCameraId(cameraId);
          setNetworkDeviceLabel(jsonData.data.networkDevice.label);
          setCameraLabel(jsonData.data.camera.label);
          setWorkDate(workDate);
        }
      } catch (error) {
        // setErrorMessage(error.message);
      }
    };
    fetchData();
  }, [params.installReportId]);

  const uploadFiles = async (files: File[], type: string) => {
    const formData = new FormData();
    formData.append("mediaId", mediaId.toString());
    formData.append("category", "inspection");
    formData.append("type", type);
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    const response = await fetch(
      `${BASE_URL}/install-report/upload-files`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to upload ${type}`);
    }
  };

  const updateInspectionData = async () => {
    const result = {
      installReportId,
      mediaId,
      comment,
      aiDeviceId,
      networkDeviceId,
      cameraId,
      workDate,
    };

    if (!mediaId) {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
    } else {
      const commonBody = {
        installReportId,
        mediaId,
        category: "inspection",
        comment,
        aiDeviceId,
        networkDeviceId,
        cameraId,
        workDate,
      };

      try {
        const response = await fetch(
          `${BASE_URL}/install-report/update-inspection`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(commonBody),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to update inspection data');
        }

        await uploadFiles(images, 'images');
        await uploadFiles(videos, 'videos');

        navigate("/installReport/installReportList");
      } catch (error) {
        // setErrorMessage(error.message);
      }
    }
  };

  return {
    installReportId,
    setInstallReportId,
    mediaList,
    setMediaList,
    mediaId,
    setMediaId,
    mediaName,
    setMediaName,
    aiDeviceList,
    setAiDeviceList,
    aiDeviceId,
    setAiDeviceId,
    aiDeviceLabel,
    setAiDeviceLabel,
    networkDeviceId,
    setNetworkDeviceId,
    networkDeviceList,
    setNetworkDeviceList,
    cameraId,
    setCameraId,
    cameraList,
    setCameraList,
    networkDeviceLabel,
    setNetworkDeviceLabel,
    cameraLabel,
    setCameraLabel,
    comment,
    setComment,
    updateInspectionData,
    images,
    setImages,
    videos,
    setVideos,
    workDate,
    setWorkDate,
    errorMessage,
  };
};

export default useInspectionUpdate;
