import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    business: string;
    setBusiness: any;
    exposedStandard: number;
    setExposedStandard: any;
    watchedStandard: number;
    setWatchedStandard: any;
    attentionedStandard: number;
    setAttentionedStandard: any;

    errorMessage: any;

    createBusinessType: () => void;
}

const useBusinessTypeCreate = (): hookMember => {
    const navigate = useNavigate();

    const [business, setBusiness] = useState<string>("");
    const [exposedStandard, setExposedStandard] = useState<number>(0);
    const [watchedStandard, setWatchedStandard] = useState<number>(0);
    const [attentionedStandard, setAttentionedStandard] = useState<number>(0);

    const [errorMessage, setErrorMessage] = useState('');

    const createBusinessType = async () => {
        try {
            if (!business) {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.')
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/business-type/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        business,
                        exposedStandard,
                        watchedStandard,
                        attentionedStandard
                    })
                })
                if(response.ok) {
                    navigate('/businessType/businessTypeList');
                }
            }
        } catch (error) {
            console.error('비즈니스 타입 생성 중 오류 발생:', error);
        }
    }
    return {
        business,
        setBusiness,
        exposedStandard,
        setExposedStandard,
        watchedStandard,
        setWatchedStandard,
        attentionedStandard,
        setAttentionedStandard,
        createBusinessType,
        errorMessage
    }
}

export default useBusinessTypeCreate;