import { useEffect, useState } from "react";

enum AdProperties {
  VIDEO_NAME = "광고명",
  BRAND = "브랜드",
  PRICE = "가격",
  REMAIN_MONEY = "남은 요금",
  SHOW_TYPE = "송출 종류",
  PURPOSE_TYPE = "목적 분류",
  GEOGRAPHICAL_SCOPE_TYPE = "지역적 범위 종류",
  CUSTOMER_TYPE = "소비자 종류",
  TARGET_GENDER = "대상 성별",
  TARGET_AGE = "대상 연령",
  TARGET_SEASON = "대상 계절",
  TARGET_INCOME = "대상 소득층",
  TARGET_TIME = "대상 시간대",
  TARGET_PLACE = "대상 위치",
  TARGET_AREA = "대상 지역",
  CORP_FIELD_ID = "산업 분류",
  TARGET_CORP_FIELD = "대상 산업 분류",
  REPEAT_TIME = "반복 횟수",
  ADVERTISER_ID = "광고주",
  AD_FIELD_ID = "광고 분류",
  STATUS = "광고 상태",
  PLAY_START_DATE = "시작일",
  PLAY_END_DATE = "종료일",
  IS_FREE = "무료광고 구분	",
  CREATED_AT = "생성일",
  TYPE = "광고 종류",
}

const useAdList = (initialPage: number) => {
  const transformData = (rawData: any[]): Record<AdProperties, any>[] => {
    return rawData.map((item) => ({
      [AdProperties.VIDEO_NAME]: item.videoName,
      [AdProperties.BRAND]: item.brand,
      [AdProperties.PRICE]: item.price,
      [AdProperties.REMAIN_MONEY]: item.remainMoney,
      [AdProperties.SHOW_TYPE]: item.showType,
      [AdProperties.PURPOSE_TYPE]: item.purposeType,
      [AdProperties.GEOGRAPHICAL_SCOPE_TYPE]: item.geographicalScopeType,
      [AdProperties.CUSTOMER_TYPE]: item.customerType,
      [AdProperties.TARGET_GENDER]: item.targetGender,
      [AdProperties.TARGET_AGE]: item.targetAge,
      [AdProperties.TARGET_SEASON]: item.targetSeason,
      [AdProperties.TARGET_INCOME]: item.targetIncome,
      [AdProperties.TARGET_TIME]: item.targetTime,
      [AdProperties.TARGET_PLACE]: item.targetPlace,
      [AdProperties.TARGET_AREA]: item.targetArea,
      [AdProperties.CORP_FIELD_ID]: item.corpFieldId,
      [AdProperties.TARGET_CORP_FIELD]: item.targetCorpField,
      [AdProperties.REPEAT_TIME]: item.repeatTime,
      [AdProperties.ADVERTISER_ID]: item.advertiserId,
      [AdProperties.AD_FIELD_ID]: item.adFieldId,
      [AdProperties.STATUS]: item.status,
      [AdProperties.PLAY_START_DATE]: item.playStartDate,
      [AdProperties.PLAY_END_DATE]: item.playEndDate,
      [AdProperties.IS_FREE]: item.isFree,
      [AdProperties.CREATED_AT]: item.createdAt,
      [AdProperties.TYPE]: item.type,
    }));
  };

  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (adId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/ad/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ adId: adId }),
      })
        .then((response) => {
          if (response.ok) {
            window.location.reload();
          } else {
            alert("삭제에 실패하였습니다.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("삭제에 실패하였습니다.");
        });
    }
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/ad/list?page=${page}`
      );

      const jsonData = await response.json();

      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      const countResponse = await fetch(`${process.env.REACT_APP_URL}/ad/list`);

      if (!countResponse.ok) {
        throw new Error("Network request failed");
      }

      const countJsonData = await countResponse.json();

      const totalDataCount = countJsonData.data.count;
      setCount(totalDataCount);
      // 모든 데이터를 가져온 경우 fetch 종료
      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
    handleDeleteClick,
    isFetchingComplete,
  };
};

export default useAdList;
