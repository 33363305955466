import useWeeklyDashboard from "./hooks/useWeeklyDashboard";
import "./css/weeklyDashboard.scss";

const WeeklyDashboard: any = () => {
  const hookMember = useWeeklyDashboard();

  return (
    <div id="weekly-table">
      <h1>주간 데이터 관제</h1>
      {!hookMember.loading ? (
        <div>
          <div>
            <button
              onClick={() => {
                hookMember.changeWeek(1);
              }}>
              {"<"}
            </button>
            <div>
              {hookMember.date[0]} ~ {hookMember.date[6]}
            </div>
            {hookMember.page === 0 ? (
              <></>
            ) : (
              <button
                onClick={() => {
                  hookMember.changeWeek(-1);
                }}>
                {">"}
              </button>
            )}
          </div>
          <table>
            <thead>
              <tr>
                <th>매체명</th>
                <th>매체번호</th>
                {hookMember.date.map((item, index) => {
                  return (
                    <th colSpan={3} key={index}>
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {hookMember.medias.length === 0 ? (
                <tr
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <td>해당 매체가 없습니다.</td>
                </tr>
              ) : (
                hookMember.medias.map((item: any, index: number) => {
                  return (
                    <>
                      <tr key={index}>
                        <th rowSpan={2}>{item.name}</th>
                        <th rowSpan={2}>{item.authNumber}</th>
                        {item.data.map((item2: any, index2: number) => {
                          return (
                            <>
                              <td key={index2}>APP</td>
                              <td>DATA</td>
                              <td>NETWORK</td>
                            </>
                          );
                        })}
                      </tr>
                      <tr className="padding0">
                        {item.data.map((item2: any, index2: number) => {
                          return (
                            <>
                              <td key={index2}>{colorDiv(item2[0])}</td>
                              <td>{colorDiv(item2[1])}</td>
                              <td>{colorDiv(item2[2])}</td>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <p>Loading ...</p>
        </div>
      )}
    </div>
  );
};

const colorDiv: any = (status: "O" | "X" | "err") => {
  // const backgroundColor = status === "O" ? "#4ADE80" : "#EF4444";
  let backgroundColor = {
    O: "#4ADE80",
    X: "#EF4444",
    err: "#FFA500",
  };
  return (
    // <div style={{ display: "flex", justifyContent: "center" }}>
    <div
      style={{
        backgroundColor: backgroundColor[status],
        width: "100%",
        height: "15px",
        // borderRadius: "10px",
      }}></div>
    // </div>
  );
};

export default WeeklyDashboard;
