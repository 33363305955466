import useAIDeviceUpdate from "./hooks/useAIDeviceUpdate";

const AIDeviceUpdate = () => {
  const hookMember = useAIDeviceUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <>
      {
        <>
          <div>
            <div className="input-table">
              {hookMember.errorMessage && (
                <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
              )}
              <div>
                <label>AI 기기 ID</label>
                <div>{hookMember.aiDeviceId}</div>
              </div>
              <div>
                <label>
                  라벨 <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={hookMember.label}
                  onChange={(e) => hookMember.setLabel(e.target.value)}
                />
              </div>
              <div>
                <label>시리얼 넘버</label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={hookMember.serialNumber}
                  onChange={(e) => hookMember.setSerialNumber(e.target.value)}
                />
              </div>
              <div>
                <label>현재 상태</label>
                <select
                  name=""
                  id=""
                  value={hookMember.currentStatus}
                  onChange={(e) => hookMember.setCurrentStatus(e.target.value)}
                >
                  <option value="설치">설치</option>
                  <option value="기기 고장">기기 고장</option>
                  <option value="수리">수리</option>
                  <option value="테스트">테스트</option>
                  <option value="개발용">개발용</option>
                  <option value="설치 대기">설치 대기</option>
                  <option value="사용 안함">사용 안함</option>
                </select>
              </div>
              <div>
                <label>
                  AI 기기 모델 타입 <span className="required">*</span>
                </label>
                <select
                  name=""
                  id=""
                  value={hookMember.aiDeviceTypeId}
                  onChange={(e) => {
                    hookMember.setAIDeviceTypeId(parseInt(e.target.value));
                  }}
                >
                  {Array.isArray(hookMember.aiDeviceTypeList.data) &&
                    hookMember.aiDeviceTypeList.data.map(
                      (item: { aiDeviceTypeId: number; modelName: string }) => (
                        <option
                          key={item.aiDeviceTypeId}
                          value={item.aiDeviceTypeId}
                        >
                          {item.modelName}
                        </option>
                      )
                    )}
                </select>
              </div>
              <div>
          <label>
            네트워크 기기 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.networkDeviceId}
            onChange={(e) => {
              hookMember.setNetworkDeviceId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.networkDeviceList.data) &&
              hookMember.networkDeviceList.data.map(
                (item: { networkDeviceId: number; label: string }) => (
                  <option key={item.networkDeviceId} value={item.networkDeviceId}>
                    {item.label}
                  </option>
                )
              )}
          </select>
        </div>
            </div>
          </div>
          <div className="buttonDiv">
            <button onClick={handleCancel}>취소</button>  
            <button onClick={hookMember.updateAIDeviceData}>저장</button>
          </div>
        </>
      }
    </>
  );
};

export default AIDeviceUpdate;
