import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

interface hookMember {
  mediaId: number;
  service: string;
  name: string;

  serialNumber: string;
  setSerialNumber: any;
  attractiveness: number;
  setAttractiveness: any;
  performance: number;
  setPerformance: any;
  scheduleUnit: number;
  setScheduleUnit: any;
  installMethod: string;
  setInstallMethod: any;
  status: string;
  setStatus: any;
  luminance: number;
  setLuminance: any;
  viewingAngle: number;
  setViewingAngle: any;
  errorMessage: any;

  isIndoor: boolean;
  setIsIndoor: any;

  mediaBusinessTypeId: number;
  setMediaBusinessTypeId: any;
  mediaBusinessTypeList: any;
  setMediaBusinessTypeList: any;

  siteId: number;
  setSiteId: any;
  siteList: any;
  setSiteList: any;

  aiDeviceId: number;
  setAIDeviceId: any;
  aiDeviceList: any;
  setAIDeviceList: any;

  mediaDeviceId: number;
  setMediaDeviceId: any;
  mediaDeviceList: any;
  setMediaDeviceList: any;

  updateData: () => void;
}

const useUpdate = (): hookMember => {
  const params = useParams();

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const [mediaId, setMediaId] = useState<number>(0);
  const [service, setService] = useState<string>("dalgoD");
  const [name, setName] = useState<string>("매체명");

  const [serialNumber, setSerialNumber] = useState<string>("");
  const [attractiveness, setAttractiveness] = useState<number>(0);
  const [performance, setPerformance] = useState<number>(0);
  const [scheduleUnit, setScheduleUnit] = useState<number>(0);
  const [installMethod, setInstallMethod] = useState<string>(""); // 설치 타입
  const [luminance, setLuminance] = useState<number>(0); // 밝기
  const [status, setStatus] = useState<string>("");
  const [viewingAngle, setViewingAngle] = useState<number>(0); // 시야각
  const [isIndoor, setIsIndoor] = useState<boolean>(false);

  const [mediaBusinessTypeId, setMediaBusinessTypeId] = useState<number>(0);
  const [mediaBusinessTypeList, setMediaBusinessTypeList] = useState([]);
  const [siteId, setSiteId] = useState<number>(0);
  const [siteList, setSiteList] = useState([]);
  const [aiDeviceId, setAIDeviceId] = useState<number>(0);
  const [aiDeviceList, setAIDeviceList] = useState([]);
  const [mediaDeviceId, setMediaDeviceId] = useState<number>(0);
  const [mediaDeviceList, setMediaDeviceList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(params.mediaId) {
          const response = await fetch(`${process.env.REACT_APP_URL}/media/one?id=${params.mediaId}`);
          const jsonData = await response.json();

          const mediaBusinessTypeListResponse = await fetch(`${process.env.REACT_APP_URL}/business-type/all`);
          const mediaBusinessTypeListData = await 
          mediaBusinessTypeListResponse.json();
          
          const aiDeviceListResponse =  await fetch(`${process.env.REACT_APP_URL}/ai-device/all`);
          const aiDeviceListData = await aiDeviceListResponse.json();
          
          const siteListResponse = await fetch(`${process.env.REACT_APP_URL}/site/all`);
          const siteListData = await siteListResponse.json();

          const mediaDeviceListResponse = await fetch(`${process.env.REACT_APP_URL}/media-device/all`);
          const mediaDeviceListData = await mediaDeviceListResponse.json();
          
          const { mediaId, name, serialNumber, attractiveness, performance, scheduleUnit, status, luminance, installMethod, viewingAngle, isIndoor, mediaBusinessTypeId, aiDeviceId, siteId, mediaDeviceId} = jsonData.data;
          const service = jsonData.data.mediaBusinessType.business;


          setService(service);
          setMediaBusinessTypeId(mediaBusinessTypeId);
          setMediaBusinessTypeList(mediaBusinessTypeListData);
          
          setAIDeviceId(aiDeviceId);
          setAIDeviceList(aiDeviceListData);

          setSiteId(siteId);
          setSiteList(siteListData);

          setMediaDeviceId(mediaDeviceId);
          setMediaDeviceList(mediaDeviceListData)

          setMediaId(Number(params.mediaId));
          setName(name);
          setSerialNumber(serialNumber);
          setInstallMethod(installMethod);
          setAttractiveness(attractiveness);
          setPerformance(performance);
          setScheduleUnit(scheduleUnit);
          setStatus(status);
          setService(service);
          setLuminance(luminance);
          setViewingAngle(viewingAngle);
          setIsIndoor(isIndoor);
       }
      } catch(error) {
        console.log(error);
      }
    }
    fetchData();
  }, [params.mediaId])

  const updateData = async () => {
    const result = {
      mediaId,
      serialNumber,
      attractiveness,
      performance,
      scheduleUnit,
      installMethod,
      luminance,
      status,
      viewingAngle,
      isIndoor,
      mediaBusinessTypeId,
      siteId,
      aiDeviceId,
      mediaDeviceId
    };

    if (!mediaBusinessTypeId || !siteId) {
      setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.');
      return;
  }

    //TODO: update 추가.
    const fetchData = async () => {
      const res = await fetch(`${process.env.REACT_APP_URL}/media/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mediaId,
          serialNumber,
          attractiveness,
          performance,
          scheduleUnit,
          installMethod,
          luminance,
          status,
          viewingAngle,
          isIndoor,
          mediaBusinessTypeId,
          siteId,
          aiDeviceId,
          mediaDeviceId
        }),
      });

      const result = await res.json();

      return result;
    };

    fetchData().then((res) => {
      if (res.statusCode == 500) {
        setErrorMessage('AI 기기가 중복된 네트워크 기기가 존재합니다.');
      } else if (res.status == 200) {
        navigate('/mediaComponent/media/list');
      } else {
        setErrorMessage('');
      }
    });
    //TODO: 성공시 list로 돌아가기
  };

  return {
    mediaId,
    service,
    name,
    updateData,
    serialNumber,
    setSerialNumber,
    attractiveness,
    setAttractiveness,
    performance,
    setPerformance,
    scheduleUnit,
    setScheduleUnit,
    status,
    setStatus,
    luminance,
    setLuminance,
    installMethod,
    setInstallMethod,
    viewingAngle,
    setViewingAngle,
    errorMessage,
    isIndoor,
    setIsIndoor,
    mediaBusinessTypeId,
    setMediaBusinessTypeId,
    mediaBusinessTypeList,
    setMediaBusinessTypeList,
    siteId,
    setSiteId,
    siteList,
    setSiteList,
    aiDeviceId,
    setAIDeviceId,
    aiDeviceList,
    setAIDeviceList,
    mediaDeviceId,
    setMediaDeviceId,
    mediaDeviceList,
    setMediaDeviceList
  };
};

export default useUpdate;
