import useMediaDeviceUpdate from "./hooks/useMediaDeviceUpdate";

const MediaDeviceUpdate = () => {
  const hookMember = useMediaDeviceUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>매체 기기명 <span className="required">*</span></label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.name}
            onChange={(e) => hookMember.setName(e.target.value)}
          />
        </div>
        <div>
          <label>모델명</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.modelName}
            onChange={(e) => hookMember.setModelName(e.target.value)}
          />
        </div>
        <div>
          <label>가격</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.price}
            onChange={(e) => hookMember.setPrice(e.target.value)}
          />
        </div>
        <div>
          <label>
            기기 종류 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.deviceType}
            onChange={(e) => hookMember.setDeviceType(e.target.value)}
          >
            <option value="">선택해 주세요</option>
            <option value="TV">TV</option>
            <option value="모니터">모니터</option>
            <option value="태블렛">태블렛</option>
          </select>
        </div>
        <div>
          <label>
            송출 종류 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.displayType}
            onChange={(e) => hookMember.setDisplayType(e.target.value)}
          >
            <option value="">선택해 주세요</option>
            <option value="LED">LED</option>
            <option value="QLED">QLED</option>
            <option value="LCD">LCD</option>
            <option value="PDP">PDP</option>
          </select>
        </div>
        <div>
          <label>가로 길이</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.width}
            onChange={(e) => hookMember.setWidth(e.target.value)}
          />
        </div>
        <div>
          <label>세로 길이</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.height}
            onChange={(e) => hookMember.setHeight(e.target.value)}
          />
        </div>
        <div>
          <label>깊이</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.depth}
            onChange={(e) => hookMember.setDepth(e.target.value)}
          />
        </div>
        <div>
          <label>가로 해상도</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.resolutionWidth}
            onChange={(e) => hookMember.setResolutionWidth(e.target.value)}
          />
        </div>
        <div>
          <label>세로 해상도</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.resolutionHeight}
            onChange={(e) => hookMember.setResolutionHeight(e.target.value)}
          />
        </div>
        <div>
          <label>브랜드</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.brand}
            onChange={(e) => hookMember.setBrand(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.updateMediaDeviceData}>저장</button>
      </div>
    </div>
  );
};

export default MediaDeviceUpdate;
