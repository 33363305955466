// // FileConflictDialog.tsx
// import React, { useRef, useEffect } from "react";

import React, { useRef, useEffect } from "react";
import "../css/fileConflictDialog.scss"; // Import SCSS styles

export const FileConflictDialog = ({
  isOpen,
  fileName,
  onOverwrite,
  onUpdateVersion,
  onCancel,
  newVersion,
}: any) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (dialogRef.current) {
      isOpen ? dialogRef.current.showModal() : dialogRef.current.close();
    }
  }, [isOpen]);

  return (
    <dialog ref={dialogRef} className="file-conflict-dialog">
      <div className="dialog-header">
        <h2>File Already Exists</h2>
        <p className="description">
          {`${fileName} 파일이 이미 존재합니다.`}
          <br />
          변경될 최신버전은 <strong>{newVersion}</strong>
          입니다.
        </p>
      </div>
      <div className="button-container">
        <button onClick={onOverwrite} className="btn overwrite">
          덮어쓰기
        </button>
        <button onClick={onUpdateVersion} className="btn update-version">
          최신버전으로 업로드
        </button>
        <button onClick={onCancel} className="btn cancel">
          취소
        </button>
      </div>
    </dialog>
  );
};

export default FileConflictDialog;

// export const FileConflictDialog = ({
//   isOpen,
//   fileName,
//   onOverwrite,
//   onCancel,
//   onUpdateVersion,
// }: any) => {
//   const dialogRef = useRef<HTMLDialogElement>(null);

//   useEffect(() => {
//     if (dialogRef.current) {
//       isOpen ? dialogRef.current.showModal() : dialogRef.current.close();
//     }
//   }, [isOpen]);

//   return (
//     <dialog ref={dialogRef} className="file-conflict-dialog">
//       <h2>File Already Exists</h2>
//       <p>{`${fileName} 파일이 이미 존재합니다. 어떤 작업을 수행하시겠습니까?`}</p>
//       <div>
//         <button onClick={onOverwrite}>덮어쓰기</button>
//         <button onClick={onUpdateVersion}>파일명 변경 후 업로드</button>
//         <button onClick={onCancel}>취소</button>
//       </div>
//     </dialog>
//   );
// };

// export default FileConflictDialog;
