import useMediaNetworkCreate from "./hooks/useMediaNetworkCreate";

const MediaNetworkCreate = () => {
  const hookMember = useMediaNetworkCreate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
                <div>
          <label>
            매체 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.mediaId}
            onChange={(e) => {
              hookMember.setMediaId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.mediaList.data) &&
              hookMember.mediaList.data.map(
                (item: { mediaId: number; name: string }) => (
                  <option key={item.mediaId} value={item.mediaId}>
                    {item.name}
                  </option>
                )
              )}
          </select>
        </div>
        <div>
          <label>날짜</label>
          <input
            type="text"
            name=""
            id=""
            placeholder="2024-01-01"
            value={hookMember.date}
            onChange={(e) => hookMember.setDate(e.target.value)}
          />
        </div>
        <div>
          <label>성공 횟수</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.success}
            onChange={(e) => hookMember.setSuccess(e.target.value)}
          />
        </div>
        <div>
          <label>실패 횟수</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.fail}
            onChange={(e) => hookMember.setFail(e.target.value)}
          />
        </div>
        <div>
          <label>고장 횟수</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.errorCount}
            onChange={(e) => hookMember.setErrorCount(e.target.value)}
          />
        </div>
        <div>
          <label>10분 이상 고장 횟수</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.errorCount10}
            onChange={(e) => hookMember.setErrorCount10(e.target.value)}
          />
        </div>
        <div>
          <label>최대 고장 시간</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.maxErrorTime}
            onChange={(e) => hookMember.setMaxErrorTime(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.createMediaNetwork}>저장</button>
      </div>
    </div>
  );
};

export default MediaNetworkCreate;
