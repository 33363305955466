import useList from "./hooks/useList";
import "./css/mediaList.scss";

import { Link } from "react-router-dom";

const MediaPointList = () => {
  const hookMember = useList();
  return (
    <>
      <h1>매체 좌표 리스트</h1>
      {!hookMember.loading ? (
        <>
          <div>
            <Link to={`/mediaComponent/mediaPoint/create`}>
              <button className="addButton">추가</button>
            </Link>
          </div>
          <div>
            <div>
              <div></div>
              <table id="table">
                <thead>
                  <tr>
                    <th>매체명</th>
                    <th>매체번호</th>
                    <th>point1_x</th>
                    <th>point1_y</th>
                    <th>point2_x</th>
                    <th>point2_y</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {hookMember.list.map((item, index) => {
                    return (
                      <tr key={index}>
                        <th>{item.media?.name}</th>
                        <th>{item.media?.authNumber}</th>
                        <th>{item.point1_x}</th>
                        <th>{item.point1_y}</th>
                        <th>{item.point2_x}</th>
                        <th>{item.point2_y}</th>
                        <th>
                          <Link
                            to={`/mediaComponent/mediaPoint/update/${item.mediaPointId}`}>
                            <button>수정</button>
                          </Link>
                        </th>
                        <th>
                          <button
                            onClick={() =>
                              hookMember.deleteData(item.mediaPointId)
                            }>
                            삭제
                          </button>
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div>
          <p>Loading ...</p>
        </div>
      )}
    </>
  );
};

export default MediaPointList;
