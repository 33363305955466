import { useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import '../css/modal.scss'; // Import the SCSS file

interface Log {
  name: string;
  path: string;
}

const options = [
  { value: '전체 보기', label: '전체 보기' },
  { value: 'INFO', label: 'INFO' },
  { value: 'WARN', label: 'WARN' },
  { value: 'ERROR', label: 'ERROR' },
];

export const useLogModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [selectedOption, setSelectedOption] = useState<string>('ERROR');
  const [currentLog, setCurrentLog] = useState<Log | null>(null);

  const fetchLogData = async (log: Log, filterType: string) => {
    try {
      if (selectedOption === "전체 보기") {
        filterType = "";
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/inquiry-server/read-log`, {
        params: {
          logPath: log.path,
          filterType: filterType,
        },
      });

      const logs = response.data[0];

      setModalContent(
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h2>{log.name}</h2>
            <select
              value={filterType}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="modal-select"
            >
              {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <div>
              {logs.map((logEntry: string, index: number) => (
                <div key={index} style={{ marginBottom: '10px', whiteSpace: 'pre-wrap' }}>
                  {logEntry}
                </div>
              ))}
            </div>
          </div>
        </div>
      );

      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching log data:', error);
      setModalContent(
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h2>{log.name}</h2>
            <p>Error fetching log data. Please try again later.</p>
          </div>
        </div>
      );
      setIsModalOpen(true);
    }
  };

  const openModalWithLogData = (log: Log) => {
    setCurrentLog(log);
    setSelectedOption('ERROR'); 
  };

  useEffect(() => {
    if (currentLog) {
      fetchLogData(currentLog, selectedOption);
    }
  }, [currentLog, selectedOption]); 

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    modalContent,
    openModalWithLogData,
    closeModal,
    selectedOption,
  };
};
