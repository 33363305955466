import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    mediaId: number;
    setMediaId: any;
    mediaList: any;
    setMediaList: any;
    date: string;
    setDate: any;
    success: number;
    setSuccess: any;
    fail: number;
    setFail: any;
    errorCount: number;
    setErrorCount: any;
    errorCount10: number
    setErrorCount10: any;
    maxErrorTime: number;
    setMaxErrorTime: any;

    errorMessage: any;

    createMediaNetwork: () => void;
}

const useMediaNetworkCreate = (): hookMember => {
    const navigate = useNavigate();

    const [mediaId, setMediaId] = useState<number>(0);
    const [mediaList, setMediaList] = useState([]);
    const [date, setDate] = useState<string>("");
    const [success, setSuccess] = useState<number>(0);
    const [fail, setFail] = useState<number>(0);
    const [errorCount, setErrorCount] = useState<number>(0);
    const [errorCount10, setErrorCount10] = useState<number>(0);
    const [maxErrorTime, setMaxErrorTime] = useState<number>(0);

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const mediaListResponse = await fetch(`${process.env.REACT_APP_URL}/media/all`);
                const mediaListData = await mediaListResponse.json();
    
                setMediaList(mediaListData);

            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const createMediaNetwork = async () => {
        try {
            if (!mediaId) {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.')
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/media-network/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        mediaId,
                        date,
                        success,
                        fail,
                        errorCount,
                        errorCount10,
                        maxErrorTime
                    })
                })
                if(response.ok) {
                    navigate('/mediaComponent/mediaNetwork/mediaNetworkList');
                }
            }
        } catch (error) {
            console.error('매체 네트워크 생성 중 오류 발생:', error);
        }
    }
    return {
        mediaId,
        setMediaId,
        mediaList,
        setMediaList,
        date,
        setDate,
        success,
        setSuccess,
        fail,
        setFail,
        errorCount,
        setErrorCount,
        errorCount10,
        setErrorCount10,
        maxErrorTime,
        setMaxErrorTime,
        createMediaNetwork,
        errorMessage
    }
}

export default useMediaNetworkCreate;