import { useEffect, useState } from "react";
import axios from "axios";

interface LogData {
  type: string;
  name: string;
  path: string;
}

const useLogInquiryList = () => {
  const [selectedBusinessType, setSelectedBusinessType] = useState<string>("");
  const [selectedLogType, setSelectedLogType] = useState<string>("");
  const [logTypeOptions, setLogTypeOptions] = useState<string[]>([]);
  const [logData, setLogData] = useState<LogData[]>([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [errorRatio, setErrorRatio] = useState<string>("");
  const [dateIncrement, setDateIncrement] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedMediaNumber, setSelectedMediaNumber] = useState<string>("");
  const [mediaNumberOptions, setMediaNumberOptions] = useState<string[]>([]);
  const [dateArr, setDateArr] = useState<string[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const setDateRangeFromToday = (days: number) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - days);
    setDateIncrement(days);
    setDateRange([startDate, endDate]);
  };

  const shiftDateRange = (direction: "left" | "right") => {
    let [start, end] = dateRange;

    if (!(start && end)) {
      start = new Date();
      end = new Date();
      end.setDate(start.getDate() + 1);
    }
    const shiftAmount = direction === "left" ? -dateIncrement : dateIncrement;

    const newStartDate = new Date(start.getTime());
    newStartDate.setDate(newStartDate.getDate() + shiftAmount);

    const newEndDate = new Date(end.getTime());
    newEndDate.setDate(newEndDate.getDate() + shiftAmount);

    setDateRange([newStartDate, newEndDate]);
  };

  useEffect(() => {
    const fetchLogData = async () => {
      if (selectedBusinessType && selectedLogType) {
        try {
          let [startDate, endDate] = dateRange;

          if (startDate) {
            // startDate에서 시간을 제거하고 날짜만 남김
            startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
          }
    
          if (endDate) {
            // endDate에서 시간을 제거하고 날짜만 남김
            endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
          }

          const params: any = {
            businessType: selectedBusinessType,
            logType: selectedLogType,
          };
  
          if (startDate) params.startDate = startDate.toLocaleDateString('en-CA');
          if (endDate) params.endDate = endDate.toLocaleDateString('en-CA');

  
          if (selectedMediaNumber) {
            params.mediaNumber = selectedMediaNumber;
          }

          const response = await axios.get(
            `${process.env.REACT_APP_URL}/inquiry-server/get-log`,
            { params }
          );
          setLogData(response.data);
          setCurrentPage(1);
        } catch (error) {
          console.error("Error fetching log data:", error);
        }
      }
    };

    fetchLogData();
  }, [selectedBusinessType, selectedLogType, selectedMediaNumber, dateRange]);

  useEffect(() => {
    if (!selectedBusinessType || !selectedLogType || !selectedMediaNumber) {
      setDateArr([]);
    }
  }, [selectedBusinessType, selectedLogType, selectedMediaNumber, setDateArr]);

  const fetchErrorRatio = async (logPath: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/inquiry-server/get-error-ratio`,
        {
          params: { logPath },
        }
      );
      setErrorRatio(response.data);
    } catch (error) {
      console.error("Error fetching error ratio:", error);
    }
  };

  useEffect(() => {
    const fetchMediaNumber = async () => {
      if (selectedBusinessType && selectedLogType) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_URL}/inquiry-server/get-filter`,
            {
              params: {
                businessType: selectedBusinessType,
                fileType: "log",
              },
            }
          );
          setMediaNumberOptions(response.data.mediaNumbers);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchMediaNumber();
  }, [selectedBusinessType, selectedLogType]);

  useEffect(() => {
    if (selectedMediaNumber) {
      fetchDateArr();
    }
  }, [selectedMediaNumber]);

  const fetchDateArr = async () => {
    if (selectedBusinessType && selectedLogType && selectedMediaNumber) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/inquiry-server/get-file-date`,
          {
            params: {
              businessType: selectedBusinessType,
              fileType: "log",
              filterType: selectedLogType,
              mediaNumber: selectedMediaNumber,
            },
          }
        );
        setDateArr(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleBusinessType = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const businessType = event.target.value;
    setSelectedBusinessType(businessType);

    const fileType = "log";

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/inquiry-server/get-filter`,
        {
          params: { businessType, fileType },
        }
      );
      setLogTypeOptions(response.data.types);
    } catch (error) {
      console.error("Error fetching log types:", error);
    }
  };

  const handleMediaNumbers = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedMediaNumber(event.target.value);
  };

  const handleLogType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLogType(event.target.value);
  };

  const handleDownloadLog = async (event: any) => {
    const filePath = event;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/inquiry-server/download-file`,
        {
          params: { filePath },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filePath.split("/").pop());
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error fetching download log:", error);
    }
  };

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadLog = async () => {
    if (!selectedFile) {
      alert("파일을 선택해주세요.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/data/inquiry-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("파일 업로드 성공");
      window.location.reload();
    } catch (error) {
      console.error("Error uploading log file:", error);
      alert("파일 업로드 실패");
    }
  };

  const resetFilters = () => {
    setSelectedBusinessType("");
    setSelectedLogType("");
    setSearchQuery("");
    setSelectedMediaNumber("");
    setCurrentPage(1);
  };

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const filteredLogData = logData.filter((log) =>
    log.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLogData = filteredLogData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredLogData.length / itemsPerPage);

  return {
    selectedBusinessType,
    selectedLogType,
    logTypeOptions,
    logData,
    dateRange,
    errorRatio,
    resetFilters,
    shiftDateRange,
    setDateRange,
    setDateRangeFromToday,
    handleBusinessType,
    handleLogType,
    handleDownloadLog,
    handleUploadLog,
    handleFileChange,
    setSearchQuery: handleSearchQueryChange,
    searchQuery,
    filteredLogData,
    currentLogData,
    currentPage,
    totalPages,
    setCurrentPage,
    fetchErrorRatio,
    mediaNumberOptions,
    selectedMediaNumber,
    handleMediaNumbers,
    dateArr
  };
};

export default useLogInquiryList;
