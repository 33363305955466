import { useEffect, useState } from "react";

enum MediaDeviceProperties {
  NAME = "매체 기기명",
  MODEL_NAME = "모델명",
  PRICE = "가격",
  DEVICE_TYPE = "기기 종류",
  DISPLAY_TYPE = "송출 종류",
  WIDTH = "가로 길이",
  HEIGHT = "세로 길이",
  DEPTH = "깊이",
  RESOLUTION_WIDTH = "가로 해상도 ",
  RESOLUTION_HEIGHT = "세로 해상도	",
  BRAND = "브랜드",
}

const useMediaDeviceList = (initialPage: number) => {
  const transformData = (
    rawData: any[]
  ): Record<MediaDeviceProperties, any>[] => {
    return rawData.map((item) => ({
      [MediaDeviceProperties.NAME]: item.name,
      [MediaDeviceProperties.MODEL_NAME]: item.modelName,
      [MediaDeviceProperties.PRICE]: item.price,
      [MediaDeviceProperties.DEVICE_TYPE]: item.deviceType,
      [MediaDeviceProperties.DISPLAY_TYPE]: item.displayType,
      [MediaDeviceProperties.WIDTH]: item.width,
      [MediaDeviceProperties.HEIGHT]: item.height,
      [MediaDeviceProperties.DEPTH]: item.depth,
      [MediaDeviceProperties.RESOLUTION_WIDTH]: item.resolutionWidth,
      [MediaDeviceProperties.RESOLUTION_HEIGHT]: item.resolutionHeight,
      [MediaDeviceProperties.BRAND]: item.brand,
    }));
  };
  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (mediaDeviceId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/media-device/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mediaDeviceId: mediaDeviceId }),
      })
        .then(async (response) => {
          if ((await response.json()) === 1451) {
            // 키 참조 에러처리
            alert("삭제에 실패했습니다.");
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          window.location.reload();
          // alert("삭제에 실패하였습니다.");
        });
    }
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/media-device/list?page=${page}`
      );

      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/media-device/list`
      );

      if (!countResponse.ok) {
        throw new Error("Network request failed");
      }

      const countJsonData = await countResponse.json();

      const totalDataCount = countJsonData.data.count;
      setCount(totalDataCount);
      // 모든 데이터를 가져온 경우 fetch 종료
      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
    handleDeleteClick,
    isFetchingComplete,
  };
};

export default useMediaDeviceList;
