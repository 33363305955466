import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  adCorpFieldId: number;
  setAdCorpFieldId: any;
  section: string;
  setSection: any;
  industryGroup: string;
  setIndustryGroup: any;
  industry: string;
  setIndustry: any;
  subIndustry: string;
  setSubIndustry: any;
  errorMessage: any;

  updateAdCorpFieldData: () => void;
}

const useAdCorpFieldUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [adCorpFieldId, setAdCorpFieldId] = useState<number>(0);
  const [section, setSection] = useState<string>("");
  const [industryGroup, setIndustryGroup] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");
  const [subIndustry, setSubIndustry] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.adCorpFieldId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/ad-corp-field/one?id=${params.adCorpFieldId}`
          );
          const jsonData = await response.json();

          const {
            adCorpFieldId,
            section,
            industryGroup,
            industry,
            subIndustry
          } = jsonData.data;

          setAdCorpFieldId(adCorpFieldId);
          setSection(section);
          setIndustryGroup(industryGroup);
          setIndustry(industry);
          setSubIndustry(subIndustry);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.AdCorpFieldId]);

  const updateAdCorpFieldData = () => {
    const result = {
        adCorpFieldId,
        section,
        industryGroup,
        industry,
        subIndustry
    };

    if (section == "") {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/ad-corp-field/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            adCorpFieldId,
            section,
            industryGroup,
            industry,
            subIndustry
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/adComponent/adCorpField/adCorpFieldList");
      }
    });
  };

  return {
    adCorpFieldId,
    setAdCorpFieldId,
    section,
    setSection,
    industryGroup,
    setIndustryGroup,
    industry,
    setIndustry,
    subIndustry,
    setSubIndustry,
    errorMessage,
    updateAdCorpFieldData,
  };
};

export default useAdCorpFieldUpdate;