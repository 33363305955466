import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import useAdFieldList from "./hooks/useAdFieldList";
import FilterDropdown from "../../../components/atoms/filterDropdown/FilterDropdown";
import {
  CustomColumnProps,
  DynamicTable,
} from "../../../components/table/dynamicTable";
import fetchProfile from "../../../api/userProfile";
import sortedData from "../../../util/sortedData";

const AdFieldList = () => {
  const {
    data,
    loading,
    currentPage,
    nextPage,
    tableData,
    setTableData,
    handleDeleteClick,
    columns,
    isFetchingComplete,
  } = useAdFieldList(0);

  const [isFetching, setIsFetching] = useState(false);
  const [sortBy, setSortBy] = useState("adFieldId");
  const [sortDirection, setSortDirection] = useState("asc");
  const observer = useRef<IntersectionObserver | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [profile, setProfile] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    const profileData = await fetchProfile();
    setProfile(profileData);
  };

  useEffect(() => {
    if (!profile) return;
    if (loading || isFetchingComplete) return;

    const tableElement = document.getElementById("table");
    const scrollTriggerDivElement =
      document.getElementById("scroll-trigger-div");

    if (tableElement && scrollTriggerDivElement) {
      scrollTriggerDivElement.style.width = tableElement.clientWidth + "px";
    }

    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting) {
        loadMoreData();
      }
    };

    observer.current = new IntersectionObserver(handleObserver, {
      threshold: 0.2,
    });

    const scrollTriggerElement = document.getElementById("scroll-trigger");
    if (scrollTriggerElement) {
      observer.current.observe(scrollTriggerElement);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, currentPage, isFetching, profile]);

  const loadMoreData = async () => {
    if (!isFetching) {
      setIsFetching(true);
      await nextPage();
      setIsFetching(false);
    }
  };

  const CustomEditButton: React.FC<{ item: any }> = ({ item }) => (
    <Link to={`/adComponent/adField/adFieldUpdate/${item.adFieldId}`}>
      수정
    </Link>
  );

  const CustomDeleteButton: React.FC<{ item: any }> = ({ item }) => (
    <button onClick={() => handleDeleteClick(item.adFieldId)}>삭제</button>
  );

  let customColumns: CustomColumnProps[] = [];

  if (
    tableData &&
    tableData.length > 0 &&
    Object.keys(tableData[0]).length > 0
  ) {
    customColumns = [
      {
        index: Object.keys(tableData[0]).length,
        rowText: "수정하기",
        component: CustomEditButton,
      },
      {
        index: Object.keys(tableData[0]).length + 1,
        rowText: "삭제하기",
        component: CustomDeleteButton,
      },
    ];
  }

  const downloadExcel = async () => {
    alert("다운로드가 진행 중입니다.");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/ad-field/download-excel`,
        {},
        {
          responseType: "blob",
        }
      );

      // 파일 다운로드를 위한 Blob 생성
      const blob = new Blob([response.data], { type: "text/xlsx" });
      const url = window.URL.createObjectURL(blob);

      // 다운로드 링크 생성 및 클릭
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "광고분류_테이블.xlsx");
      document.body.appendChild(link);
      link.click();

      // 생성된 URL 해제
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error creating xlsx:", error);
    }
  };

  // 검색 기능
  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const filteredDataArray = sortedData(data, sortBy, sortDirection).filter(
      (item) =>
        item.middleCategory &&
        item.middleCategory.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredDataArray);
  }, [searchTerm, data, sortBy, sortDirection]);

  return (
    <div>
      {loading ? (
        <p>Loading ...</p>
      ) : (
        <div className="container">
          <>
            <Link to={"/adComponent/adField/adFieldCreate"}>
              <button className="addButton">추가</button>
            </Link>
          </>
          <>
            <button className="excelButton" onClick={downloadExcel}>
              엑셀 다운로드
            </button>
            <div className="header">
              <h1>광고 분류 리스트</h1>
              <FilterDropdown
                columns={columns}
                searchTerm={searchTerm}
                tableData={tableData}
                allData={data}
                setTableData={setTableData}
              />
            </div>
          </>
          <div className="table-container">
            <DynamicTable data={tableData} customColumns={customColumns} />
            <div id="scroll-trigger-div">
              <div id="scroll-trigger" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdFieldList;
