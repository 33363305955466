// node.tsx
import { useState } from "react";

export const Node = ({
  node,
  onClick,
  onRightClick,
  selectedPath,
  parentPath,
}: any) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = (e: React.MouseEvent) => {
    e.stopPropagation(); // 부모로의 이벤트 전파 방지
    setExpanded(!expanded);
  };

  const handleClick = (e: React.MouseEvent, path: string) => {
    e.preventDefault(); // 기본 동작 방지
    onClick(path);
  };

  const handleRightClick = (
    e: React.MouseEvent,
    path: string,
    isFile: boolean
  ) => {
    e.preventDefault(); // 기본 우클릭 메뉴 방지
    onRightClick(e, path, isFile);
  };

  const nodePath = parentPath ? `${parentPath}/${node.name}` : node.name;

  return (
    <li draggable={false}>
      <div
        className={`tree-node ${selectedPath === nodePath ? "selected" : ""}`}
        onClick={(e) => handleClick(e, nodePath)}
        onContextMenu={(e) => handleRightClick(e, nodePath, !node.children)}
        draggable={false} // 드래그 비활성화
      >
        {node.children ? (
          <span onClick={handleExpand} draggable={false}>
            {expanded ? "📂" : "📁"} {node.name}
          </span>
        ) : (
          <span draggable={false}>📄 {node.name}</span>
        )}
      </div>
      {expanded && node.children && (
        <ul className="tree-node-children">
          {node.children.map((child: any) => (
            <Node
              key={child.name}
              node={child}
              onClick={onClick}
              onRightClick={onRightClick}
              selectedPath={selectedPath}
              parentPath={nodePath}
            />
          ))}
        </ul>
      )}
    </li>
  );
};
