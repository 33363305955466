import useCpvUpdate from "./hooks/useCpvUpdate";

const CpvUpdate = () => {
  const hookMember = useCpvUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>
            비즈니스 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.businessTypeId}
            onChange={(e) => {
              hookMember.setBusinessTypeId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.businessTypeList.data) &&
              hookMember.businessTypeList.data.map(
                (item: { businessTypeId : number; business: string }) => (
                  <option key={item.businessTypeId} value={item.businessTypeId}>
                    {item.business}
                  </option>
                )
              )}
          </select>
        </div>
        <div>
          <label>
            장소 분류 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.siteFieldId}
            onChange={(e) => {
              hookMember.setSiteFieldId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.siteFieldList.data) &&
              hookMember.siteFieldList.data.map(
                (item: { siteFieldId : number; middleCategory: string }) => (
                  <option key={item.siteFieldId} value={item.siteFieldId}>
                    {item.middleCategory}
                  </option>
                )
              )}
          </select>
        </div>
        <div>
          <label>노출 가격</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.exposedUnitPrice}
            onChange={(e) => hookMember.setExposedUnitPrice(e.target.value)}
          />
        </div>
        <div>
          <label>인지 가격</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.watchedUnitPrice}
            onChange={(e) => hookMember.setWatchedUnitPrice(e.target.value)}
          />
        </div>
        <div>
          <label>주목 가격</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.attentionedUnitPrice}
            onChange={(e) => hookMember.setAttentionedUnitPrice(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.updateCpvData}>저장</button>
      </div>
    </div>
  );
};

export default CpvUpdate;
