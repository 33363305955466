import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    name: string;
    setName: any;
    authNumber: number;
    setAuthNumber: any;
    serialNumber: string;
    setSerialNumber: any;
    owner: string;
    setOwner: any;
    attractiveness: number;
    setAttractiveness: any;
    performance: number;
    setPerformance: any;
    scheduleUnit: number;
    setScheduleUnit: any;
    status: string;
    setStatus: any;
    luminance: number;
    setLuminance: any;
    installMethod: string;
    setInstallMethod: any;
    viewingAngle: number;
    setViewingAngle: any;
    isIndoor: boolean;
    setIsIndoor: any;
    contractor: string;
    setContractor: any;
    errorMessage: any;
    

    createMedia: () => void;
}

const useCreate = (): hookMember => {
    const navigate = useNavigate();

    const [name, setName] = useState<string>("");
    const [authNumber, setAuthNumber] = useState<number>(0);
    const [serialNumber, setSerialNumber] = useState<string>(""); 
    const [owner, setOwner] = useState<string>("");
    const [attractiveness, setAttractiveness] = useState<number>(0);
    const [performance, setPerformance] = useState<number>(0);
    const [scheduleUnit, setScheduleUnit] = useState<number>(0);
    const [luminance, setLuminance] = useState<number>(0);
    const [installMethod, setInstallMethod] = useState<string>("");
    const [viewingAngle, setViewingAngle] = useState<number>(0);
    const [isIndoor, setIsIndoor] = useState<boolean>(false);
    const [contractor, setContractor] = useState<string>("");
    const [status, setStatus] = useState<string>("시작 전");

    const [errorMessage, setErrorMessage] = useState('');

    const createMedia = async () => {
        try {
            if(name === "" || authNumber === 0) {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.')
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/media/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name,
                        authNumber,
                        serialNumber,
                        owner,
                        attractiveness,
                        performance,
                        scheduleUnit,
                        luminance,
                        installMethod,
                        viewingAngle,
                        isIndoor,
                        contractor,
                        status,
                    })
                })
                if (response.ok) {
                    navigate('/media/list');
                  } else {
                    console.error('미디어 생성에 실패했습니다.');
                  }
            }
            } catch (error) {
              console.error('미디어 생성 중 오류 발생:', error);
            }
    }

    return {
        name,
        setName,
        authNumber,
        setAuthNumber,
        serialNumber,
        setSerialNumber,
        owner,
        setOwner,
        attractiveness,
        setAttractiveness,
        performance,
        setPerformance,
        scheduleUnit,
        setScheduleUnit,
        status,
        setStatus,
        luminance,
        setLuminance,
        installMethod,
        setInstallMethod,
        viewingAngle,
        setViewingAngle,
        isIndoor,
        setIsIndoor,
        contractor,
        setContractor,
        createMedia,
        errorMessage
    }
}

export default useCreate;