import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  businessTypeId: number;
  setBusinessTypeId: any;
  business: string;
  setBusiness: any;
  exposedStandard: number;
  setExposedStandard: any;
  watchedStandard: number;
  setWatchedStandard: any;
  attentionedStandard: number;
  setAttentionedStandard: any;

  errorMessage: any;

  updateBusinessTypeData: () => void;
}

const useBusinessTypeUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [businessTypeId, setBusinessTypeId] = useState<number>(0);
  const [business, setBusiness] = useState<string>("");
  const [exposedStandard, setExposedStandard] = useState<number>(0);
  const [watchedStandard, setWatchedStandard] = useState<number>(0);
  const [attentionedStandard, setAttentionedStandard] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.businessTypeId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/business-type/one?id=${params.businessTypeId}`
          );
          const jsonData = await response.json();

          const {
            businessTypeId,
            business,
            exposedStandard,
            watchedStandard,
            attentionedStandard
          } = jsonData.data;

          setBusinessTypeId(businessTypeId);
          setBusiness(business);
          setExposedStandard(exposedStandard);
          setWatchedStandard(watchedStandard);
          setAttentionedStandard(attentionedStandard);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.businessTypeId]);

  const updateBusinessTypeData = () => {
    const result = {
        businessTypeId,
        business,
        exposedStandard,
        watchedStandard,
        attentionedStandard
    };

    if (!business) {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/business-type/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            businessTypeId,
            business,
            exposedStandard,
            watchedStandard,
            attentionedStandard
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/businessType/businessTypeList");
      }
    });
  };

  return {
    businessTypeId,
    setBusinessTypeId,
    business,
    setBusiness,
    exposedStandard,
    setExposedStandard,
    watchedStandard,
    setWatchedStandard,
    attentionedStandard,
    setAttentionedStandard,
    errorMessage,
    updateBusinessTypeData,
  };
};

export default useBusinessTypeUpdate;
