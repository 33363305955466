import { Outlet, Link, useNavigate } from "react-router-dom"; 
import "./css/MainLayout.scss";
import Sidebar from "../sidebar/Sidebar";
import { useEffect, useState } from "react";
import fetchProfile from "../../../api/userProfile";
import { ReactComponent as User } from "../../../assets/images/user.svg";
import LoginPage from "../login/LoginPage";
import logo from '../../../assets/images/adddlogo.png';

const MainLayout = () => {
  const [profile, setProfile] = useState<any>(null);
  const [token, setToken] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = sessionStorage.getItem('token'); // 세션 스토리지에서 토큰 가져오기
    if (storedToken) {
      setToken(storedToken); // 토큰이 있다면 상태에 저장
      fetchProfileData(storedToken); // 토큰이 있으면 프로필 데이터 가져오기
    } else {
      navigate("/");
      window.location.reload();
    }
  }, []);

  const fetchProfileData = async (token: string) => { // 토큰을 매개변수로 받도록 수정
    try {
      const profileData = await fetchProfile(); // 토큰을 사용하여 프로필 데이터 가져오기
      setProfile(profileData);
    } catch (error) {
      console.error('프로필 데이터를 불러오는 중 오류 발생:', error);
    }
  };

  const handleLogout = async () => {
    try {
      const refreshToken = sessionStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_URL}/auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${refreshToken}`
        },
      });
      if (response.ok) {
        sessionStorage.removeItem('token'); // 토큰 삭제
        navigate("/");
        window.location.reload();
      } else {
        console.error("로그아웃 실패");
      }
    } catch (error) {
      console.error("로그아웃 요청 중 오류 발생", error);
    }
  };

  const handleLogin = async (credentials: { email: string; password: string }) => {
    try {
      sessionStorage.removeItem('token');
      const response = await fetch(`${process.env.REACT_APP_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      if (!response.ok) {
        throw new Error('Invalid credentials');
      }
      const data = await response.json();
      if (data.status === 200) {
        setToken(data.data);
        sessionStorage.setItem('token', data.data);
        window.location.replace(`${process.env.REACT_APP_URL}`);
        window.location.reload();
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div>
      {token ? (
        <>
          <header className="top-section">
            <div className="logo">
              <img src={logo} alt="Logo" />
            </div>
            {profile && profile.data.name && (
              <div className="profile-container">
                <div className="profile-info">
                  <span className="profile-icon">
                    <User />
                  </span>
                  <span className="profile-name">{profile.data.name}</span>
                </div>
                <button className="logout-button" onClick={handleLogout}>Logout</button>
              </div>
            )}
          </header>
          <main>
            <Sidebar />
            <div id="mainDiv">
              <Outlet />
            </div>
          </main>
        </>
      ) : (
        <LoginPage/>
      )}
    </div>
  );
};

export default MainLayout;
