import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    issueDate: string;
    setIssueDate: any;
    issueContent: string;
    setIssueContent: any;
    issueType: string;
    setIssueType: any;

    aiDeviceId: number;
    setAIDeviceId: any;
    aiDeviceList: any;
    setAIDeviceList: any;

    errorMessage: any;
    createAIDeviceIssue: () => void;
}

const useAIDeviceIssueCreate = (): hookMember => {
    const navigate = useNavigate();

    const [issueDate, setIssueDate] = useState<string>("");
    const [issueContent, setIssueContent] = useState<string>("");
    const [issueType, setIssueType] = useState<string>("네트워크 이슈");

    const [aiDeviceId, setAIDeviceId] = useState<number>(0);
    const [aiDeviceList, setAIDeviceList] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        const fetchData = async () => {
          try {
            const aiDeviceListResponse = await fetch(`${process.env.REACT_APP_URL}/ai-device/all`);
              const aiDeviceListData = await aiDeviceListResponse.json();
    
                setAIDeviceList(aiDeviceListData);
          } catch (error) {
            console.log(error);
          }
        };
        fetchData();
      }, []);


    const createAIDeviceIssue = async () => {
        try {
            if(!issueDate || !aiDeviceId) {
                setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
            } else {

                const localDate = new Date(issueDate); // 입력된 날짜를 로컬 날짜로 파싱
                const offset = localDate.getTimezoneOffset(); // 현재 시간대의 오프셋을 가져옴 (분 단위)

                // 오프셋을 빼서 UTC 시간으로 변환
                localDate.setMinutes(localDate.getMinutes() - offset);

                const response = await fetch(`${process.env.REACT_APP_URL}/ai-device-issue/create`, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    issueDate: localDate.toISOString(),
                    issueContent,
                    issueType,
                    aiDeviceId
                })
            })
            if (response.ok) {
                navigate('/aiComponent/aiDeviceIssue/aiDeviceIssueList');
            } else {
                console.error('AI 기기 이슈 생성에 실패했습니다.');
            }
            }
        } catch (error) {
            console.error('AI 기기 이슈 생성 중 오류 발생')
        }
    }

    return {
        issueDate,
        setIssueDate,
        issueContent,
        setIssueContent,
        issueType,
        setIssueType,
        errorMessage,
        createAIDeviceIssue,
        aiDeviceId,
        setAIDeviceId,
        aiDeviceList,
        setAIDeviceList
    }
}

export default useAIDeviceIssueCreate;