// LoginForm.tsx
import React, { useState } from "react";
import "./css/LoginPage.scss";

interface LoginFormProps {
  onSubmit: (credentials: { email: string; password: string; }) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorStatus, setErrorStatus] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({ email, password });
    if (email === ""){
      setErrorStatus(true);
      setErrorMessage("아이디(이메일)는 필수 입력사항입니다.");
    } else if (password === "") {
      setErrorStatus(true);
      setErrorMessage("비밀번호는 필수 입력사항입니다.");
    } 
  };

  return (
    <>
    <div>
      <p className="loginFormTitle">AI-DEV 서버</p>
      <form className="loginForm" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="이메일"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {errorStatus ? <span className="errorMessage">{errorMessage}</span>: ""}
        <button type="submit">로그인</button>
      </form>
      </div>
    </>
  );
};

export default LoginForm;
