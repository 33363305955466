import { useEffect, useState } from "react";

type circleColor = 0 | 1 | 2;

interface hookMember {
  mediaList: any[];
  media: number;
  setMedia: (media: number) => void;
  loading: boolean;
  date: string;
  setDate: any;
  gps: any[];
  lastUpdate: string;
  driveInfo: any[];
  radioIndex: string;
  setRadioIndex: any;
  createInfoWindow: (data: any) => void;
  infoWindow: any;
  circleColor: circleColor;
  setCircleColor: (circleColor: circleColor) => void;
  selectedIdx: number;
  speedLog: any[];
  legend: any[];
}

const useDalgoTDashboard = (): hookMember => {
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [media, setMedia] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [date, setDate] = useState<string>("");
  const [lastUpdate, setLastUpdate] = useState<string>("");
  const [gps, setGps] = useState<any[]>([]);
  const [driveInfo, setDriveInfo] = useState<any[]>([]);
  const [radioIndex, setRadioIndex] = useState<string>("");
  const [infoWindow, setInfoWindow] = useState<any>(false);
  const [circleColor, setCircleColor] = useState<circleColor>(0);
  const [selectedIdx, setSelectedIdx] = useState<number>(-1);
  const [speedLog, setSpeedLog] = useState<any[]>([]);
  const [legend, setLegend] = useState<any[]>([]);

  const fetchMediaList = async () => {
    if (!loading) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/media/byBusiness?service=DalgoT`
      );
      const jsonData = await response.json();

      if (jsonData.status == 200) {
        setMedia(jsonData.data[0].authNumber);
        setMediaList(jsonData.data);
      } else {
        setMedia(0);
        setMediaList([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchGPS = async (date: string, media: number, radioIndex: string) => {
    if (!date) return;
    if (!media) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/dashboard/dalgoT/gps?media=${media}&date=${date}`
        // `${process.env.REACT_APP_URL}/dashboard/dalgoT/gps?media=7000&date=2024-08-08`
      );
      const jsonData = await response.json();

      if (jsonData.status == 200) {
        splitGps(jsonData.data, radioIndex);
        setLoading(false);
      } else {
        setGps([]);
        setLastUpdate("");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setGps([]);
      setLastUpdate("");
      setLoading(false);
    }
  };

  // 휴식시간 나누기
  const splitGps = (gps: any[], radioIndex: string) => {
    gps = gps.filter((item) => {
      return Number(item[1]) !== 0 && Number(item[2]) !== 0;
    });

    const gpsArray: any[] = [[]];
    let i = 0;
    gps.map((item, index) => {
      if (index > 0) {
        var diff =
          new Date(item[0]).getTime() - new Date(gps[index - 1][0]).getTime();
        if (isNaN(diff) || diff > 60000) {
          if (!isNaN(new Date(gps[index - 1][0]).getTime())) {
            gpsArray.push([]);
            i++;
          }
        }
      }

      // if (item[0].length > 20) {
      //   gpsArray.push([]);
      //   i++;
      // }
      gpsArray[i].push(item);
    });

    // console.log(gpsArray);

    let p = radioIndex
      ? gpsArray[Number(radioIndex)].map((item: any, idx: number) => {
          return {
            idx,
            time: item[0].split(" ")[1],
            point: { lat: Number(item[1]), lng: Number(item[2]) },
            speed: Number(item[3]),
            distance: item[4],
            exposed: item[5],
            exposed2: item[4] > 0 ? item[5] / item[4] : item[5],
          };
        })
      : gps.map((item: any, idx: number) => {
          return {
            idx,
            time: item[0].split(" ")[1],
            point: { lat: Number(item[1]), lng: Number(item[2]) },
            speed: Number(item[3]),
            distance: item[4],
            exposed: item[5],
            exposed2: item[4] > 0 ? item[5] / item[4] : item[5],
          };
        });
    // let f = p.filter((item: any) => {
    //   return item.point.lat !== 0 && item.point.lng !== 0;
    // });

    setGps(p);
    createSpeedLog(p);

    const timeDif = (start: string, end: string): number => {
      if (!start || !end) return 0;
      var start_m =
        60 * Number(start.split(":")[0]) + Number(start.split(":")[1]);
      var end_m = 60 * Number(end.split(":")[0]) + Number(end.split(":")[1]);

      return end_m - start_m;
    };

    const timeDifStr = (r: number): string => {
      if (!r) return "";

      var h = Math.floor(r / 60);
      var m = r % 60;
      if (m > 9) {
        return h + ":" + m;
      } else {
        return h + ":0" + m;
      }
    };

    let play_sum = 0;
    let wait_sum = 0;

    const a = gpsArray.map((item, index) => {
      try {
        let startTime = item[0][0].split(" ")[1];
        let endTime = item[item.length - 1][0].split(" ")[1];
        let nextStartTime = gpsArray[index + 1]
          ? gpsArray[index + 1][0][0].split(" ")[1]
          : "";

        let playTime = timeDif(startTime, endTime);
        let waitTime = timeDif(endTime, nextStartTime);

        play_sum += playTime;
        wait_sum += waitTime;

        return {
          idx: index + 1,
          start: startTime.split(":")[0] + ":" + startTime.split(":")[1],
          end: endTime.split(":")[0] + ":" + endTime.split(":")[1],
          play: timeDifStr(playTime),
          wait: timeDifStr(waitTime),
        };
      } catch (err) {
        console.log(err);
        return {
          idx: "",
          start: "",
          end: "",
          play: "",
          wait: "",
        };
      }
    });

    a.push({
      idx: "",
      start: "",
      end: "합계:",
      play: timeDifStr(play_sum),
      wait: timeDifStr(wait_sum),
    });

    let lastUpdateTime = gps[gps.length - 1][0];
    let now = new Date();
    let nowText = `${now.getFullYear()}-${
      now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1
    }-${now.getDate() < 10 ? "0" + now.getDate() : now.getDate()}`;

    let timeDifMinite = Math.floor(
      (now.getTime() - new Date(lastUpdateTime).getTime()) / 60000
    );

    if (nowText === date) {
      setLastUpdate(
        `${timeDifMinite}분 전 업데이트 (${gps[gps.length - 1][0]})`
      );
    } else {
      setLastUpdate(``);
    }

    setDriveInfo(a);
  };

  const createInfoWindow = (data: any) => {
    setSelectedIdx(data.idx);
    setInfoWindow(data);
  };

  const createSpeedLog = (data: any[]) => {
    const filter = data.filter((item) => {
      return item.speed > 50;
    });
    // console.log(filter);
    setSpeedLog(filter);
  };

  useEffect(() => {
    if (date === "") {
      const today = `${new Date().getFullYear()}-${
        new Date().getMonth() > 8
          ? new Date().getMonth() + 1
          : "0" + (new Date().getMonth() + 1)
      }-${
        new Date().getDate() > 9
          ? new Date().getDate()
          : "0" + new Date().getDate()
      }`;

      setDate(today);
    }

    fetchMediaList();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchGPS(date, media, radioIndex);
  }, [date, media, radioIndex]);

  useEffect(() => {
    if (circleColor === 0) {
      setLegend([
        { text: "정상", color: "#88F" },
        { text: "과속(50km/h 초과)", color: "red" },
      ]);
    } else if (circleColor === 2) {
      setLegend([
        { text: "1명 이하(1m당 노출인구)", color: "#F99" },
        { text: "0~1명", color: "#F66" },
        { text: "1~2명", color: "#F33" },
        { text: "2명 이상", color: "#F00" },
      ]);
    }
  }, [circleColor]);

  return {
    mediaList,
    media,
    setMedia,
    loading,
    date,
    setDate,
    gps,
    lastUpdate,
    driveInfo,
    radioIndex,
    setRadioIndex,
    createInfoWindow,
    infoWindow,
    circleColor,
    setCircleColor,
    selectedIdx,
    speedLog,
    legend,
  };
};

export default useDalgoTDashboard;
