import useSiteCreate from "./hooks/useCreate";

const SiteCreate = () => {
  const hookMember = useSiteCreate();

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>
            장소명 <span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.name}
            onChange={(e) => hookMember.setName(e.target.value)}
          />
        </div>
        {/* <div>
          <label>주소</label>
          <div>{hookMember.address}</div>
        </div> */}
        <div>
          <label>위도</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.latitude}
            onChange={(e) => hookMember.setLatitude(e.target.value)}
          />
        </div>
        <div>
          <label>경도</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.longitude}
            onChange={(e) => hookMember.setLongitude(e.target.value)}
          />
        </div>
        <div>
          <label>담당자명</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.adminName}
            onChange={(e) => hookMember.setAdminName(e.target.value)}
          />
        </div>
        <div>
          <label>담당자 연락처</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.adminPhoneNumber}
            onChange={(e) => hookMember.setAdminPhoneNumber(e.target.value)}
          />
        </div>
        <div>
          <label>담당자 직책</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.adminPosition}
            onChange={(e) => hookMember.setAdminPosition(e.target.value)}
          />
        </div>
        <div>
          <label>동적특성</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.dynamicScore}
            onChange={(e) => hookMember.setDynamicScore(e.target.value)}
          />
        </div>
        <div>
          <label>소득층</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.incomeClass}
            onChange={(e) => hookMember.setIncomeClass(e.target.value)}
          />
        </div>
        <div>
          <label>월요일 <span className="required">*</span></label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.monday}
            placeholder="00:00-00:00 / closed"
            onChange={(e) => hookMember.setMonday(e.target.value)}
          />
        </div>
        <div>
          <label>화요일 <span className="required">*</span></label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.tuesday}
            placeholder="00:00-00:00 / closed"
            onChange={(e) => hookMember.setTuesday(e.target.value)}
          />
        </div>
        <div>
          <label>수요일 <span className="required">*</span></label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.wednesday}
            placeholder="00:00-00:00 / closed"
            onChange={(e) => hookMember.setWednesday(e.target.value)}
          />
        </div>
        <div>
          <label>목요일 <span className="required">*</span></label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.thursday}
            placeholder="00:00-00:00 / closed"
            onChange={(e) => hookMember.setThursday(e.target.value)}
          />
        </div>
        <div>
          <label>금요일 <span className="required">*</span></label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.friday}
            placeholder="00:00-00:00 / closed"
            onChange={(e) => hookMember.setFriday(e.target.value)}
          />
        </div>
        <div>
          <label>토요일 <span className="required">*</span></label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.saturday}
            placeholder="00:00-00:00 / closed"
            onChange={(e) => hookMember.setSaturday(e.target.value)}
          />
        </div>
        <div>
          <label>일요일 <span className="required">*</span></label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.sunday}
            placeholder="00:00-00:00 / closed"
            onChange={(e) => hookMember.setSunday(e.target.value)}
          />
        </div>
        <div>
          <label>점심시간 <span className="required">*</span></label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.breakTime}
            placeholder="00:00-00:00 / none"
            onChange={(e) => hookMember.setBreakTime(e.target.value)}
          />
        </div>
        <div>
          <label>공휴일 휴무</label>
          <select
            name=""
            id=""
            value={hookMember.holidayWorking}
            onChange={(e) => hookMember.setHolidayWorking(e.target.value)}
          >
            <option value="break">break</option>
            <option value="working">working</option>
          </select>
        </div>
        <div>
          <label>
            장소 분류 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.siteFieldId}
            onChange={(e) => {
              hookMember.setSiteFieldId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.fieldList.data) &&
              hookMember.fieldList.data.map(
                (item: { siteFieldId: number; middleCategory: string }) => (
                  <option key={item.siteFieldId} value={item.siteFieldId}>
                    {item.middleCategory}
                  </option>
                )
              )}
          </select>
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.createSite}>저장</button>
      </div>
    </div>
  );
};

export default SiteCreate;
