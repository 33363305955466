import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
  businessTypeId: number;
  setBusinessTypeId: any;
  businessTypeList: any;
  setBusinessTypeList: any;
  siteFieldId: number;
  setSiteFieldId: any;
  siteFieldList: any;
  setSiteFieldList: any;
  watchedUnitPrice: number;
  setWatchedUnitPrice: any;
  attentionedUnitPrice: number;
  setAttentionedUnitPrice: any;
  exposedUnitPrice: number;
  setExposedUnitPrice: any;

  errorMessage: any;

  createCpv: () => void;
}

const useCpvCreate = (): hookMember => {
  const navigate = useNavigate();

  const [businessTypeId, setBusinessTypeId] = useState<number>(0);
  const [businessTypeList, setBusinessTypeList] = useState([]);
  const [siteFieldId, setSiteFieldId] = useState<number>(0);
  const [siteFieldList, setSiteFieldList] = useState([]);
  const [watchedUnitPrice, setWatchedUnitPrice] = useState<number>(0);
  const [attentionedUnitPrice, setAttentionedUnitPrice] = useState<number>(0);
  const [exposedUnitPrice, setExposedUnitPrice] = useState<number>(0);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const businessTypeListResponse = await fetch(
          `${process.env.REACT_APP_URL}/business-type/all`
        );
        const businessTypeListData = await businessTypeListResponse.json();

        setBusinessTypeList(businessTypeListData);

        const siteFieldListResponse = await fetch(
          `${process.env.REACT_APP_URL}/site-field/all`
        );
        const siteFieldListData = await siteFieldListResponse.json();

        setSiteFieldList(siteFieldListData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const createCpv = async () => {
    try {
      if (!businessTypeId || !siteFieldId) {
        setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/cpv/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              businessTypeId,
              siteFieldId,
              watchedUnitPrice,
              attentionedUnitPrice,
              exposedUnitPrice,
            }),
          }
        );
        if (response.ok) {
          navigate("/cpv/cpvList");
        }
      }
    } catch (error) {
      console.error("네트워크 기기 생성 중 오류 발생:", error);
    }
  };
  return {
    businessTypeId,
    setBusinessTypeId,
    businessTypeList,
    setBusinessTypeList,
    siteFieldId,
    setSiteFieldId,
    siteFieldList,
    setSiteFieldList,
    watchedUnitPrice,
    setWatchedUnitPrice,
    attentionedUnitPrice,
    setAttentionedUnitPrice,
    exposedUnitPrice,
    setExposedUnitPrice,
    createCpv,
    errorMessage,
  };
};

export default useCpvCreate;
