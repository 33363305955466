import { useEffect, useState } from "react";

enum AiModuleProperties {
  NAME = "모듈명",
  TYPE = "종류",
  COMPANY = "업체",
  FP32 = "FP32 성능",
  FP16 = "FP16 성능",
  INT8 = "INT8 성능",
}

const useAiModuleList = (initialPage: number) => {
  const transformData = (rawData: any[]): Record<AiModuleProperties, any>[] => {
    return rawData.map((item) => ({
      [AiModuleProperties.NAME]: item.name,
      [AiModuleProperties.TYPE]: item.type,
      [AiModuleProperties.COMPANY]: item.company,
      [AiModuleProperties.FP32]: item.fp32,
      [AiModuleProperties.FP16]: item.fp16,
      [AiModuleProperties.INT8]: item.int8,
    }));
  };
  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (aiModuleId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/ai-module/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ aiModuleId: aiModuleId }),
      })
        .then(async (response) => {
          if ((await response.json()) === 1451) {
            // 키 참조 에러처리
            alert("삭제에 실패했습니다.");
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          alert("삭제에 실패하였습니다.");
        });
    }
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/ai-module/list?page=${page}`
      );

      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/ai-module/list`
      );

      if (!countResponse.ok) {
        throw new Error("Network request failed");
      }

      const countJsonData = await countResponse.json();
      const totalDataCount = countJsonData.data.count;

      setCount(countJsonData.data.count);
      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
    handleDeleteClick,
    isFetchingComplete,
  };
};

export default useAiModuleList;
