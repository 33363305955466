import AppRoute from "./routes/AppRoute";
import "./App.css";
import LoginPage from "./components/layouts/login/LoginPage";

function App() {
  // return <AppRoute />;
  return (
    <div className="App">
      <LoginPage />
    </div>
  );
}

export default App;
