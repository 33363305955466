import useScheduleDashboard from "./hooks/useScheduleDashboard";
import "./css/scheduleDashboard.scss";
import { Link } from "react-router-dom";

const backgroundColor = {
  O: "#4ADE80",
  X: "#EF4444",
  err: "#FFA500",
};

const ScheduleDashboard: any = () => {
  const hookMember = useScheduleDashboard();

  return (
    <div id="schedule-table">
      <h1>스케줄러 관제 - 전체</h1>
      {!hookMember.loading ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}>
            {/* <button
              onClick={() => {
                hookMember.changeWeek(1);
              }}>
              {"<"}
            </button> */}
            <div style={{ fontSize: "20px" }}>
              {hookMember.date[0]} ~ {hookMember.date[6]}
            </div>
            {/* {hookMember.page === 0 ? (
              <></>
            ) : (
              <button
                onClick={() => {
                  hookMember.changeWeek(-1);
                }}>
                {">"}
              </button>
            )} */}
            <div className="color">
              <p>정상</p>
              <div style={{ backgroundColor: backgroundColor["O"] }}></div>
              <p>오류</p>
              <div style={{ backgroundColor: backgroundColor["X"] }}></div>
            </div>
          </div>
          <div className="schedule-table">
            <table>
              <thead>
                <tr>
                  <th>매체명</th>
                  <th>매체번호</th>
                  {hookMember.date.map((item, index) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {hookMember.medias.length === 0 ? (
                  <tr
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    <td>해당 매체가 없습니다.</td>
                  </tr>
                ) : (
                  hookMember.medias.map((item: any, index: number) => {
                    return (
                      <>
                        <tr key={index}>
                          <th>{item.name}</th>
                          <th>{item.authNumber}</th>
                          <th>
                            {colorDiv(
                              item.schedule[0] ? "O" : "X",
                              item.authNumber,
                              hookMember.date[0]
                            )}
                          </th>
                          <th>
                            {colorDiv(
                              item.schedule[1] ? "O" : "X",
                              item.authNumber,
                              hookMember.date[1]
                            )}
                          </th>
                          <th>
                            {colorDiv(
                              item.schedule[2] ? "O" : "X",
                              item.authNumber,
                              hookMember.date[2]
                            )}
                          </th>
                          <th>
                            {colorDiv(
                              item.schedule[3] ? "O" : "X",
                              item.authNumber,
                              hookMember.date[3]
                            )}
                          </th>
                          <th>
                            {colorDiv(
                              item.schedule[4] ? "O" : "X",
                              item.authNumber,
                              hookMember.date[4]
                            )}
                          </th>
                          <th>
                            {colorDiv(
                              item.schedule[5] ? "O" : "X",
                              item.authNumber,
                              hookMember.date[5]
                            )}
                          </th>
                          <th>
                            {colorDiv(
                              item.schedule[6] ? "O" : "X",
                              item.authNumber,
                              hookMember.date[6]
                            )}
                          </th>
                        </tr>
                      </>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>
          <p>Loading ...</p>
        </div>
      )}
    </div>
  );
};

const colorDiv: any = (status: "O" | "X" | "err", media: any, date: any) => {
  // const backgroundColor = status === "O" ? "#4ADE80" : "#EF4444";
  // let backgroundColor = {
  //   O: "#4ADE80",
  //   X: "#EF4444",
  //   err: "#FFA500",
  // };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Link to={`/dashboard/schedule/${media}?date=${date}`}>
        <div
          style={{
            backgroundColor: backgroundColor[status],
            width: "100px",
            height: "30px",
            borderRadius: "5px",
            textAlign: "center",
            fontSize: "16px",
            alignContent: "center",
          }}>
          {/* 상세보기 */}
        </div>
      </Link>
    </div>
  );
};

export default ScheduleDashboard;
