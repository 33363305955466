import useCameraCreate from "./hooks/useCameraCreate";

const CameraCreate = () => {
  const hookMember = useCameraCreate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>
            라벨<span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.label}
            onChange={(e) => hookMember.setLabel(e.target.value)}
          />
        </div>
        <div>
          <label>현재 해상도 가로</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.currentFrameWidth}
            onChange={(e) => hookMember.setCurrentFrameWidth(e.target.value)}
          />
        </div>
        <div>
          <label>현재 해상도 세로</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.currentFrameHeight}
            onChange={(e) => hookMember.setCurrentFrameHeight(e.target.value)}
          />
        </div>
        <div>
          <label>현재 상태</label>
          <select
            name=""
            id=""
            value={hookMember.currentStatus}
            onChange={(e) => hookMember.setCurrentStatus(e.target.value)}
          >
            <option value="정상">정상</option>
            <option value="비정상">비정상</option>
            <option value="수리">수리</option>
            <option value="사용 안함">사용 안함</option>
            <option value="개발용">개발용</option>
          </select>
        </div>
        <div>
          <label>현재 FPS</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.currentFPS}
            onChange={(e) => hookMember.setCurrentFPS(e.target.value)}
          />
        </div>
        <div>
          <label>
            AI 기기 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.aiDeviceId}
            onChange={(e) => {
              hookMember.setAIDeviceId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.aiDeviceList.data) &&
              hookMember.aiDeviceList.data.map(
                (item: { aiDeviceId: number; label: string }) => (
                  <option key={item.aiDeviceId} value={item.aiDeviceId}>
                    {item.label}
                  </option>
                )
              )}
          </select>
        </div>
        <div>
          <label>
            카메라 종류 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.cameraTypeId}
            onChange={(e) => {
              hookMember.setCameraTypeId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.cameraTypeList.data) &&
              hookMember.cameraTypeList.data.map(
                (item: { cameraTypeId: number; modelName: string }) => (
                  <option key={item.cameraTypeId} value={item.cameraTypeId}>
                    {item.modelName}
                  </option>
                )
              )}
          </select>
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.createCamera}>저장</button>
      </div>
    </div>
  );
};

export default CameraCreate;
