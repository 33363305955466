import { TableHeader } from "./tableHeader";
import { TableRow } from "./tableRow";
import "./css/table.scss";
import { useSort } from "./hooks/useSort"; // 경로는 맞게 조정하세요.

export interface TableType {
  [key: string]: string;
}

export interface CustomComponentProps {
  item: TableType;
  rowIndex: number;
}

export interface CustomColumnProps {
  index: number;
  rowText?: string;
  component?: React.FC<CustomComponentProps>;
}

export interface DynamicTableProps {
  data: TableType[];
  customColumns?: CustomColumnProps[];
}

export const DynamicTable: React.FC<DynamicTableProps> = ({
  data,
  customColumns,
}) => {
  const { sortedData, sortConfig, onSort } = useSort(data);

  const columns = sortedData.length > 0 ? Object.keys(sortedData[0]) : [];

  // 커스텀 열을 삽입할 때 헤더에 추가
  const extendedColumns = [...columns];
  customColumns?.forEach((customColumn) => {
    if (customColumn.rowText) {
      extendedColumns.splice(customColumn.index, 0, customColumn.rowText);
    } else {
      extendedColumns.splice(
        customColumn.index,
        0,
        `Custom ${customColumn.index + 1}`
      );
    }
  });

  return (
    <div className="dynamic-table-container">
      {sortedData.length > 0 ? (
        <table id="table">
          <TableHeader
            columns={extendedColumns}
            onSort={onSort}
            sortConfig={sortConfig}
          />
          <tbody>
            {sortedData.map((item, index) => (
              <TableRow
                key={index}
                item={item}
                customColumns={customColumns}
                rowIndex={index}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-data">정보를 찾을 수 없습니다.</div>
      )}
    </div>
  );
};
