import { useEffect, useState } from "react";

interface hookMember {
  date: string[];
  medias: any;
  loading: boolean;
  changeWeek: any;
  page: number;
}

interface medias {
  authNumber: number;
  name: string;
  app: string;
  image: string;
  data: string;
  network: string;
}

const useWeeklyDashboard = (): hookMember => {
  const [page, setPage] = useState<number>(0);
  const [date, setDate] = useState<string[]>([]);
  const [medias, setMedias] = useState<medias[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    const test = async (mediaPage: number) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/dashboard/weekly?page=${page}&mediaPage=${mediaPage}`
        );
        const jsonData = await response.json();
        if (jsonData.result.length === 0) return;
        if (mediaPage === 0) {
          setMedias(jsonData.result);
          setDate(jsonData.date);
          setLoading(false);
        } else {
          setMedias((prevData) => [...prevData, ...(jsonData.result || [])]);
        }
        test(mediaPage + 1);
      } catch (error) {
        console.error("Error:", error);
        setMedias([]);
        setDate([]);
        setLoading(false);
      }
    };
    setLoading(true);
    test(0);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  function changeWeek(n: number) {
    setLoading(true);
    setPage(page + n);
  }

  return { date, medias, loading, changeWeek, page };
};

export default useWeeklyDashboard;
