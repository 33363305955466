import useCreate from "./hooks/useCreate";

const CreateMedia = () => {
  const hookMember = useCreate();

  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>
            매체 이름<span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.name}
            onChange={(e) => hookMember.setName(e.target.value)}
          />
        </div>
        <div>
          <label>
            매체 고유 번호<span className="required">*</span>
          </label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.authNumber}
            onChange={(e) => hookMember.setAuthNumber(e.target.value)}
          />
        </div>
        <div>
          <label>매체 시리얼</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.serialNumber}
            onChange={(e) => hookMember.setSerialNumber(e.target.value)}
          />
        </div>
        <div>
          <label>매체 소유자</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.owner}
            onChange={(e) => hookMember.setOwner(e.target.value)}
          />
        </div>
        <div>
          <label>매력도</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.attractiveness}
            onChange={(e) => hookMember.setAttractiveness(e.target.value)}
          />
        </div>
        <div>
          <label>평균 실적</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.performance}
            onChange={(e) => hookMember.setPerformance(e.target.value)}
          />
        </div>
        <div>
          <label>스케줄 단위</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.scheduleUnit}
            onChange={(e) => hookMember.setScheduleUnit(e.target.value)}
          />
        </div>
        <div>
          <label>
            설치 방법 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.installMethod}
            onChange={(e) => hookMember.setInstallMethod(e.target.value)}
          >
            <option value="">선택해 주세요</option>
            <option value="천장형">천장형</option>
            <option value="벽부형">벽부형</option>
            <option value="스탠드형">스탠드형</option>
            <option value="매립형">매립형</option>
            <option value="모니터암">모니터 암</option>
          </select>
        </div>
        <div>
          <label>매체 상태</label>
          <select
            name=""
            id=""
            value={hookMember.status}
            onChange={(e) => hookMember.setStatus(e.target.value)}
          >
            <option value="시작 전">시작 전</option>
            <option value="기기 없음">기기 없음</option>
            <option value="설치 대기">설치 대기</option>
            <option value="설치 안됨">설치 안됨</option>
            <option value="네트워크 이슈">네트워크 이슈</option>
            <option value="기기 이슈">기기 이슈</option>
            <option value="테스트">테스트</option>
            <option value="정상">정상</option>
            <option value="중단">중단</option>
          </select>
        </div>
        <div>
          <label>매체 계약자</label>
          <input
            type="string"
            name=""
            id=""
            value={hookMember.contractor}
            onChange={(e) => hookMember.setContractor(e.target.value)}
          />
        </div>
        <div>
          <label>밝기</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.luminance}
            onChange={(e) => hookMember.setLuminance(e.target.value)}
          />
        </div>
        <div>
          <label>시야각</label>
          <input
            type="number"
            name=""
            id=""
            value={hookMember.viewingAngle}
            onChange={(e) => hookMember.setViewingAngle(e.target.value)}
          />
        </div>
        <div>
          <label>실내외 구분</label>
          <select
            name=""
            id=""
            value={(hookMember.isIndoor || false).toString()}
            onChange={(e) => hookMember.setIsIndoor(e.target.value === "true")}
          >
            <option value="false" selected={hookMember.isIndoor === false}>
              실외
            </option>
            <option value="true" selected={hookMember.isIndoor === true}>
              실내
            </option>
          </select>
        </div>
        <button className="saveButton" onClick={hookMember.createMedia}>저장</button>
      </div>
    </div>
  );
};

export default CreateMedia;
