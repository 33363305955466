import { useState, useEffect, ReactNode, useRef } from 'react';
import axios from 'axios';
import '../../logInquiry/css/modal.scss'; // Import the SCSS file

interface Csv {
  name: string;
  path: string;
}

export const useCsvModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [csvFiles, setCsvFiles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [currentCsv, setCurrentCsv] = useState<Csv | null>(null); // 현재 CSV 데이터를 상태로 저장

  const contentRef = useRef<HTMLDivElement | null>(null);

  const fetchCsvData = async (csv: Csv, pageNumber: number) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/inquiry-server/read-csv`, {
        params: {
          csvPath: csv.path,
          page: pageNumber, // 페이지 번호를 서버로 전달
          pageSize: 300, // 한 번에 가져올 CSV 행 수
        },
      });

      const newCsvFiles = response.data;

      setCsvFiles((prevCsvFiles) => [...newCsvFiles]);
      setHasMore(newCsvFiles.length > 0); // 추가 데이터가 있는지 확인

    } catch (error) {
      console.error('Error fetching log data:', error);
      setModalContent(
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{currentCsv?.name}</h2>
            <p>Error fetching log data. Please try again later.</p>
          </div>
        </div>
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      setModalContent(
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{currentCsv?.name}</h2>
            <div id="csvContent">
              {csvFiles.map((csvEntry: string, index: number) => (
                <div key={index} style={{ marginBottom: '10px', whiteSpace: 'pre-wrap' }}>
                  {csvEntry}
                </div>
              ))}
              {hasMore && !isLoading && (
                <button onClick={loadMoreData}>Load More</button>
              )}
            </div>
          </div>
        </div>
      );
    }
  }, [isModalOpen, csvFiles, hasMore, isLoading, currentCsv]);

  const openModalWithCsvData = async (csvData: Csv) => {
    setCurrentCsv(csvData); // 현재 CSV 데이터를 상태로 설정
    setIsLoading(true);
    setIsModalOpen(true);
    setPage(0); // 페이지 초기화
    setCsvFiles([]); // 이전에 로드된 CSV 데이터 초기화
    fetchCsvData(csvData, 0); // 첫 페이지 데이터 로드
  };

  const loadMoreData = () => {
    if (hasMore && !isLoading && currentCsv) {
      setIsLoading(true);
      const nextPage = page + 1;
      setPage(nextPage);
      fetchCsvData(currentCsv, nextPage); // currentCsv를 사용하여 추가 데이터 로드
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsLoading(false);
  };

  return {
    isModalOpen,
    modalContent,
    openModalWithCsvData,
    closeModal,
    isLoading,
    loadMoreData,
  };
};

