import useBusinessTypeUpdate from "./hooks/useBusinessTypeUpdate";

const BusinessTypeUpdate = () => {
  const hookMember = useBusinessTypeUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>비즈니스 <span className="required">*</span></label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.business}
            onChange={(e) => hookMember.setBusiness(e.target.value)}
          />
        </div>
        <div>
          <label>노출 기준</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.exposedStandard}
            onChange={(e) => hookMember.setExposedStandard(e.target.value)}
          />
        </div>
        <div>
          <label>인지 기준</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.watchedStandard}
            onChange={(e) => hookMember.setWatchedStandard(e.target.value)}
          />
        </div>
        <div>
          <label>주목 기준</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.attentionedStandard}
            onChange={(e) => hookMember.setAttentionedStandard(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.updateBusinessTypeData}>저장</button>
      </div>
    </div>
  );
};

export default BusinessTypeUpdate;
