import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
    aiDeviceIssueId: number;
    setAIDeviceIssueId: any;
    issueDate: string;
    setIssueDate: any;
    issueContent: string;
    setIssueContent: any;
    issueType: string;
    setIssueType: any;

    aiDeviceId: number;
    setAIDeviceId: any;
    aiDeviceList: any;
    setAIDeviceList: any;

    errorMessage: any;

    updateAIDeviceIssueData: () => void;
}

const useAIDeviceIssueUpdate = (): hookMember => {
    const params = useParams();

    const navigate = useNavigate();
    const [aiDeviceIssueId, setAIDeviceIssueId] = useState<number>(Number(params.aiDeviceIssueId));
    const [issueDate, setIssueDate] = useState<string>("");
    const [issueContent, setIssueContent] = useState<string>("");
    const [issueType, setIssueType] = useState<string>("");

    const [aiDeviceId, setAIDeviceId] = useState<number>(0);
    const [aiDeviceList, setAIDeviceList] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                if(params.aiDeviceIssueId) {
                    const response = await fetch(`${process.env.REACT_APP_URL}/ai-device-issue/one?id=${params.aiDeviceIssueId}`);
                    const jsonData = await response.json();

                    const aiDeviceListResponse =  await fetch(`${process.env.REACT_APP_URL}/ai-device/all`);
                    const aiDeviceListData = await aiDeviceListResponse.json();

                    const { issueDate, issueContent, issueType, aiDeviceId} = jsonData.data;

                    setAIDeviceId(aiDeviceId);
                    setAIDeviceList(aiDeviceListData);

                    setAIDeviceIssueId(Number(params.aiDeviceIssueId));
                    setIssueDate(issueDate);
                    setIssueContent(issueContent);
                    setIssueType(issueType);
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, [params.aiDeviceIssueId]);

    const updateAIDeviceIssueData = () => {
        const result = {
            aiDeviceIssueId,
            issueDate,
            issueContent,
            issueType,
            aiDeviceId
        }

        if (issueDate === "" || !aiDeviceId) {
            setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.');
            return;
        }

        const localDate = new Date(issueDate); // 입력된 날짜를 로컬 날짜로 파싱
        const offset = localDate.getTimezoneOffset(); // 현재 시간대의 오프셋을 가져옴 (분 단위)

        // 오프셋을 빼서 UTC 시간으로 변환
        localDate.setMinutes(localDate.getMinutes() - offset);

        const fetchData = async () => {
            const res = await fetch(`${process.env.REACT_APP_URL}/ai-device-issue/update`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    aiDeviceIssueId,
                    issueDate: localDate.toISOString(),
                    issueContent,
                    issueType,
                    aiDeviceId
                }),
            });
            const result = await res.json();
            return result;
        }
        fetchData().then((res) => {
            if (res.status == 200) {
              navigate('/aiComponent/aiDeviceIssue/aiDeviceIssueList')
            }
          });
    }

    return {
        aiDeviceIssueId,
        setAIDeviceIssueId,
        issueDate,
        setIssueDate,
        issueContent,
        setIssueContent,
        issueType,
        setIssueType,
        updateAIDeviceIssueData,
        aiDeviceId,
        setAIDeviceId,
        aiDeviceList,
        setAIDeviceList,
        errorMessage
    }
}

export default useAIDeviceIssueUpdate;