import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
    cameraTypeId: number;
    setCameraTypeId: any;
    modelName: string;
    setModelName: any;
    company: string;
    setCompany: any;
    frameWidthMax: number;
    setFrameWidthMax: any;
    frameHeightMax: number;
    setFrameHeightMax: any;
    connectionType: string;
    setConnectionType: any;
    maxFPS: number;
    setMaxFPS: any;
    price: number;
    setPrice: any;
    diagonalAngle: number;
    setDiagonalAngle: any;
    horizontalAngle: number;
    setHorizontalAngle: any;
    verticalAngle: number;
    setVerticalAngle: any;

    updateCameraTypeData: () => void;
}

const useCameraTypeUpdate = (): hookMember => {
    const params = useParams();

    const navigate = useNavigate();
    const [cameraTypeId, setCameraTypeId] = useState<number>(0);
    const [modelName, setModelName] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [frameWidthMax, setFrameWidthMax] = useState<number>(0);
    const [frameHeightMax, setFrameHeightMax] = useState<number>(0);
    const [connectionType, setConnectionType] = useState<string>("");
    const [maxFPS, setMaxFPS] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [diagonalAngle, setDiagonalAngle] = useState<number>(0);
    const [horizontalAngle, setHorizontalAngle] = useState<number>(0);
    const [verticalAngle, setVerticalAngle] = useState<number>(0); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.cameraTypeId) {
                    const response = await fetch(`${process.env.REACT_APP_URL}/camera-type/one?id=${params.cameraTypeId}`);
                    const jsonData = await response.json();

                    const { cameraTypeId, modelName, company, frameWidthMax, frameHeightMax, connectionType, maxFPS, price, diagonalAngle, horizontalAngle, verticalAngle} = jsonData.data;

                    setCameraTypeId(cameraTypeId);
                    setModelName(modelName);
                    setCompany(company);
                    setFrameWidthMax(frameWidthMax);
                    setFrameHeightMax(frameHeightMax);
                    setConnectionType(connectionType);
                    setMaxFPS(maxFPS);
                    setPrice(price);
                    setDiagonalAngle(diagonalAngle);
                    setHorizontalAngle(horizontalAngle);
                    setVerticalAngle(verticalAngle);
                }
            } catch(error) {
                console.log(error);
            }
        };
        fetchData();
    }, [params.cameraTypeId]);

    const updateCameraTypeData = () => {
        const result = {
            cameraTypeId,
            modelName,
            company,
            frameWidthMax,
            frameHeightMax,
            connectionType,
            maxFPS,
            price,
            diagonalAngle,
            horizontalAngle,
            verticalAngle
        }

        const fetchData = async () => {
            const res = await fetch(`${process.env.REACT_APP_URL}/camera-type/update`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cameraTypeId,
                    modelName,
                    company,
                    frameWidthMax,
                    frameHeightMax,
                    connectionType,
                    maxFPS,
                    price,
                    diagonalAngle,
                    horizontalAngle,
                    verticalAngle
                }),
            });
            const result = res.json();
            return result;
        }
        fetchData().then((res) => {
            if (res.status == 200) {
                navigate('/cameraComponent/cameraType/cameraTypeList')
            }
        });
    }

    return {
        cameraTypeId,
        setCameraTypeId,
        modelName,
        setModelName,
        company,
        setCompany,
        frameWidthMax,
        setFrameWidthMax,
        frameHeightMax,
        setFrameHeightMax,
        connectionType,
        setConnectionType,
        maxFPS,
        setMaxFPS,
        price,
        setPrice,
        diagonalAngle,
        setDiagonalAngle,
        horizontalAngle,
        setHorizontalAngle,
        verticalAngle,
        setVerticalAngle,
        updateCameraTypeData
    }
}

export default useCameraTypeUpdate;