import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  adFieldId: number;
  setAdFieldId: any;
  majorCategory: string;
  setMajorCategory: any;
  middleCategory: string;
  setMiddleCategory: any;
  targetAge: string;
  setTargetAge: any;
  targetMaleRatio: number;
  setTargetMaleRatio: any;
  targetFemaleRatio: number;
  setTargetFemaleRatio: any;
  errorMessage: any;

  updateAdFieldData: () => void;
}

const useAdFieldUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [adFieldId, setAdFieldId] = useState<number>(0);
  const [majorCategory, setMajorCategory] = useState<string>("");
  const [middleCategory, setMiddleCategory] = useState<string>("");
  const [targetAge, setTargetAge] = useState<string>("");
  const [targetMaleRatio, setTargetMaleRatio] = useState<number>(0);
  const [targetFemaleRatio, setTargetFemaleRatio] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.adFieldId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/ad-field/one?id=${params.adFieldId}`
          );
          const jsonData = await response.json();

          const {
            adFieldId,
            majorCategory,
            middleCategory,
            targetAge,
            targetMaleRatio,
            targetFemaleRatio
          } = jsonData.data;

          setAdFieldId(adFieldId);
          setMajorCategory(majorCategory);
          setMiddleCategory(middleCategory);
          setTargetAge(targetAge);
          setTargetMaleRatio(targetMaleRatio);
          setTargetFemaleRatio(targetFemaleRatio);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.AdFieldId]);

  const updateAdFieldData = () => {
    const result = {
        adFieldId,
        majorCategory,
        middleCategory,
        targetAge,
        targetMaleRatio,
        targetFemaleRatio
    };

    if (majorCategory == "") {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/ad-field/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            adFieldId,
            majorCategory,
            middleCategory,
            targetAge,
            targetMaleRatio,
            targetFemaleRatio
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/adComponent/adField/adFieldList");
      }
    });
  };

  return {
    adFieldId,
    setAdFieldId,
    majorCategory,
    setMajorCategory,
    middleCategory,
    setMiddleCategory,
    targetAge,
    setTargetAge,
    targetMaleRatio,
    setTargetMaleRatio,
    targetFemaleRatio,
    setTargetFemaleRatio,
    errorMessage,
    updateAdFieldData,
  };
};

export default useAdFieldUpdate;