import ReactDatePicker, { registerLocale } from "react-datepicker";
import useCsvInquiryList from "./hooks/useCsvInquiryList";
import { useCsvModal } from "./hooks/useCsvModal";
import CsvModal from "./csvModal";
import OvalSpinner from "../../../components/atoms/loading/OvalSpinner";
import { ko } from "date-fns/locale";
import { useState } from "react";

registerLocale("ko", ko);

const CsvInquiryList: React.FC = () => {
  const {
    selectedBusinessType,
    selectedCsvType,
    csvTypeOptions,
    dateRange,
    resetFilters,
    shiftDateRange,
    setDateRange,
    setDateRangeFromToday,
    handleBusinessType,
    handleCsvType,
    handleDownloadCsv,
    handleUploadCsv,
    handleFileChange,
    setSearchQuery,
    searchQuery,
    currentCsvData,
    currentPage,
    totalPages,
    setCurrentPage,
    mediaNumberOptions,
    selectedMediaNumber,
    handleMediaNumbers,
    dateArr,
    fetchFileLine,
    fileLine
  } = useCsvInquiryList();

  const {
    isModalOpen,
    modalContent,
    closeModal,
    openModalWithCsvData,
    isLoading,
    loadMoreData,
  } = useCsvModal();

  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const isDateWithoutCsv = (date: Date) => {
    return dateArr.some(
      (d) => new Date(d).toDateString() === date.toDateString()
    );
  };

  return (
    <div className="inquiry-container">
      {isLoading && <OvalSpinner />}
      <h1>CSV 조회</h1>
      <select
        id="businessType"
        value={selectedBusinessType}
        onChange={handleBusinessType}
      >
        <option value="">비즈니스 타입 선택</option>
        <option value="DalgoD">DalgoD</option>
        <option value="DalgoT">DalgoT</option>
        <option value="BrandDP">BrandDP</option>
        <option value="ModelX">ModelX</option>
      </select>
      <select id="csvType" value={selectedCsvType} onChange={handleCsvType}>
        <option value="">CSV 타입 선택</option>
        {csvTypeOptions.map((csvType, index) => (
          <option key={index} value={csvType}>
            {csvType}
          </option>
        ))}
      </select>
      <select
        id="mediaNumber"
        value={selectedMediaNumber}
        onChange={handleMediaNumbers}
      >
        <option value="">매체 번호 선택</option>
        {mediaNumberOptions.map((mediaNumber, index) => (
          <option key={index} value={mediaNumber}>
            {mediaNumber}
          </option>
        ))}
      </select>
      <input
        className="input-field"
        type="text"
        placeholder="파일명 검색"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <button onClick={resetFilters}>초기화</button>

      <p className="info-message">
        비즈니스 타입, CSV 타입, 매체 번호를 선택한 후 아래 날짜 선택 칸을
        클릭하면 파일의 존재 여부를 확인할 수 있습니다.
      </p>
      <div className="date-range-buttons">
        <button onClick={() => shiftDateRange("left")}>←</button>
        <ReactDatePicker
          selected={dateRange[0]}
          onChange={(update) =>
            setDateRange(update as [Date | null, Date | null])
          }
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          selectsRange
          dateFormat="yyyy/MM/dd"
          isClearable
          className="inquiry-datepicker"
          locale="ko"
          dayClassName={(date) =>
            isDateWithoutCsv(date) ? "has-log-date" : "no-log-date"
          }
        />
        <button onClick={() => shiftDateRange("right")}>→</button>
        <button onClick={() => setDateRangeFromToday(1)}>지난 1일</button>
        <button onClick={() => setDateRangeFromToday(7)}>지난 7일</button>
        <button onClick={() => setDateRangeFromToday(30)}>지난 30일</button>
      </div>

      <div className="file-upload">
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <button onClick={handleUploadCsv}>업로드 확인</button>
      </div>

      <table className="table-row-hover">
        <thead>
          <tr>
            <th>CSV</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {selectedBusinessType && selectedCsvType ? (
            currentCsvData.length > 0 ? (
              currentCsvData.map((csv, index) => (
                <tr
                  key={index}
                  onClick={() => openModalWithCsvData(csv)}
                  onMouseEnter={() => {
                    fetchFileLine(csv.path);
                    setHoveredRow(csv.path);
                  }}
                  onMouseLeave={() => setHoveredRow(null)}
                  className="csv-row"
                  >
                  <td>{csv.name}
                  <div
                      className="file-line-tooltip"
                      style={{
                        display: hoveredRow === csv.path ? "block" : "none",
                      }}
                    >
                      파일 Line 수: {fileLine} 줄
                    </div>
                    </td>
                  <td>
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDownloadCsv(csv.path);
                      }}
                    >
                      다운로드
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={2}
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  파일이 존재하지 않습니다.
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={2} style={{ textAlign: "center", padding: "20px" }}>
                비즈니스 타입과 CSV 타입을 선택해주세요.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination-controls">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          이전
        </button>
        <span>
          {currentPage} / {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          다음
        </button>
      </div>

      <CsvModal
        isOpen={isModalOpen}
        onClose={closeModal}
        content={modalContent}
        isLoading={isLoading}
        loadMoreData={loadMoreData}
      />
    </div>
  );
};

export default CsvInquiryList;
