import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  siteId: number;
  service: string;

  name: string;
  setName: any;
  address: string;
  setAddress: any;
  latitude: number;
  setLatitude: any;
  longitude: number;
  setLongitude: any;
  adminName: string;
  setAdminName: any;
  adminPhoneNumber: string;
  setAdminPhoneNumber: any;
  adminPosition: string;
  setAdminPosition: any;
  dynamicScore: number;
  setDynamicScore: any;
  incomeClass: string;
  setIncomeClass: any;
  monday: string;
  setMonday: any;
  tuesday: string;
  setTuesday: any;
  wednesday: string;
  setWednesday: any;
  thursday: string;
  setThursday: any;
  friday: string;
  setFriday: any;
  saturday: string;
  setSaturday: any;
  sunday: string;
  setSunday: any;
  breakTime: string;
  setBreakTime: any;
  holidayWorking: string;
  setHolidayWorking: any;

  siteFieldId: number;
  setSiteFieldId: any;
  fieldList: any;
  setFieldList: any;

  errorMessage: any;

  updateData: () => void;
}

const useUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();

  const [siteId, setSiteId] = useState<number>(0);
  const [service, setService] = useState<string>("dalgoD");
  const [name, setName] = useState<string>("장소명");
  const [address, setAddress] = useState<string>("주소");
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const [adminName, setAdminName] = useState<string>("담당자 이름");
  const [adminPhoneNumber, setAdminPhoneNumber] =
    useState<string>("담당자 연락처");
  const [adminPosition, setAdminPosition] = useState<string>("담당자 직책");
  const [dynamicScore, setDynamicScore] = useState<number>(0);
  const [incomeClass, setIncomeClass] = useState<string>("");
  const [monday, setMonday] = useState<string>("월요일");
  const [tuesday, setTuesday] = useState<string>("화요일");
  const [wednesday, setWednesday] = useState<string>("수요일");
  const [thursday, setThursday] = useState<string>("목요일");
  const [friday, setFriday] = useState<string>("금요일");
  const [saturday, setSaturday] = useState<string>("토요일");
  const [sunday, setSunday] = useState<string>("일요일");
  const [breakTime, setBreakTime] = useState<string>("점심시간");
  const [holidayWorking, setHolidayWorking] = useState<string>("공휴일 휴무");

  const [siteFieldId, setSiteFieldId] = useState<number>(0);
  const [fieldList, setFieldList] = useState<any[]>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.siteId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/site/one?id=${params.siteId}`
          );
          const scheduleResponse = await fetch(
            `${process.env.REACT_APP_URL}/site-schedule/one?id=${params.siteId}`
          );
          const jsonData = await response.json();
          const scheduleJsonData = await scheduleResponse.json();

          const fieldListResponse = await fetch(
            `${process.env.REACT_APP_URL}/site-field/all`
          );
          const fieldListData = await fieldListResponse.json();

          const {
            siteId,
            name,
            address,
            adminName,
            latitude,
            longitude,
            adminPhoneNumber,
            adminPosition,
            siteFieldId,
            dynamicScore,
          } = jsonData.data;
          const {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            breakTime,
            holidayWorking,
          } = scheduleJsonData.data;

          setFieldList(fieldListData);
          setSiteFieldId(siteFieldId);

          setSiteId(siteId);
          setName(name);
          setAddress(address);
          setAdminName(adminName);
          setLatitude(latitude);
          setLongitude(longitude);
          setAdminPhoneNumber(adminPhoneNumber);
          setAdminPosition(adminPosition);
          setMonday(monday);
          setTuesday(tuesday);
          setWednesday(wednesday);
          setThursday(thursday);
          setFriday(friday);
          setSaturday(saturday);
          setSunday(sunday);
          setBreakTime(breakTime);
          setHolidayWorking(holidayWorking);
          setDynamicScore(dynamicScore);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.siteId]);

  const validateTimeFormat = (time: string): boolean => {
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)-([01]\d|2[0-3]):([0-5]\d)$/;
    return timePattern.test(time);
  };

  const updateData = async () => {
    const result = {
      siteId,
      service,
      name,
      address,
      latitude,
      longitude,
      adminName,
      adminPhoneNumber,
      adminPosition,
      dynamicScore,
      incomeClass,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      breakTime,
      holidayWorking,
      siteFieldId,
    };

    if (name === "" || !siteFieldId) {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const days: { [key: string]: string } = {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      breakTime
    };
    const dayNames: { [key: string]: string } = {
      monday: "월요일",
      tuesday: "화요일",
      wednesday: "수요일",
      thursday: "목요일",
      friday: "금요일",
      saturday: "토요일",
      sunday: "일요일",
      breakTime: "점심시간"
    };

    for (const day in days) {
      if (days.hasOwnProperty(day) && days[day].trim() === "") {
        setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
        return;
      }
    }

    for (const day in days) {
      if (days.hasOwnProperty(day) && !validateTimeFormat(days[day])) {
        if (days[day].trim() === "") continue; // 빈 공백인 경우 넘어감
        if (day === "breakTime" && days[day] === "none") continue;
        if (day !== "breakTime" && days[day] === "closed") continue;
        setErrorMessage(`잘못된 시간 형식: ${dayNames[day]}`);
        return;
      }
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/site/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(result),
      });

      const jsonResponse = await res.json();

      if (res.ok) {
        navigate("/siteComponent/site/list");
      } else {
        setErrorMessage("장소 업데이트에 실패했습니다.");
        console.error("장소 업데이트에 실패했습니다.", jsonResponse);
      }
    } catch (error) {
      setErrorMessage("장소 업데이트 중 오류 발생");
      console.error("장소 업데이트 중 오류 발생:", error);
    }
  };

  return {
    siteId,
    service,
    updateData,
    name,
    setName,
    address,
    setAddress,
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    adminName,
    setAdminName,
    adminPhoneNumber,
    setAdminPhoneNumber,
    adminPosition,
    setAdminPosition,
    dynamicScore,
    setDynamicScore,
    incomeClass,
    setIncomeClass,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thursday,
    setThursday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    sunday,
    setSunday,
    breakTime,
    setBreakTime,
    holidayWorking,
    setHolidayWorking,
    fieldList,
    setFieldList,
    siteFieldId,
    setSiteFieldId,
    errorMessage,
  };
};

export default useUpdate;
