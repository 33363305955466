import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import fetchProfile from "../../api/userProfile";
import sortedData from "../../util/sortedData";
import useInstallReportList from "./hooks/useInstallReportList";

const InstallReportList = () => {
  const { data, loading, currentPage, nextPage, count, handleDeleteClick } =
    useInstallReportList(0);

  const [isFetching, setIsFetching] = useState(false);
  const [sortBy, setSortBy] = useState("installReportId");
  const [sortDirection, setSortDirection] = useState("asc");
  const observer = useRef<IntersectionObserver | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [profile, setProfile] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<{
    [key: string]: Boolean;
  }>({
    "media.name": false,
    workDate: false,
    createdAt: false,
    category: false,
    //추가
  });

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    const profileData = await fetchProfile();
    setProfile(profileData);
  };

  useEffect(() => {
    if (loading) return;

    document.getElementById("scroll-trigger-div")!.style.width =
      document.getElementById("table")!.clientWidth + "px";

    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting) {
        loadMoreData();
      }
    };

    observer.current = new IntersectionObserver(handleObserver, {
      threshold: 1,
    });
    observer.current.observe(document.getElementById("scroll-trigger")!);

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, currentPage, isFetching]);

  const loadMoreData = async () => {
    if (!isFetching && currentPage < Number(count) / 10 - 1) {
      setIsFetching(true);
      await nextPage();
      setIsFetching(false);
    }
  };

  const handleSort = (column: string) => {
    if (sortBy == column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortDirection("asc");
    }
  };

  const arrowIcon = (column: string) => {
    if (sortBy === column) {
      return sortDirection === "asc" ? "⬆︎" : "⬇︎";
    }
    return "";
  };

  //   const downloadExcel = async () => {
  //     alert("다운로드가 진행 중입니다.");
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_URL}/cpv/download-excel`,
  //         {},
  //         {
  //           responseType: "blob",
  //         }
  //       );

  //       // 파일 다운로드를 위한 Blob 생성
  //       const blob = new Blob([response.data], { type: "text/xlsx" });
  //       const url = window.URL.createObjectURL(blob);

  //       // 다운로드 링크 생성 및 클릭
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "cpv_테이블.xlsx");
  //       document.body.appendChild(link);
  //       link.click();

  //       // 생성된 URL 해제
  //       window.URL.revokeObjectURL(url);
  //     } catch (error) {
  //       console.error("Error creating xlsx:", error);
  //     }
  //   };

  // 검색 기능
  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const filteredDataArray = sortedData(data, sortBy, sortDirection)
      .filter((item) => {
        if (categoryFilter === "all") return true;
        return item.category === categoryFilter;
      })
      .filter((item) =>
        item.media && item.media.name
          ? item.media.name.includes(searchTerm)
          : item.actualImageName.includes(searchTerm)
      );
    setSearchResults(filteredDataArray);
  }, [searchTerm, data, sortBy, sortDirection, categoryFilter]);

  const handleCategoryFilterChange = (e: any) => {
    setCategoryFilter(e.target.value);
  };

  const handleReset = () => {
    setSortBy("installReportId");
    setSortDirection("asc");
    setSearchTerm("");
    setCategoryFilter("all");
  };

  const toggleColumnVisibility = (column: string) => {
    setHiddenColumns({
      ...hiddenColumns,
      [column]: !hiddenColumns[column],
    });
  };

  const columnNames: any = {
    "media.name": "매체",
    workDate: "작업 날짜",
    createdAt: "작성 날짜",
    category: "작업 종류",
  };

  const renderTableHeaders = () => {
    return (
      <tr>
        {Object.keys(hiddenColumns).map((column, index) => (
          <th
            key={index}
            style={{
              display: hiddenColumns[column] ? "none" : "table-cell",
            }}
            onClick={() => handleSort(column)}
          >
            {columnNames[column]} {arrowIcon(column)}
          </th>
        ))}
        {profile &&
          (profile.data.userGroup === "admin" ||
            profile.data.userGroup === "manager") && <th></th>}
        {profile &&
          (profile.data.userGroup === "admin" ||
            profile.data.userGroup === "manager") && <th></th>}
      </tr>
    );
  };

  const renderTableRows = () => {
    return searchResults.map((item: any, index: number) => (
      <tr
        key={index}
        style={{
          backgroundColor: hoveredRow === index ? "lightgray" : "inherit",
        }}
        onMouseEnter={() => setHoveredRow(index)}
        onMouseLeave={() => setHoveredRow(null)}
      >
        {Object.keys(hiddenColumns).map((column, columnIndex) => (
          <td
            key={columnIndex}
            style={{
              display: hiddenColumns[column] ? "none" : "table-cell",
            }}
          >
            {column === "media.name"
              ? item.media
                ? item.media.name
                : item.actualImageName
              : item[column]}
          </td>
        ))}
        {profile &&
          (profile.data.userGroup === "admin" ||
            profile.data.userGroup === "manager") && (
            <>
              <td>
                <Link
                  to={
                    item.category === "실사"
                      ? `/installReport/actualImageUpdate/${item.installReportId}`
                      : item.category === "설치"
                      ? `/installReport/installUpdate/${item.installReportId}`
                      : item.category === "점검"
                      ? `/installReport/inspectionUpdate/${item.installReportId}`
                      : item.category === "철거"
                      ? `/installReport/demolishUpdate/${item.installReportId}`
                      : ""
                  }
                >
                  조회
                </Link>
              </td>
              <td>
                <button onClick={() => handleDeleteClick(item.installReportId)}>
                  삭제
                </button>
              </td>
            </>
          )}
      </tr>
    ));
  };

  return (
    <div>
      <h1>설치 문서 리스트</h1>
      {loading ? (
        <p>Loading ...</p>
      ) : (
        <>
          <div>
            {(profile.data.userGroup === "admin" ||
              profile.data.userGroup === "manager") && (
              <>
                <Link to={"/installReport/actualImageCreate"}>
                  <button className="addButton">실사 추가</button>
                </Link>
                <Link to={"/installReport/installCreate"}>
                  <button className="addButton">설치 추가</button>
                </Link>
                <Link to={"/installReport/inspectionCreate"}>
                  <button className="addButton">점검 추가</button>
                </Link>
                <Link to={"/installReport/demolishCreate"}>
                  <button className="addButton">철거 추가</button>
                </Link>
              </>
            )}
            {/* <button className="excelButton" onClick={downloadExcel}>
              엑셀 다운로드
            </button> */}
            <div className="searchInput">
              <span>카테고리</span>
              <select
                value={categoryFilter}
                onChange={handleCategoryFilterChange}
              >
                <option value="all"></option>
                <option value="실사">실사</option>
                <option value="설치">설치</option>
                <option value="점검">점검</option>
                <option value="철거">철거</option>
              </select>
              <input
                type="text"
                placeholder="매체 검색"
                value={searchTerm}
                onChange={handleSearch}
              />
              <button onClick={handleReset}>초기화</button>
            </div>
          </div>
          <div>
            <div>
              {Object.entries(hiddenColumns).map(([column, isVisible]) => (
                <label key={column}>
                  <input
                    type="checkbox"
                    checked={!isVisible}
                    onChange={() => toggleColumnVisibility(column)}
                  />
                  {columnNames[column]}
                </label>
              ))}
            </div>
            <table id="table">
              <thead>{renderTableHeaders()}</thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </div>
          <div id="scroll-trigger-div">
            <div id="scroll-trigger" />
          </div>
        </>
      )}
    </div>
  );
};

export default InstallReportList;
