import useAIDeviceIssueCreate from "./hooks/useAIDeviceIssueCreate";

const AIDeviceIssueCreate = () => {
  const hookMember = useAIDeviceIssueCreate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        {/* <span style ={{color: "blue", marginLeft: '350px'}}>2024-01-01 00:00:00 형태로 입력해주세요.</span> */}
        <div>
          <label>
            이슈 날짜<span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.issueDate}
            placeholder="2024-01-01 00:00:00"
            onChange={(e) => hookMember.setIssueDate(e.target.value)}
          />
        </div>
        <div>
          <label>이슈 내용</label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.issueContent}
            onChange={(e) => hookMember.setIssueContent(e.target.value)}
          />
        </div>
        <div>
          <label>이슈 종류</label>
          <select
            name=""
            id=""
            value={hookMember.issueType}
            onChange={(e) => hookMember.setIssueType(e.target.value)}
          >
            <option value="하드웨어 이슈">하드웨어 이슈</option>
            <option value="네트워크 이슈">네트워크 이슈</option>
          </select>
        </div>
        <div>
          <label>
            AI 기기 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.aiDeviceId}
            onChange={(e) => {
              hookMember.setAIDeviceId(parseInt(e.target.value));
            }}
          >
            <option>선택해 주세요</option>
            {Array.isArray(hookMember.aiDeviceList.data) &&
              hookMember.aiDeviceList.data.map(
                (item: { aiDeviceId: number; label: string }) => (
                  <option key={item.aiDeviceId} value={item.aiDeviceId}>
                    {item.label}
                  </option>
                )
              )}
          </select>
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.createAIDeviceIssue}>저장</button>
      </div>
    </div>
  );
};

export default AIDeviceIssueCreate;
