import useUpdate from "./hooks/useUpdate";

const UpdateMedia = () => {
  const hookMember = useUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <>
      {
        <>
          <div>
            <div className="input-table">
              {hookMember.errorMessage && (
                <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
              )}
              <div>
                <label>서비스</label>
                <div>{hookMember.service}</div>
              </div>
              <div>
                <label>매체번호</label>
                <div>{hookMember.mediaId}</div>
              </div>
              <div>
                <label>매체명</label>
                <div>{hookMember.name}</div>
              </div>
              <div>
                <label>시리얼 번호</label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={hookMember.serialNumber}
                  onChange={(e) => hookMember.setSerialNumber(e.target.value)}
                />
              </div>
              <div>
                <label>밝기</label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={hookMember.luminance}
                  onChange={(e) => hookMember.setLuminance(e.target.value)}
                />
              </div>
              <div>
                <label>설치 방법</label>
                <select
                  name=""
                  id=""
                  value={hookMember.installMethod}
                  onChange={(e) => hookMember.setInstallMethod(e.target.value)}
                >
                  <option value="">선택해 주세요</option>
                  <option value="천장형">천장형</option>
                  <option value="벽부형">벽부형</option>
                  <option value="스탠드형">스탠드형</option>
                  <option value="매립형">매립형</option>
                  <option value="모니터암">모니터 암</option>
                </select>
              </div>
              <div>
                <label>매체 상태</label>
                <select
                  name=""
                  id=""
                  value={hookMember.status}
                  onChange={(e) => hookMember.setStatus(e.target.value)}
                >
                  <option value="시작 전">시작 전</option>
                  <option value="기기 없음">기기 없음</option>
                  <option value="설치 대기">설치 대기</option>
                  <option value="설치 안됨">설치 안됨</option>
                  <option value="네트워크 이슈">네트워크 이슈</option>
                  <option value="기기 이슈">기기 이슈</option>
                  <option value="테스트">테스트</option>
                  <option value="정상">정상</option>
                  <option value="중단">중단</option>
                </select>
              </div>
              <div>
                <label>시야각(도)</label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={hookMember.viewingAngle}
                  onChange={(e) => hookMember.setViewingAngle(e.target.value)}
                />
              </div>
              <div>
                <label>매력도</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.attractiveness}
                  onChange={(e) => hookMember.setAttractiveness(e.target.value)}
                />
              </div>
              <div>
                <label>평균 실적</label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={hookMember.performance}
                  onChange={(e) => hookMember.setPerformance(e.target.value)}
                />
              </div>
              <div>
                <label>스케줄 단위</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.scheduleUnit}
                  onChange={(e) => hookMember.setScheduleUnit(e.target.value)}
                />
              </div>
              <div>
                <label>실내외 구분</label>
                <select
                  name=""
                  id=""
                  value={(hookMember.isIndoor || false).toString()}
                  onChange={(e) =>
                    hookMember.setIsIndoor(e.target.value === "true")
                  }
                >
                  <option
                    value="false"
                    selected={hookMember.isIndoor === false}
                  >
                    실외
                  </option>
                  <option value="true" selected={hookMember.isIndoor === true}>
                    실내
                  </option>
                </select>
              </div>
              <div>
                <label>
                  비즈니스 종류 <span className="required">*</span>
                </label>
                <select
                  name=""
                  id=""
                  value={hookMember.mediaBusinessTypeId}
                  onChange={(e) => {
                    hookMember.setMediaBusinessTypeId(parseInt(e.target.value));
                  }}
                >
                  <option>선택해 주세요</option>
                  {Array.isArray(hookMember.mediaBusinessTypeList.data) &&
                    hookMember.mediaBusinessTypeList.data.map(
                      (item: { businessTypeId: number; business: string }) => (
                        <option
                          key={item.businessTypeId}
                          value={item.businessTypeId}
                        >
                          {item.business}
                        </option>
                      )
                    )}
                </select>
              </div>
              <div>
                <label>
                  장소 <span className="required">*</span>
                </label>
                <select
                  name=""
                  id=""
                  value={hookMember.siteId}
                  onChange={(e) => {
                    hookMember.setSiteId(parseInt(e.target.value));
                  }}
                >
                  <option>선택해 주세요</option>
                  {Array.isArray(hookMember.siteList.data) &&
                    hookMember.siteList.data.map(
                      (item: { siteId: number; name: string }) => (
                        <option key={item.siteId} value={item.siteId}>
                          {item.name}
                        </option>
                      )
                    )}
                </select>
              </div>
              <div>
                <label>매체 기기</label>
                <select
                  name=""
                  id=""
                  value={hookMember.mediaDeviceId}
                  onChange={(e) => {
                    hookMember.setMediaDeviceId(parseInt(e.target.value));
                  }}
                >
                  <option>선택해 주세요</option>
                  {Array.isArray(hookMember.mediaDeviceList.data) &&
                    hookMember.mediaDeviceList.data.map(
                      (item: { mediaDeviceId: number; name: string }) => (
                        <option
                          key={item.mediaDeviceId}
                          value={item.mediaDeviceId}
                        >
                          {item.name}
                        </option>
                      )
                    )}
                </select>
              </div>
              <div>
                <label>AI 기기</label>
                <select
                  name=""
                  id=""
                  value={hookMember.aiDeviceId}
                  onChange={(e) => {
                    hookMember.setAIDeviceId(parseInt(e.target.value));
                  }}
                >
                  <option>선택해 주세요</option>
                  {Array.isArray(hookMember.aiDeviceList.data) &&
                    hookMember.aiDeviceList.data.map(
                      (item: { aiDeviceId: number; label: string }) => (
                        <option key={item.aiDeviceId} value={item.aiDeviceId}>
                          {item.label}
                        </option>
                      )
                    )}
                </select>
              </div>
            </div>
          </div>
          <div className="buttonDiv">
            <button onClick={handleCancel}>취소</button>
            <button onClick={hookMember.updateData}>저장</button>
          </div>
        </>
      }
    </>
  );
};

export default UpdateMedia;
