import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
    aiDeviceId: number;
    setAIDeviceId: any;
    label: string;
    setLabel: any;
    serialNumber: string;
    setSerialNumber: any;
    currentStatus: string;
    setCurrentStatus: any;
    

    aiDeviceTypeId: number;
    setAIDeviceTypeId: any;
    aiDeviceTypeList: any;
    setAIDeviceTypeList: any;
    networkDeviceId: number;
    setNetworkDeviceId: any;
    networkDeviceList: any;
    setNetworkDeviceList: any;

    errorMessage: any;

    updateAIDeviceData: () => void;
 }

const useAIDeviceUpdate = (): hookMember => {
    const params = useParams();

    const navigate = useNavigate();
    const [aiDeviceId, setAIDeviceId] = useState<number>(0);
    const [label, setLabel] = useState<string>("");
    const [serialNumber, setSerialNumber] = useState<string>("");
    const [currentStatus, setCurrentStatus] = useState<string>("");

    const [aiDeviceTypeId, setAIDeviceTypeId] = useState<number>(0);
    const [aiDeviceTypeList, setAIDeviceTypeList] = useState([]);
    const [networkDeviceId, setNetworkDeviceId] = useState<number>(0);
  const [networkDeviceList, setNetworkDeviceList] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.aiDeviceId) {
                    const response = await fetch(`${process.env.REACT_APP_URL}/ai-device/one?id=${params.aiDeviceId}`);
                    const jsonData = await response.json();

                    const aiDeviceTypeListResponse = await fetch(`${process.env.REACT_APP_URL}/ai-device-type/all`);
                    const aiDeviceTypeListData = await aiDeviceTypeListResponse.json();

                    const networkDeviceResponse = await fetch(
                        `${process.env.REACT_APP_URL}/network-device/all`
                    );
                    const networkDeviceData = await networkDeviceResponse.json();

                    const { aiDeviceId, label, serialNumber, currentStatus, aiDeviceTypeId, networkDeviceId} = jsonData.data;

                    setAIDeviceTypeId(aiDeviceTypeId);
                    setAIDeviceTypeList(aiDeviceTypeListData);
                    setNetworkDeviceList(networkDeviceData);
                    setNetworkDeviceId(networkDeviceId);

                    setAIDeviceId(aiDeviceId);
                    setLabel(label);
                    setSerialNumber(serialNumber);
                    setCurrentStatus(currentStatus);

                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [params.aiDeviceId])

    const updateAIDeviceData = () => {
        const result = {
            aiDeviceId,
            label,
            serialNumber,
            currentStatus,
            aiDeviceTypeId,
            networkDeviceId
        }

        if (label === "" || !aiDeviceTypeId || !networkDeviceId) {
            setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.');
            return;
        }

        const fetchData = async () => {
            const res = await fetch(`${process.env.REACT_APP_URL}/ai-device/update`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    aiDeviceId,
                    label,
                    serialNumber,
                    currentStatus,
                    aiDeviceTypeId,
                    networkDeviceId
                }),
            });
            const result = res.json();
            return result;
        }
        fetchData().then((res) => {
            if (res.status == 200) {
              navigate('/aiComponent/aiDevice/aiDeviceList')
            } else {
                if(res.statusCode == 500){
                    setErrorMessage('네트워크 기기가 중복된 AI 기기가 존재합니다.');
                }
            }
          });
    }

    return {
        aiDeviceId,
        setAIDeviceId,
        label,
        setLabel,
        serialNumber,
        setSerialNumber,
        currentStatus,
        setCurrentStatus,
        updateAIDeviceData,
        aiDeviceTypeId,
        setAIDeviceTypeId,
        aiDeviceTypeList,
        setAIDeviceTypeList,
        networkDeviceId,
        setNetworkDeviceId,
        networkDeviceList,
        setNetworkDeviceList,
        errorMessage
    }
}

export default useAIDeviceUpdate;