import useSiteFieldCreate from "./hooks/useSiteFieldCreate";

const SiteFieldCreate = () => {
  const hookMember = useSiteFieldCreate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>
            중분류<span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.middleCategory}
            onChange={(e) => hookMember.setMiddleCategory(e.target.value)}
          />
        </div>
        <div>
          <label>
            대분류<span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.majorCategory}
            onChange={(e) => hookMember.setMajorCategory(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.createSiteField}>저장</button>
      </div>
    </div>
  );
};

export default SiteFieldCreate;
