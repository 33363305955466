/** 2024.08.05 작성 */

import React, { useEffect, useState } from "react";
import "../css/contextMenu.scss";

export const ContextMenu = ({
  isFile,
  onUploadClick,
  onCreateFolderClick,
  onDownloadClick,
  onClose,
  position,
}: any) => {
  return position && !isFile ? (
    <>
      <div className="context-menu-overlay" onClick={onClose}></div>
      <div
        className="context-menu"
        style={{ top: position?.y, left: position?.x }}
      >
        <ul>
          <li onClick={onUploadClick}>업로드</li>
          {/* <li onClick={onCreateFolderClick}>폴더 생성</li> */}
        </ul>
      </div>
    </>
  ) : (
    <div></div>
  );
};
