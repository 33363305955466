import { useEffect, useState } from "react";

interface hookMember {
  data: ad[];
  loading: boolean;
  today: string;
}

interface ad {
  authNumber: number;
  name: string;
  date: string;
  status: boolean;
  list: any[];
}

const useAdDashboard = (): hookMember => {
  const [data, setData] = useState<ad[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<number>(new Date().getTime());
  const today = `${new Date().getFullYear()}-${
    new Date().getMonth() > 8
      ? new Date().getMonth() + 1
      : "0" + (new Date().getMonth() + 1)
  }-${
    new Date().getDate() > 9 ? new Date().getDate() : "0" + new Date().getDate()
  }`;

  const fetchData = async (page: any, loading_page = true) => {
    if (!loading) return;

    if (loading_page) {
      setLoading(true);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/dashboard/adList?page=${page}`
      );
      const jsonData = await response.json();

      if (jsonData.status == 200) {
        setLoading(false);
        setData(jsonData.data);
      } else {
        setLoading(false);
        setData([]);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      setData([]);
    }
  };

  // 5분마다 새로고침
  useEffect(() => {
    fetchData(0);
    setInterval(() => {
      const timeDif = Math.floor((new Date().getTime() - reload) / 1000);
      if (timeDif % 300 === 0) {
        fetchData(0, false);
      }
    }, 1000);
  }, []);

  return { data, loading, today };
};

export default useAdDashboard;
