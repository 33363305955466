import useNetworkDeviceTypeCreate from "./hooks/useNetworkDeviceTypeCreate";

const NetworkDeviceTypeCreate = () => {
  const hookMember = useNetworkDeviceTypeCreate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>
            네트워크 기기명<span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.name}
            onChange={(e) => hookMember.setName(e.target.value)}
          />
        </div>
        <div>
          <label>
            연결 방식 <span className="required">*</span>
          </label>
          <select
            name=""
            id=""
            value={hookMember.connectionType}
            onChange={(e) => hookMember.setConnectionType(e.target.value)}
          >
            <option value="">선택해 주세요</option>
            <option value="라우터">라우터</option>
            <option value="와이파이">와이파이</option>
            <option value="로컬">로컬</option>
          </select>
        </div>
        <div>
          <label>
            기기 가격
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.devicePrice}
            onChange={(e) => hookMember.setDevicePrice(e.target.value)}
          />
        </div>
        <div>
          <label>
            월 요금
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.monthlyFee}
            onChange={(e) => hookMember.setMonthlyFee(e.target.value)}
          />
        </div>
        <div>
          <label>
            라벨 Prefix
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.labelPrefix}
            onChange={(e) => hookMember.setLabelPrefix(e.target.value)}
          />
        </div>
        <div>
          <label>
            회사명
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.company}
            onChange={(e) => hookMember.setCompany(e.target.value)}
          />
        </div>
        <div>
          <label>
            모델명
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.modelName}
            onChange={(e) => hookMember.setModelName(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.createNetworkDeviceType}>저장</button>
      </div>
    </div>
  );
};

export default NetworkDeviceTypeCreate;
