import useAdDashboard from "./hooks/useAdDashboard";
import "./css/adDashboard.scss";

const AdDashboard: any = () => {
  const hookMember = useAdDashboard();

  return (
    <div id="ad-dashboard-table">
      <h1>광고 다운로드 관제</h1>
      {!hookMember.loading ? (
        <div>
          <table>
            <thead>
              <tr>
                <th>매체 이름</th>
                <th>고유 번호</th>
                <th>날짜</th>
                <th>매체 상태</th>
                <th>광고 수</th>
                <th>광고 정보</th>
              </tr>
            </thead>
            <tbody>
              {hookMember.data.length === 0 ? (
                <tr
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <td>해당 매체가 없습니다.</td>
                </tr>
              ) : (
                hookMember.data.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.authNumber}</td>
                      <td
                        style={{
                          backgroundColor:
                            item.date == hookMember.today ? "" : "pink",
                        }}>
                        {item.date}
                      </td>
                      <td
                        style={{
                          backgroundColor: item.status == "정상" ? "" : "pink",
                        }}>
                        {item.status}
                      </td>
                      <td>
                        {item.list[1].length} /{" "}
                        {item.list[0].length + item.list[1].length}
                      </td>
                      <td>
                        <div className="ad_box">
                          {item.list[0].map((ad: any, index2: number) => {
                            return (
                              <div
                                className="ad ad_0 hovertext"
                                key={index2}
                                data-hover={ad.name}>
                                {ad.id}
                              </div>
                            );
                          })}
                          {item.list[1].map((ad: any, index2: number) => {
                            return (
                              <div
                                className="ad ad_1 hovertext"
                                key={index2}
                                data-hover={ad.name}>
                                {ad.id}
                              </div>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <p>Loading ...</p>
        </div>
      )}
    </div>
  );
};

export default AdDashboard;
