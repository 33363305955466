import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
  mediaId: string;
  setMediaId: any;
  data: any;
  loading: boolean;
  inputData: any;
  changeInput: (n: number, value: number) => void;
  updateData: () => void;
}

const useCreate = (): hookMember => {
  const navigate = useNavigate();
  const [mediaId, setMediaId] = useState<string>("");
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState<any>([0, 0, 0, 0]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/media/all`);
      const jsonData = await response.json();
      setData(jsonData.data);
      setLoading(false);
    } catch (error) {
      setData({});
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const changeInput = (n: number, value: number) => {
    var a = JSON.parse(JSON.stringify(inputData));
    a[n] = value;
    setInputData(a);
  };

  const updateData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/media-point/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mediaId: Number(mediaId),
            point1_x: inputData[0],
            point1_y: inputData[1],
            point2_x: inputData[2],
            point2_y: inputData[3],
          }),
        }
      );

      const result = await res.json();

      if (result.status === 200) {
        navigate("/mediaComponent/mediaPoint/list");
      } else {
        alert(result.message);
      }
    } catch (err) {
      console.log(err);
      alert("매체 좌표 생성에 실패하였습니다.");
    }
  };

  return {
    mediaId,
    setMediaId,
    data,
    loading,
    inputData,
    changeInput,
    updateData,
  };
};

export default useCreate;
