import React from 'react';
import './css/modal.scss'; // 모달 스타일을 위한 CSS 파일

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    content: React.ReactNode;
    options?: { value: string; label: string }[]; 
  }
  
  const LogModal: React.FC<ModalProps> = ({ isOpen, onClose, content, options }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          {content}
        </div>
      </div>
    );
  };
  
  export default LogModal;
