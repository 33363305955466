import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    name: string;
    setName: any;
    type: string;
    setType: any;
    company: string;
    setCompany: any;
    fp32: string;
    setFp32: any;
    fp16: string;
    setFp16: any;
    int8: string;
    setInt8: any;

    errorMessage: any;

    createAiModule: () => void;
}

const useAiModuleCreate = (): hookMember => {
    const navigate = useNavigate();

    const [name, setName] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [fp32, setFp32] = useState<string>("");
    const [fp16, setFp16] = useState<string>("");
    const [int8, setInt8] = useState<string>("");

    const [errorMessage, setErrorMessage] = useState('');

    const createAiModule = async () => {
        try {
            if (!name) {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.')
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/ai-module/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name,
                        type,
                        company,
                        fp32,
                        fp16,
                        int8
                    })
                })
                if(response.ok) {
                    navigate('/aiComponent/aiModule/aiModuleList');
                }
            }
        } catch (error) {
            console.error('AI 모듈 생성 중 오류 발생:', error);
        }
    }
    return {
        name,
        setName,
        type,
        setType,
        company,
        setCompany,
        fp32,
        setFp32,
        fp16,
        setFp16,
        int8,
        setInt8,
        createAiModule,
        errorMessage
    }
}

export default useAiModuleCreate;