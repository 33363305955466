import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
    cameraId: number;
    setCameraId: any;
    label: string;
    setLabel: any;
    currentFrameWidth: number;
    setCurrentFrameWidth: any;
    currentFrameHeight: number;
    setCurrentFrameHeight: any;
    currentStatus: string;
    setCurrentStatus: any;
    currentFPS: number;
    setCurrentFPS: any;

    aiDeviceId: number;
    setAIDeviceId: any;
    aiDeviceList: any;
    setAIDeviceList: any;

    cameraTypeId: number;
    setCameraTypeId: any;
    cameraTypeList: any;
    setCameraTypeList: any;

    errorMessage: any;

    updateCameraData: () => void;
}

const useCameraUpdate = (): hookMember => {
    const params = useParams();

    const navigate = useNavigate();
    const [cameraId, setCameraId] = useState<number>(0);
    const [label, setLabel] = useState<string>("");
    const [currentFrameWidth, setCurrentFrameWidth] = useState<number>(0);
    const [currentFrameHeight, setCurrentFrameHeight] = useState<number>(0);
    const [currentStatus, setCurrentStatus] = useState<string>("");
    const [currentFPS, setCurrentFPS] = useState<number>(0);

    const [errorMessage, setErrorMessage] = useState('');

    const [aiDeviceId, setAIDeviceId] = useState<number>(0);
    const [aiDeviceList, setAIDeviceList] = useState([]);
    const [cameraTypeId, setCameraTypeId] = useState<number>(0);
    const [cameraTypeList, setCameraTypeList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.cameraId) {
                    const response = await fetch(`${process.env.REACT_APP_URL}/camera/one?id=${params.cameraId}`);
                    const jsonData = await response.json();

                    const aiDeviceListResponse = await fetch(`${process.env.REACT_APP_URL}/ai-device/all`);
                    const aiDeviceListData = await aiDeviceListResponse.json();

                    const cameraTypeListResponse = await fetch(`${process.env.REACT_APP_URL}/camera-type/all`);
                    const cameraTypeListData = await cameraTypeListResponse.json();

                    const { cameraId, label, currentFrameWidth, currentFrameHeight, currentStatus, currentFPS, aiDeviceId, cameraTypeId } = jsonData.data;

                    setAIDeviceId(aiDeviceId);
                    setAIDeviceList(aiDeviceListData);
                    setCameraTypeId(cameraTypeId);
                    setCameraTypeList(cameraTypeListData);

                    setCameraId(cameraId);
                    setLabel(label);
                    setCurrentFrameWidth(currentFrameWidth);
                    setCurrentFrameHeight(currentFrameHeight);
                    setCurrentStatus(currentStatus);
                    setCurrentFPS(currentFPS);
                }
            } catch(error) {
                console.log(error);
            }
        };
        fetchData();
    }, [params.cameraId]);

    const updateCameraData = () => {
        const result = {
            cameraId,
            label,
            currentFrameWidth,
            currentFrameHeight,
            currentStatus,
            currentFPS,
            aiDeviceId,
            cameraTypeId
        }

        if (label === "" || !aiDeviceId || !cameraTypeId) {
            setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.');
            return;
        }

        const fetchData = async () => {
            const res = await fetch(`${process.env.REACT_APP_URL}/camera/update`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cameraId,
                    label,
                    currentFrameWidth,
                    currentFrameHeight,
                    currentStatus,
                    currentFPS,
                    aiDeviceId,
                    cameraTypeId
                }),
            });
            const result = res.json();
            return result;
        }
        fetchData().then((res) => {
            if (res.status == 200) {
                navigate('/cameraComponent/camera/cameraList')
            }
        });
    }

    return {
        cameraId,
        setCameraId,
        label,
        setLabel,
        currentFrameWidth,
        setCurrentFrameWidth,
        currentFrameHeight,
        setCurrentFrameHeight,
        currentStatus,
        setCurrentStatus,
        currentFPS,
        setCurrentFPS,
        updateCameraData,
        aiDeviceId,
        setAIDeviceId,
        aiDeviceList,
        setAIDeviceList,
        cameraTypeId,
        setCameraTypeId,
        cameraTypeList,
        setCameraTypeList,
        errorMessage
    }
}

export default useCameraUpdate;