import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    majorCategory: string;
    setMajorCategory: any;
    middleCategory: string;
    setMiddleCategory: any;
    targetAge: string;
    setTargetAge: any;
    targetMaleRatio: number;
    setTargetMaleRatio: any;
    targetFemaleRatio: number;
    setTargetFemaleRatio: any;
    errorMessage: any;

    createAdField: () => void;
}

const useAdFieldCreate = (): hookMember => {
    const navigate = useNavigate();

    const [majorCategory, setMajorCategory] = useState<string>("");
    const [middleCategory, setMiddleCategory] = useState<string>("");
    const [targetAge, setTargetAge] = useState<string>("");
    const [targetMaleRatio, setTargetMaleRatio] = useState<number>(0);
    const [targetFemaleRatio, setTargetFemaleRatio] = useState<number>(0);

    const [errorMessage, setErrorMessage] = useState('');

    const createAdField = async () => {
        try {
            if (majorCategory === "") {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.')
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/ad-field/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        majorCategory,
                        middleCategory,
                        targetAge,
                        targetMaleRatio,
                        targetFemaleRatio
                    })
                })
                if(response.ok) {
                    navigate('/adComponent/adField/adFieldList');
                }
            }
        } catch (error) {
            console.error('광고 분류 생성 중 오류 발생:', error);
        }
    }
    return {
        majorCategory,
        setMajorCategory,
        middleCategory,
        setMiddleCategory,
        targetAge,
        setTargetAge,
        targetMaleRatio,
        setTargetMaleRatio,
        targetFemaleRatio,
        setTargetFemaleRatio,
        createAdField,
        errorMessage
    }
}

export default useAdFieldCreate;