import React, { useEffect, useRef } from 'react';
import '../logInquiry/css/modal.scss'; // 모달 스타일을 위한 CSS 파일

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  content: React.ReactNode;
  isLoading: boolean;
  loadMoreData: () => void; // 추가 데이터 로드 함수
}

const CsvModal: React.FC<ModalProps> = ({ isOpen, onClose, content, isLoading, loadMoreData }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (modalRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = modalRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
        loadMoreData(); // 추가 데이터 로드
      }
    }
  };

  useEffect(() => {
    const modalDiv = modalRef.current;
    if (modalDiv) {
      modalDiv.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (modalDiv) {
        modalDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoading]);

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content" ref={modalRef}>
        <div>{content}</div>
        {isLoading && <p>Loading more data...</p>}
      </div>
    </div>
  );
};

export default CsvModal;
