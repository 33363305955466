import { useEffect, useState } from "react";

enum AIDeviceIssueProperties {
  ISSUE_DATE = "모델명",
  "aiDevice.label" = "업체",
  ISSUE_TYPE = "가격",
  ISSUE_CONTENT = "라벨 Prefix",
}

const useAIDeviceIssueList = (initialPage: number) => {
  const transformData = (
    rawData: any[]
  ): Record<AIDeviceIssueProperties, any>[] => {
    return rawData.map((item) => ({
      [AIDeviceIssueProperties.ISSUE_DATE]: item.issueDate,
      [AIDeviceIssueProperties["aiDevice.label"]]: item.aiDevice?.label,
      [AIDeviceIssueProperties.ISSUE_TYPE]: item.issueType,
      [AIDeviceIssueProperties.ISSUE_CONTENT]: item.issueContent,
    }));
  };

  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (aiDeviceIssueId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/ai-device-issue/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ aiDeviceIssueId: aiDeviceIssueId }),
      })
        .then((response) => {
          if (response.ok) {
            window.location.reload();
          } else {
            alert("삭제에 실패하였습니다.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("삭제에 실패하였습니다.");
        });
    }
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/ai-device-issue/list?page=${page}`
      );
      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/ai-device-issue/list`
      );
      const countJsonData = await countResponse.json();
      const totalDataCount = countJsonData.data.count;
      setCount(countJsonData.data.count);

      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
    handleDeleteClick,
    isFetchingComplete,
  };
};

export default useAIDeviceIssueList;
