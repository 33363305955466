import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

interface hookMember {
  data: any;
  loading: boolean;
  inputData: any;
  changeInput: (n: number, value: number) => void;
  updateData: () => void;
}

const useUpdate = (): hookMember => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState<any>([0, 0, 0, 0]);

  const fetchData = async (media: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/media-point/detail?media=${media}`
      );
      const jsonData = await response.json();
      setData(jsonData.data);
      setInputData([
        jsonData.data.point1_x,
        jsonData.data.point1_y,
        jsonData.data.point2_x,
        jsonData.data.point2_y,
      ]);
      setLoading(false);
    } catch (error) {
      setData({});
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (params.mediaId) {
      fetchData(params.mediaId);
    }
  }, [params]);

  const changeInput = (n: number, value: number) => {
    var a = JSON.parse(JSON.stringify(inputData));
    a[n] = value;
    setInputData(a);
  };

  const updateData = async () => {
    const res = await fetch(`${process.env.REACT_APP_URL}/media-point/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: Number(params.mediaId),
        point1_x: inputData[0],
        point1_y: inputData[1],
        point2_x: inputData[2],
        point2_y: inputData[3],
      }),
    });

    navigate("/mediaComponent/mediaPoint/list");
  };

  return {
    data,
    loading,
    inputData,
    changeInput,
    updateData,
  };
};

export default useUpdate;
