import { useEffect, useState } from "react";

enum SiteProperties {
  NAME = "장소명",
  ADDRESS = "주소",
  LATITUDE = "위도",
  LONGITUDE = "경도",
  ADMIN_NAME = "담당자명",
  ADMIN_PHONE_NUMBER = "담당자 연락처",
  ADMIN_POSITION = "담당자 직책",
  DYNAMIC_SCORE = "동적 특성",
  INCOME_CLASS = "소득층",
  "siteField.middleCategory" = "장소분류",
  "siteWorkSchedule.monday" = "월요일",
  "siteWorkSchedule.tuesday" = "화요일",
  "siteWorkSchedule.wednesday" = "수요일",
  "siteWorkSchedule.thursday" = "목요일",
  "siteWorkSchedule.friday" = "금요일",
  "siteWorkSchedule.saturday" = "토요일",
  "siteWorkSchedule.sunday" = "일요일",
  "siteWorkSchedule.breakTime" = "점심시간	",
  "siteWorkSchedule.holidayWorking" = "공휴일 휴무",
}

const useList = (initialPage: number) => {
  const transformData = (rawData: any[]): Record<SiteProperties, any>[] => {
    return rawData.map((item) => ({
      [SiteProperties.NAME]: item.name,
      [SiteProperties.ADDRESS]: item.address,
      [SiteProperties.LATITUDE]: item.latitude,
      [SiteProperties.LONGITUDE]: item.longitude,
      [SiteProperties.ADMIN_NAME]: item.adminName,
      [SiteProperties.ADMIN_PHONE_NUMBER]: item.adminPhoneNumber,
      [SiteProperties.ADMIN_POSITION]: item.adminPosition,
      [SiteProperties.DYNAMIC_SCORE]: item.dynamicScore,
      [SiteProperties.INCOME_CLASS]: item.incomeClass,
      [SiteProperties["siteField.middleCategory"]]:
        item.siteField?.middleCategory,
      [SiteProperties["siteWorkSchedule.monday"]]:
        item.siteWorkSchedule?.monday,
      [SiteProperties["siteWorkSchedule.tuesday"]]:
        item.siteWorkSchedule?.tuesday,
      [SiteProperties["siteWorkSchedule.wednesday"]]:
        item.siteWorkSchedule?.wednesday,
      [SiteProperties["siteWorkSchedule.thursday"]]:
        item.siteWorkSchedule?.thursday,
      [SiteProperties["siteWorkSchedule.friday"]]:
        item.siteWorkSchedule?.friday,
      [SiteProperties["siteWorkSchedule.saturday"]]:
        item.siteWorkSchedule?.saturday,
      [SiteProperties["siteWorkSchedule.sunday"]]:
        item.siteWorkSchedule?.sunday,
      [SiteProperties["siteWorkSchedule.breakTime"]]:
        item.siteWorkSchedule?.breakTime,
      [SiteProperties["siteWorkSchedule.holidayWorking"]]:
        item.siteWorkSchedule?.holidayWorking,
    }));
  };
  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/site/list?page=${page}`
      );
      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/site/list`
      );
      const countJsonData = await countResponse.json();

      const totalDataCount = countJsonData.data.count;
      setCount(totalDataCount);
      // 모든 데이터를 가져온 경우 fetch 종료
      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  return {
    data,
    loading,
    currentPage,
    nextPage,
    columns,
    tableData,
    setTableData,
    count,
    isFetchingComplete,
  };
};

export default useList;
