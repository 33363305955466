import axios from "axios";
import { useEffect, useState } from "react";

enum AdCorpFieldProperties {
  SECTION = "섹션",
  INDUSTRY_GROUP = "산업 그룹",
  INDUSTRY = "산업",
  SUB_INDUSTRY = "세부 산업",
}

const useAdCorpFieldList = (initialPage: number) => {
  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const transformData = (
    rawData: any[]
  ): Record<AdCorpFieldProperties, any>[] => {
    return rawData.map((item) => ({
      [AdCorpFieldProperties.SECTION]: item.section,
      [AdCorpFieldProperties.INDUSTRY_GROUP]: item.industryGroup,
      [AdCorpFieldProperties.INDUSTRY]: item.industry,
      [AdCorpFieldProperties.SUB_INDUSTRY]: item.subIndustry,
    }));
  };
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (adCorpFieldId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/ad-corp-field/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ adCorpFieldId: adCorpFieldId }),
      })
        .then(async (response) => {
          if ((await response.json()) === 1451) {
            // 키 참조 에러처리
            alert("삭제에 실패했습니다.");
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          alert("삭제에 실패하였습니다.");
        });
    }
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/ad-corp-field/list?page=${page}`
      );

      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);

      // 원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      // 새로운 데이터를 가져왔을 때만 컬럼 설정
      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      // 전체 데이터 개수 확인
      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/ad-corp-field/list`
      );

      if (!countResponse.ok) {
        throw new Error("Network request failed");
      }

      const countJsonData = await countResponse.json();

      const totalDataCount = countJsonData.data.count;
      setCount(totalDataCount);
      // 모든 데이터를 가져온 경우 fetch 종료
      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
    handleDeleteClick,
    isFetchingComplete,
  };
};

export default useAdCorpFieldList;
