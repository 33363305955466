import { useEffect, useState } from "react";

enum AdVideoInfoProperties {
  FRAME_WIDTH = "동영상 가로 해상도",
  FRAME_HEIGHT = "	동영상 세로 해상도",
  VIDEO_FORMAT = "동영상 포맷",
  RATIO = "동영상 비율	",
  CATEGORY = "카테고리",
  FOCUS_COLOR = "핵심 색상	",
  KEYWORD = "키워드	",
  VIBE = "분위기",
  VOLUME = "동영상 용량",
  VIDEO_FRAME_COUNT = "총 프레임 수",
  VIDEO_PLAY_TIME = "동영상 길이",
  CHANGED_COUNT = "동적 특성	",
}

const useAdVideoInfoList = (initialPage: number) => {
  const transformData = (
    rawData: any[]
  ): Record<AdVideoInfoProperties, any>[] => {
    return rawData.map((item) => ({
      [AdVideoInfoProperties.FRAME_WIDTH]: item.frameWidth,
      [AdVideoInfoProperties.FRAME_HEIGHT]: item.frameHeight,
      [AdVideoInfoProperties.VIDEO_FORMAT]: item.videoFormat,
      [AdVideoInfoProperties.RATIO]: item.ratio,
      [AdVideoInfoProperties.CATEGORY]: item.category,
      [AdVideoInfoProperties.FOCUS_COLOR]: item.focusColor,
      [AdVideoInfoProperties.KEYWORD]: item.keyword,
      [AdVideoInfoProperties.VIBE]: item.vibe,
      [AdVideoInfoProperties.VOLUME]: item.volume,
      [AdVideoInfoProperties.VIDEO_FRAME_COUNT]: item.videoFrameCount,
      [AdVideoInfoProperties.VIDEO_PLAY_TIME]: item.videoPlayTime,
      [AdVideoInfoProperties.CHANGED_COUNT]: item.changedCount,
    }));
  };
  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (adVideoInfoId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/ad-video-info/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ adVideoInfoId: adVideoInfoId }),
      })
        .then((response) => {
          if (response.ok) {
            window.location.reload();
          } else {
            alert("삭제에 실패하였습니다.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("삭제에 실패하였습니다.");
        });
    }
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/ad-video-info/list?page=${page}`
      );

      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/ad-video-info/list`
      );

      if (!countResponse.ok) {
        throw new Error("Network request failed");
      }

      const countJsonData = await countResponse.json();

      const totalDataCount = countJsonData.data.count;
      setCount(countJsonData.data.count);

      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
    handleDeleteClick,
    isFetchingComplete,
  };
};

export default useAdVideoInfoList;
