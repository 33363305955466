import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
  middleCategory: string;
  setMiddleCategory: any;
  majorCategory: string;
  setMajorCategory: any;

  errorMessage: any;
  createSiteField: () => void;
}

const useSiteFieldCreate = (): hookMember => {
  const navigate = useNavigate();

  const [middleCategory, setMiddleCategory] = useState<string>("");
  const [majorCategory, setMajorCategory] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState("");

  const createSiteField = async () => {
    try {
      if (middleCategory === "" || majorCategory === "") {
        setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/site-field/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              middleCategory,
              majorCategory,
            }),
          }
        );
        if (response.ok) {
          navigate("/siteComponent/siteField/siteFieldList");
        } else {
          console.error("카메라 생성에 실패했습니다.");
        }
      }
    } catch (error) {
      console.error("장소 분류 생성 중 오류 발생:", error);
    }
  };

  return {
    middleCategory,
    setMiddleCategory,
    majorCategory,
    setMajorCategory,
    errorMessage,
    createSiteField,
  };
};

export default useSiteFieldCreate;
