import useScheduleDashboard from "./hooks/useScheduleDetailDashboard";
import "./css/scheduleDetailDashboard.scss";
import SchedulerComponents from "../../components/layouts/scheduler/schedulerComponent";
import { Link } from "react-router-dom";

const ScheduleDetailDashboard: any = () => {
  const hookMember = useScheduleDashboard();

  const category_color: any = {
    ACCOUNT: "#eed4cc",
    MEDIA_PR: "#cceedd",
    PUBLIC: "#bbccdd",
    ADDD: "#eeeecc",
    DUMMY: "#ccbbdd",
  };

  const adList: any = {};

  if (hookMember.data.schedule) {
    hookMember.data.schedule.map((item: any) => {
      item?.adList.map((adId: any) => {
        var category = hookMember.data.ads[adId]?.category;
        var name = hookMember.data.ads[adId]?.name;

        if (!hookMember.data.ads[adId]) {
          return;
        }

        if (!adList[adId]) {
          adList[adId] = {
            name,
            category,
            time: 0,
            count: 0,
          };
        }

        adList[adId].count++;
        adList[adId].time += hookMember.data.ads[adId]?.playSecond;
      });
    });
  }

  console.log(adList);

  return (
    <div id="schedule-detail-table">
      <Link to={"/dashboard/schedule"}>
        <div>목록</div>
      </Link>
      <h1>스케줄러 관제 - 상세</h1>
      {!hookMember.loading ? (
        <>
          <div id="schedule-detail-header">
            <div>
              <div style={{ display: "flex", marginBottom: "5px" }}>
                <select
                  value={hookMember.mediaId}
                  onChange={(e) => {
                    hookMember.changeMedia(e.target.value);
                  }}>
                  {hookMember.data.media.map((item: any, index: number) => {
                    return (
                      <option value={item.authNumber}>
                        {item.authNumber}){item.name}
                      </option>
                    );
                  })}
                </select>
                <div
                  style={{
                    marginLeft: "8px",
                    fontSize: "16px",
                    alignContent: "center",
                  }}>
                  운영시간 : {hookMember.data.site_schedule}
                </div>
              </div>
              <div>
                <button onClick={() => hookMember.dateButton(-1)}>{"<"}</button>
                <input
                  type="date"
                  value={hookMember.input}
                  onChange={(e) => {
                    // hookMember.setInput(e.target.value);
                    hookMember.select(e.target.value);
                  }}
                />
                <button onClick={() => hookMember.dateButton(1)}>{">"}</button>
                {/* <button onClick={() => hookMember.select()}>이동</button> */}
              </div>
            </div>
            <div className="color">
              {Object.entries(category_color).map(
                (item: any, index: number) => {
                  return (
                    <>
                      <p>{item[0]}</p>
                      <div style={{ backgroundColor: item[1] }}></div>
                    </>
                  );
                }
              )}
            </div>
          </div>
          <div className="Schedule">
            {hookMember.data.schedule.length > 0 ? (
              hookMember.data.schedule.map((item: any, index: number) => {
                return (
                  <SchedulerComponents
                    data={item}
                    ads={hookMember.data.ads}
                    key={index}></SchedulerComponents>
                );
              })
            ) : (
              <div>스케줄러가 없습니다.</div>
            )}
            <div className="scheduler-component">
              <table>
                <thead>
                  <tr>
                    <th colSpan={3} style={{ fontSize: "20px" }}>
                      전체
                    </th>
                  </tr>
                  <tr>
                    <td>ID</td>
                    <td>광고명</td>
                    <td>시간(횟수)</td>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(adList).map((item: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          backgroundColor: category_color[item[1].category],
                        }}>
                        <td>{item[0]}</td>
                        <td>{item[1].name}</td>
                        <td>
                          {timeText(item[1].time)} ({item[1].count})
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div>
          <p>Loading ...</p>
        </div>
      )}
    </div>
  );
};

const timeText = (second: number) => {
  if (second < 60) {
    return second + "초";
  } else {
    return `${Math.floor(second / 60)}분 ${second % 60}초`;
  }
};

export default ScheduleDetailDashboard;
