import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    section: string;
    setSection: any;
    industryGroup: string;
    setIndustryGroup: any;
    industry: string;
    setIndustry: any;
    subIndustry: string;
    setSubIndustry: any;
    errorMessage: any;

    createAdCorpField: () => void;
}

const useAdCorpFieldCreate = (): hookMember => {
    const navigate = useNavigate();

    const [section, setSection] = useState<string>("");
    const [industryGroup, setIndustryGroup] = useState<string>("");
    const [industry, setIndustry] = useState<string>("");
    const [subIndustry, setSubIndustry] = useState<string>("");

    const [errorMessage, setErrorMessage] = useState('');

    const createAdCorpField = async () => {
        try {
            if (section === "") {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.')
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/ad-corp-field/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        section,
                        industryGroup,
                        industry,
                        subIndustry
                    })
                })
                if(response.ok) {
                    navigate('/adComponent/adCorpField/adCorpFieldList');
                }
            }
        } catch (error) {
            console.error('광고 산업 분류 생성 중 오류 발생:', error);
        }
    }
    return {
        section,
        setSection,
        industryGroup,
        setIndustryGroup,
        industry,
        setIndustry,
        subIndustry,
        setSubIndustry,
        createAdCorpField,
        errorMessage
    }
}

export default useAdCorpFieldCreate;