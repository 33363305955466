import useUpdate from "./hooks/useUpdate";

const MediaPointUpdate = () => {
  const hookMember = useUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <>
      {!hookMember.loading ? (
        <>
          <div>
            <div className="input-table">
              <div>
                <label>매체명</label>
                <div>{hookMember.data?.media?.name}</div>
              </div>
              <div>
                <label>매체번호</label>
                <div>{hookMember.data?.media?.authNumber}</div>
              </div>
              <div>
                <label>point1_x</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.inputData[0]}
                  onChange={(e) =>
                    hookMember.changeInput(0, Number(e.target.value))
                  }
                />
                {/* <div>{hookMember.data.point1_x}</div> */}
              </div>
              <div>
                <label>point1_y</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.inputData[1]}
                  onChange={(e) =>
                    hookMember.changeInput(1, Number(e.target.value))
                  }
                />
              </div>
              <div>
                <label>point2_x</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.inputData[2]}
                  onChange={(e) =>
                    hookMember.changeInput(2, Number(e.target.value))
                  }
                />
              </div>
              <div>
                <label>point2_y</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={hookMember.inputData[3]}
                  onChange={(e) =>
                    hookMember.changeInput(3, Number(e.target.value))
                  }
                />
              </div>
            </div>
          </div>
          <div className="buttonDiv">
            <button onClick={handleCancel}>취소</button>
            <button onClick={hookMember.updateData}>저장</button>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MediaPointUpdate;
