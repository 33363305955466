export function formatDate(date: Date, format: string): string {
  const pad = (n: number) => (n < 10 ? "0" + n : n);

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // 월은 0부터 시작하므로 +1
  const day = pad(date.getDate());
  const hour = pad(date.getHours());
  const minute = pad(date.getMinutes());
  const second = pad(date.getSeconds());

  return format
    .replace("yyyy", String(year))
    .replace("mm", String(month))
    .replace("dd", String(day))
    .replace("hh", String(hour))
    .replace("MM", String(minute))
    .replace("ss", String(second));
}
