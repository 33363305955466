import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    name: string;
    setName: any
    errorMessage: any;

    createAiDeviceCompany: () => void;
}

const useAiDeviceCompanyCreate = (): hookMember => {
    const navigate = useNavigate();

    const [name, setName] = useState<string>("");

    const [errorMessage, setErrorMessage] = useState('');

    const createAiDeviceCompany = async () => {
        try {
            if (!name) {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.')
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/ai-device-company/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name
                    })
                })
                if(response.ok) {
                    navigate('/aiComponent/aiDeviceCompany/aiDeviceCompanyList');
                }
            }
        } catch (error) {
            console.error('AI 기기 업체 생성 중 오류 발생:', error);
        }
    }
    return {
        name,
        setName,
        createAiDeviceCompany,
        errorMessage
    }
}

export default useAiDeviceCompanyCreate;