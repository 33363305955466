import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
    mediaDeviceId: number;
    setMediaDeviceId: any;
    name: string;
    setName: any;
    modelName: string;
    setModelName: any;
    price: number;
    setPrice: any;
    deviceType: string;
    setDeviceType: any;
    displayType: string;
    setDisplayType: any;
    width: number;
    setWidth: any;
    height: number;
    setHeight: any;
    depth: number;
    setDepth: any;
    resolutionWidth: number;
    setResolutionWidth: any;
    resolutionHeight: number;
    setResolutionHeight: any;
    brand: string;
    setBrand: any;

  errorMessage: any;

  updateMediaDeviceData: () => void;
}

const useMediaDeviceUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [mediaDeviceId, setMediaDeviceId] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [modelName, setModelName] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [deviceType, setDeviceType] = useState<string>("");
  const [displayType, setDisplayType] = useState<string>("");
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [depth, setDepth] = useState<number>(0);
  const [resolutionWidth, setResolutionWidth] = useState<number>(0);
  const [resolutionHeight, setResolutionHeight] = useState<number>(0);
  const [brand, setBrand] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.mediaDeviceId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/media-device/one?id=${params.mediaDeviceId}`
          );
          const jsonData = await response.json();

          const {
            mediaDeviceId,
            name,
            modelName,
            price,
            deviceType,
            displayType,
            width,
            height,
            depth,
            resolutionWidth,
            resolutionHeight,
            brand
          } = jsonData.data;

          setMediaDeviceId(mediaDeviceId);
          setName(name);
          setModelName(modelName);
          setPrice(price);
          setDeviceType(deviceType);
          setDisplayType(displayType);
          setWidth(width);
          setHeight(height);
          setDepth(depth);
          setResolutionWidth(resolutionWidth);
          setResolutionHeight(resolutionHeight);
          setBrand(brand);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.mediaDeviceId]);

  const updateMediaDeviceData = () => {
    const result = {
      mediaDeviceId,
      name,
      modelName,
      price,
      deviceType,
      displayType,
      width,
      height,
      depth,
      resolutionWidth,
      resolutionHeight,
      brand
    };

    if (name === "" || !deviceType || !displayType) {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/media-device/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mediaDeviceId,
            name,
            modelName,
            price,
            deviceType,
            displayType,
            width,
            height,
            depth,
            resolutionWidth,
            resolutionHeight,
            brand
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/mediaComponent/mediaDevice/mediaDeviceList");
      }
    });
  };

  return {
    mediaDeviceId,
    setMediaDeviceId,
    name,
    setName,
    modelName,
    setModelName,
    price,
    setPrice,
    deviceType,
    setDeviceType,
    displayType,
    setDisplayType,
    width,
    setWidth,
    height,
    setHeight,
    depth,
    setDepth,
    resolutionWidth,
    setResolutionWidth,
    resolutionHeight,
    setResolutionHeight,
    brand,
    setBrand,
    errorMessage,
    updateMediaDeviceData,
  };
};

export default useMediaDeviceUpdate;
