import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
  mediaList: any;
  setMediaList: any;
  mediaId: number;
  setMediaId: any;
  comment: string;
  setComment: any;
  images: File[];
  setImages: any;
  videos: File[];
  setVideos: any;
  workDate: Date;
  setWorkDate: any;

  errorMessage: any;

  createDemolish: () => void;
}

const useDemolishCreate = (): hookMember => {
  const navigate = useNavigate();

  const [mediaList, setMediaList] = useState([]);
  const [mediaId, setMediaId] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [images, setImages] = useState<File[]>([]);
  const [videos, setVideos] = useState<File[]>([]);
  const [workDate, setWorkDate] = useState<Date>(new Date());

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const mediaListResponse = await fetch(
          `${process.env.REACT_APP_URL}/media/all`
        );
        const mediaListData = await mediaListResponse.json();

        setMediaList(mediaListData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const createDemolish = async () => {
    try {
      if (!mediaId) {
        setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      } else {
        const commonBody = {
          mediaId,
          category: "demolish",
          comment,
          workDate,
        };

        const imageFormData = new FormData();
        imageFormData.append("mediaId", mediaId.toString());
        imageFormData.append("category", "demolish");
        imageFormData.append("type", "images");
        images.forEach((image) => {
          imageFormData.append('files', image);
        });

        const videoFormData = new FormData();
        videoFormData.append("mediaId", mediaId.toString());
        videoFormData.append("category", "demolish");
        videoFormData.append("type", "videos");
        videos.forEach((video) => {
          videoFormData.append('files', video);
        });

        const response = await fetch(
          `${process.env.REACT_APP_URL}/install-report/create-demolish`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(commonBody),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to create actual image');
        }

        const imageResponse = await fetch(
          `${process.env.REACT_APP_URL}/install-report/upload-files`,
          {
            method: "POST",
            body: imageFormData,
          }
        );

        if (!imageResponse.ok) {
          throw new Error('Failed to upload images');
        }

        const videoResponse = await fetch(
          `${process.env.REACT_APP_URL}/install-report/upload-files`,
          {
            method: "POST",
            body: videoFormData,
          }
        );

        if (!videoResponse.ok) {
          throw new Error('Failed to upload videos');
        }

        navigate("/installReport/installReportList");
      }
    } catch (error) {
      setErrorMessage("네트워크 기기 생성 중 오류 발생");
    }
  };
  
  return {
    mediaList,
    setMediaList,
    mediaId,
    setMediaId,
    comment,
    setComment,
    createDemolish,
    images,
    setImages,
    videos,
    setVideos,
    workDate,
    setWorkDate,
    errorMessage,
  };
};

export default useDemolishCreate;
