import useList from "./hooks/useList";
import "./css/mediaList.scss";

import axios from "axios";
import { Link } from "react-router-dom";
import { SetStateAction, useEffect, useRef, useState } from "react";
import FilterDropdown from "../../../components/atoms/filterDropdown/FilterDropdown";
import {
  CustomColumnProps,
  DynamicTable,
} from "../../../components/table/dynamicTable";
import fetchProfile from "../../../api/userProfile";
import sortedData from "../../../util/sortedData";

const MediaList = () => {
  const {
    data,
    loading,
    currentPage,
    nextPage,
    tableData,
    setTableData,
    columns,
    isFetchingComplete,
  } = useList(0);

  const [isFetching, setIsFetching] = useState(false);
  const [sortBy, setSortBy] = useState("authNumber");
  const [sortDirection, setSortDirection] = useState("asc");
  const observer = useRef<IntersectionObserver | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [profile, setProfile] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  // 계정 정보
  const fetchProfileData = async () => {
    const profileData = await fetchProfile();
    setProfile(profileData);
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  // 무한 스크롤
  useEffect(() => {
    if (!profile) return;
    if (loading || isFetchingComplete) return;

    const tableElement = document.getElementById("table");
    const scrollTriggerDivElement =
      document.getElementById("scroll-trigger-div");

    if (tableElement && scrollTriggerDivElement) {
      scrollTriggerDivElement.style.width = tableElement.clientWidth + "px";
    }

    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting) {
        loadMoreData();
      }
    };

    observer.current = new IntersectionObserver(handleObserver, {
      threshold: 0.2,
    });

    const scrollTriggerElement = document.getElementById("scroll-trigger");
    if (scrollTriggerElement) {
      observer.current.observe(scrollTriggerElement);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, currentPage, isFetching, profile]);

  const loadMoreData = async () => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);

    try {
      nextPage();
    } catch (error) {
      console.error("Error loading more data:", error);
    } finally {
      setIsFetching(false);
    }
  };

  // ems 동기화
  const syncButton = async () => {
    const confirmSync = window.confirm("동기화 하시겠습니까?");

    if (confirmSync) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/media/sync-media`
        );
        window.location.reload();
      } catch (error) {
        console.error("동기화 실패:", error);
      }
    }
  };

  // 엑셀 다운로드
  const downloadExcel = async () => {
    alert("다운로드가 진행 중입니다.");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/media/download-excel`,
        {},
        {
          responseType: "blob",
        }
      );

      // 파일 다운로드를 위한 Blob 생성
      const blob = new Blob([response.data], { type: "text/xlsx" });
      const url = window.URL.createObjectURL(blob);

      // 다운로드 링크 생성 및 클릭
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "매체_테이블.xlsx");
      document.body.appendChild(link);
      link.click();

      // 생성된 URL 해제
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error creating xlsx:", error);
    }
  };

  // 검색 기능
  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const getInitialStatusFilter = () => {
    return localStorage.getItem("statusFilter") || "all";
  };

  const getInitialMethodFilter = () => {
    return localStorage.getItem("methodFilter") || "all";
  };

  const getInitialServiceFilter = () => {
    return localStorage.getItem("serviceFilter") || "all";
  };

  const [statusFilter, setStatusFilter] = useState(getInitialStatusFilter());
  const [methodFilter, setMethodFilter] = useState(getInitialMethodFilter());
  const [serviceFilter, setServiceFilter] = useState(getInitialServiceFilter());

  useEffect(() => {
    localStorage.setItem("statusFilter", statusFilter);
  }, [statusFilter]);

  useEffect(() => {
    localStorage.setItem("methodFilter", methodFilter);
  }, [methodFilter]);

  useEffect(() => {
    localStorage.setItem("serviceFilter", serviceFilter);
  }, [serviceFilter]);

  useEffect(() => {
    const filteredDataArray = sortedData(data, sortBy, sortDirection)
      .filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .filter((item) => {
        if (statusFilter === "all") return true;
        return item.status === statusFilter;
      })
      .filter((item) => {
        if (methodFilter === "all") return true;
        return item.installMethod === methodFilter;
      })
      .filter((item) => {
        if (serviceFilter === "all") return true;
        return item.mediaBusinessType?.business === serviceFilter;
      });

    setSearchResults(filteredDataArray);
  }, [
    searchTerm,
    data,
    sortBy,
    sortDirection,
    statusFilter,
    methodFilter,
    serviceFilter,
  ]);

  return (
    <div>
      {loading ? (
        <p>Loading ...</p>
      ) : (
        <div className="container">
          <>
            <button className="syncButton" onClick={syncButton}>
              EMS 동기화
            </button>
            <button className="excelButton" onClick={downloadExcel}>
              엑셀 다운로드
            </button>
            <div className="header">
              <h1>매체 리스트</h1>
              <FilterDropdown
                columns={columns}
                searchTerm={searchTerm}
                tableData={tableData}
                allData={data}
                setTableData={setTableData}
              />
            </div>
            <div className="table-container">
              <DynamicTable data={tableData} />
              <div id="scroll-trigger-div">
                <div id="scroll-trigger" />
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default MediaList;
