import useAiDeviceCompanyUpdate from "./hooks/useAiDeviceCompanyUpdate";

const AiDeviceCompanyUpdate = () => {
  const hookMember = useAiDeviceCompanyUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>업체명  <span className="required">*</span>
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.name}
            onChange={(e) => hookMember.setName(e.target.value)}
          />
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.updateAiDeviceCompanyData}>저장</button>
      </div>
    </div>
  );
};

export default AiDeviceCompanyUpdate;
