import { useEffect, useState } from "react";

interface NetworkDevice {
  networkDeviceId: number;
  label: string;
  currentState: string;
  aiDevice: string;
  networkDeviceType: string;
}

enum NetworkDeviceProperties {
  LABEL = "라벨",
  DEVICE_TYPE = "기기 종류",
  AI_DEVICE = "AI 기기",
  CURRENT_STATE = "현재 상태",
}

const useNetworkDeviceList = (initialPage: number) => {
  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (networkDeviceId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/network-device/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ networkDeviceId: networkDeviceId }),
      })
        .then(async (response) => {
          if ((await response.json()) === 1451) {
            // 키 참조 에러처리
            alert("삭제에 실패했습니다.");
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          window.location.reload();
        });
    }
  };

  const transformData = (
    networkDeviceData: any[]
  ): Record<NetworkDeviceProperties, any>[] => {
    return networkDeviceData.map((device: any) => ({
      [NetworkDeviceProperties.LABEL]: device.label,
      [NetworkDeviceProperties.DEVICE_TYPE]: device.networkDeviceType
        ? device.networkDeviceType.name
        : "N/A",
      [NetworkDeviceProperties.AI_DEVICE]: device.aiDevice
        ? device.aiDevice.label
        : "N/A",
      [NetworkDeviceProperties.CURRENT_STATE]: device.currentState,
    }));
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/network-device/list?page=${page}`
      );

      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/network-device/list`
      );

      if (!countResponse.ok) {
        throw new Error("Network request failed");
      }

      const countJsonData = await countResponse.json();

      const totalDataCount = countJsonData.data.count;
      setCount(totalDataCount);
      // 모든 데이터를 가져온 경우 fetch 종료
      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    handleDeleteClick,
    columns,
    tableData,
    setTableData,
    isFetchingComplete,
  };
};

export default useNetworkDeviceList;
