import { useEffect, useState } from "react";

enum MediaProperties {
  NAME = "매체명",
  "mediaBusinessType.business" = "서비스명",
  AUTH_NUMBER = "매체번호",
  SERIAL_NUMBER = "매체 시리얼",
  ATTRACTIVENESS = "매력도",
  PERFORMANCE = "평균 실적",
  SCHEDULE_UNIT = "스케줄 단위",
  INSTALL_METHOD = "설치 방법",
  INSTALL_HEIGHT = "설치 높이",
  STATUS = "매체 상태",
  VISIBLE_AREA = "가시면적",
  VISIBLE_DISTANCE = "가시거리",
  IS_INDOOR = "실내외 구분",
  "site.name" = "장소",
  "mediaDevice.name" = "매체 기기",
  SIZE = "사이즈",
  "aiDevice.label" = "AI 기기",
  INSTALL_DATE = "설치 날짜",
  CONTRACTOR = "매체 계약자",
  LUMINANCE = "밝기",
  VIEWING_ANGLE = "시야각",
}

const useList = (initialPage: number) => {
  const transformData = (rawData: any[]): Record<MediaProperties, any>[] => {
    return rawData.map((item) => ({
      [MediaProperties.NAME]: item.name,
      [MediaProperties["mediaBusinessType.business"]]:
        item.mediaBusinessType?.business,
      [MediaProperties.AUTH_NUMBER]: item.authNumber,
      [MediaProperties.SERIAL_NUMBER]: item.serialNumber,
      [MediaProperties.ATTRACTIVENESS]: item.attractiveness,
      [MediaProperties.PERFORMANCE]: item.performance,
      [MediaProperties.SCHEDULE_UNIT]: item.scheduleUnit,
      [MediaProperties.INSTALL_METHOD]: item.installMethod,
      [MediaProperties.INSTALL_HEIGHT]: item.installHeight,
      [MediaProperties.STATUS]: item.status,
      [MediaProperties.VISIBLE_AREA]: item.visibleArea,
      [MediaProperties.VISIBLE_DISTANCE]: item.visibleDistance,
      [MediaProperties.IS_INDOOR]: item.isIndoor,
      [MediaProperties["site.name"]]: item.site?.name,
      [MediaProperties["mediaDevice.name"]]: item.mediaDevice?.name,
      [MediaProperties.SIZE]: item.size,
      [MediaProperties["aiDevice.label"]]: item.aiDevice?.label,
      [MediaProperties.INSTALL_DATE]: item.installDate,
      [MediaProperties.CONTRACTOR]: item.contractor,
      [MediaProperties.LUMINANCE]: item.luminance,
      [MediaProperties.VIEWING_ANGLE]: item.viewingAngle,
    }));
  };
  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/media/list?page=${page}`
      );
      const jsonData = await response.json();

      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      setCount(jsonData.data.count);

      if (
        transformedData.length === 0 ||
        jsonData.data.count <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    previousPage,
    columns,
    tableData,
    setTableData,
    count,
    isFetchingComplete,
  };
};

export default useList;
