import { useEffect, useState } from "react";
import axios from "axios";
import { formatDate } from "../../../util/dateFormat";

interface TableType {
  [key: string]: string;
}

interface HookMediaHistoryDetail {
  selectedMediaDetails: any[];
  setSelectedMediaDetails: (details: any[]) => void;
  selectedResource: any[];
  historyTableData: TableType[];
  setHistoryTableData: (any: TableType[]) => void;
  columns: string[];
  loadHistoryData: (mediaId: string) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  setIsOpenResourceHistory: (arg0: boolean) => void;
  isOpenResourceHistory: boolean;
  viewResourceList: (authNumber: number) => void;
  resourceList: any[];
}

export const useMediaVersionHistoryDetail = (
  mediaId: number | undefined
): HookMediaHistoryDetail => {
  const [selectedMediaDetails, setSelectedMediaDetails] = useState<any[]>([]);
  const [historyTableData, setHistoryTableData] = useState<TableType[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [selectedHistoryColumns, setSelectedHistoryColumns] = useState<{
    [key: string]: boolean;
  }>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredHistoryItems, setFilteredHistoryItems] = useState<any[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<string>("name");

  const [selectedResource, setSelectedResource] = useState<any[]>([]);

  const [isOpenResourceHistory, setIsOpenResourceHistory] =
    useState<boolean>(false);

  const [resourceList, setResourceList] = useState<any[]>([]);
  const loadHistoryData = (mediaId: any) => {
    axios
      .get(`${process.env.REACT_APP_URL}/media/version-history/${mediaId}`)
      .then((response) => {
        const historyData = response.data
          .map((history: any) => ({
            name: history.media.name,
            authNumber: history.media.authNumber,
            isTest: history.version.isTest ? "True" : "False",
            businessType: history.media.mediaBusinessType.business,
            versionType: history.version.versionType,
            versionNumber: `${history.version.major}.${history.version.minor}.${history.version.patch}`,
            updatedAt: new Date(history.updateTime),
          }))
          .sort(
            (a: any, b: any) => b.updatedAt.getTime() - a.updatedAt.getTime()
          )
          .map((history: any) => ({
            ...history,
            updatedAt: formatDate(history.updatedAt, "yyyy-mm-dd hh:MM:ss"),
          }));

        setSelectedMediaDetails(historyData);
        if (historyData.length > 0) {
          const columns = Object.keys(historyData[0]);
          setColumns(columns);
          const initialSelectedColumns = columns.reduce((acc, column) => {
            acc[column] = true; // 기본적으로 모든 컬럼 선택
            return acc;
          }, {} as { [key: string]: boolean });
          setSelectedHistoryColumns(initialSelectedColumns);
        }
      })
      .catch((error) => {
        console.error("Error fetching media history:", error);
      });
  };

  const loadResourceData = (mediaId: any) => {
    axios
      .get(`${process.env.REACT_APP_URL}/media-resource-history/${mediaId}`)
      .then((response) => {
        const resourceData = response.data
          .map((resource: any) => ({
            name: resource.resource.fileName,

            isTest: resource.resource.isTest ? "True" : "False",
            fileSize: resource.resource.fileSize,
            updatedAt: new Date(resource.updateTime),
          }))
          .sort(
            (a: any, b: any) => b.updatedAt.getTime() - a.updatedAt.getTime()
          )
          .map((resource: any) => ({
            ...resource,
            updatedAt: formatDate(resource.updatedAt, "yyyy-mm-dd hh:MM:ss"),
          }));

        setSelectedResource(resourceData);
      })
      .catch((error) => {
        console.error("Error fetching media history:", error);
      });
  };

  const viewResourceList = (item: any) => {
    // 모든 리소스를 보여주기 위해 필터링 로직을 제거하고 리소스 리스트를 그대로 사용
    const newItem = {
      ...item,
      highlight: true, // 이 아이템을 강조하기 위한 속성
    };
    setResourceList(selectedResource);

    setResourceList((prevResources) => {
      // 새 아이템을 배열에 추가하고 시간순 내림차순으로 정렬
      const updatedResources = [...prevResources, newItem];
      updatedResources.sort(
        (a: any, b: any) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
      return updatedResources;
    });
    setIsOpenResourceHistory(true);
  };

  useEffect(() => {
    loadHistoryData(mediaId);
    loadResourceData(mediaId);
  }, [mediaId]);

  useEffect(() => {}, [isOpenResourceHistory]);
  return {
    selectedMediaDetails,
    historyTableData,
    columns,
    setSelectedMediaDetails,
    loadHistoryData,
    searchTerm,
    setSearchTerm,
    setHistoryTableData,
    setIsOpenResourceHistory,
    isOpenResourceHistory,
    viewResourceList,
    selectedResource,
    resourceList,
  };
};
