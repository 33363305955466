interface TableHeaderProps {
  columns: string[];
  onSort: (index: number) => void;
  sortConfig: { index: number | null; direction: "asc" | "desc" | null };
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  columns,
  onSort,
  sortConfig,
}) => {
  return (
    <thead>
      <tr>
        {columns.map((column, index) => (
          <th
            key={index}
            onClick={() => onSort(index)}
            className="sortable-column"
          >
            {column}
            {sortConfig.index === index
              ? sortConfig.direction === "asc"
                ? " ▲"
                : " ▼"
              : ""}
          </th>
        ))}
      </tr>
    </thead>
  );
};
