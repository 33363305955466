import React from "react";
import "./memoCard.scss"; // SCSS 파일 사용

// MemoCard 컴포넌트의 프로퍼티 타입 정의
interface MemoCardProps {
  title: string;
  description: string;
  memoText: string;
  handleMemoChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSaveMemo: () => void;
  handleCancle: () => void;
}

const MemoCard: React.FC<MemoCardProps> = ({
  title,
  description,
  memoText,
  handleMemoChange,
  handleSaveMemo,
  handleCancle,
}) => {
  return (
    <div className="overlay">
      <div className="memo-card">
        <div className="memo-card__header">
          <div className="memo-card__title">{title}</div>
          <div className="memo-card__description">{description}</div>
        </div>
        <div className="memo-card__content">
          <textarea
            value={memoText}
            onChange={handleMemoChange}
            placeholder="Write your memo here..."
            className="memo-card__textarea"
          />
        </div>
        <div className="memo-card__footer">
          <button className="memo-card__button" onClick={handleSaveMemo}>
            저장
          </button>
          <button className="memo-card__button" onClick={handleCancle}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default MemoCard;
