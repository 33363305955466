import { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';

interface RealEffectData {
  period: string;
  mediaId: string;
  [key: string]: any; 
}

interface UseDailyRealEffectGraphsListProps {
  mediaIds: string[];
  startDate: string;
  endDate: string;
}

const validateInputs = (mediaIds: string[], startDate: string, endDate: string) => {
  const errors: string[] = [];

  if (!mediaIds.length || mediaIds.some(id => !id.trim())) {
    errors.push('At least one valid Media ID is required.');
  }
  
  if (!startDate || !isValidDate(startDate)) {
    errors.push('Start date is invalid.');
  }
  
  if (!endDate || !isValidDate(endDate)) {
    errors.push('End date is invalid.');
  }

  if (new Date(startDate) > new Date(endDate)) {
    errors.push('Start date cannot be after end date.');
  }

  return errors;
};

const isValidDate = (dateStr: string): boolean => {
  const date = new Date(dateStr);
  return !isNaN(date.getTime());
};

const useDailyRealEffectGraphsList = ({ mediaIds, startDate, endDate }: UseDailyRealEffectGraphsListProps) => {
  const [data, setData] = useState<RealEffectData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const allData: RealEffectData[] = [];

        for (const mediaId of mediaIds) {
          try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/daily-real-effect/sum`, {
              params: { mediaId, startDate, endDate, groupBy: 'daily' },
            });
            
            const responseData = response.data.data;

            if (Array.isArray(responseData)) {
              allData.push(...responseData.map((item: RealEffectData) => ({ ...item, mediaId })));
            } else {
              throw new Error('Unexpected data format: response.data.data is not an array.');
            }
          } catch (err) {
            console.error(`Error fetching data for mediaId ${mediaId}:`, err);

            if (axios.isAxiosError(err)) {
              const errorMessage = err.response?.data?.message || err.message || 'Failed to fetch data';
              setError(`Error for Media ID ${mediaId}: ${errorMessage}`);
            } else {
              setError(`An unexpected error occurred for Media ID ${mediaId}.`);
            }
            break; // Exit loop on error
          }
        }

        setData(allData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data.');
      } finally {
        setLoading(false);
      }
    };

    const validationErrors = validateInputs(mediaIds, startDate, endDate);
    if (validationErrors.length === 0) {
      fetchData();
    } else {
      setError(validationErrors.join(' '));
      setLoading(false);
    }
  }, [mediaIds, startDate, endDate]);

  return { data, loading, error };
};

export default useDailyRealEffectGraphsList;
