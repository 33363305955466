import useAdUpdate from "./hooks/useAdUpdate";

const AdUpdate = () => {
  const hookMember = useAdUpdate();

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="input-table">
        {hookMember.errorMessage && (
          <div style={{ color: "red" }}>{hookMember.errorMessage}</div>
        )}
        <div>
          <label>광고명</label>
          <div>{hookMember.videoName}</div>
        </div>
        <div>
          <label>
            생성일
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hookMember.createdAt}
            onChange={(e) => hookMember.setCreatedAt(e.target.value)}
          />
        </div>
        <div>
          <label>광고 종류</label>
          <select
            name=""
            id=""
            value={hookMember.type}
            onChange={(e) => hookMember.setType(e.target.value)}
          >
            <option value="">선택해 주세요</option>
            <option value="구좌">구좌</option>
            <option value="CPV">CPV</option>
            <option value="크레딧">크레딧</option>
            <option value="매체 자체 광고">매체 자체 광고</option>
            <option value="명화">명화</option>
            <option value="애드 광고">애드 광고</option>
            <option value="공익 광고">공익 광고</option>
          </select>
        </div>
      </div>
      <div className="buttonDiv">
        <button onClick={handleCancel}>취소</button>
        <button onClick={hookMember.updateAdData}>저장</button>
      </div>
    </div>
  );
};

export default AdUpdate;
