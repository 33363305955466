import React from "react";
import "./css/SidebarItem.scss";
import { NavLink } from "react-router-dom";

interface SidebarItemProps {
  menu: {
    name: string;
    path: string;
  };
  active?: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ menu, active }) => {
  return (
    <div className={`sidebar-item ${active}`}>
      <div>
        <p>{menu.name}</p>
      </div>
    </div>
  );
};

export default SidebarItem;
