import { useEffect, useState } from "react";

enum NetworkDeviceTypeProperties {
  NAME = "기기명",
  CONNECTION_TYPE = "연결 방식",
  DEVICE_PRICE = "기기 가격",
  MONTHLY_FEE = "월 요금",
  LABEL_PREFIX = "라벨 Prefix",
  COMPANY = "회사명",
  MODEL_NAME = "모델명",
}

const useNetworkDeviceTypeList = (initialPage: number) => {
  const transformData = (
    rawData: any[]
  ): Record<NetworkDeviceTypeProperties, any>[] => {
    return rawData.map((item) => ({
      [NetworkDeviceTypeProperties.NAME]: item.name,
      [NetworkDeviceTypeProperties.CONNECTION_TYPE]: item.connectionType,
      [NetworkDeviceTypeProperties.DEVICE_PRICE]: item.devicePrice,
      [NetworkDeviceTypeProperties.MONTHLY_FEE]: item.monthlyFee,
      [NetworkDeviceTypeProperties.LABEL_PREFIX]: item.labelPrefix,
      [NetworkDeviceTypeProperties.COMPANY]: item.company,
      [NetworkDeviceTypeProperties.MODEL_NAME]: item.modelName,
    }));
  };

  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (networkDeviceTypeId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/network-device-type/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ networkDeviceTypeId: networkDeviceTypeId }),
      })
        .then(async (response) => {
          if ((await response.json()) === 1451) {
            // 키 참조 에러처리
            alert("삭제에 실패했습니다.");
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          window.location.reload();
        });
    }
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/network-device-type/list?page=${page}`
      );

      const jsonData = await response.json();
      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }

      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/network-device-type/list`
      );

      if (!countResponse.ok) {
        throw new Error("Network request failed");
      }

      const countJsonData = await countResponse.json();

      const totalDataCount = countJsonData.data.count;
      setCount(totalDataCount);
      // 모든 데이터를 가져온 경우 fetch 종료
      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
    handleDeleteClick,
    isFetchingComplete,
  };
};

export default useNetworkDeviceTypeList;
