import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
    aiDeviceTypeId: number;
    setAIDeviceTypeId: any;
    modelName: string;
    setModelName: any;
    company: string;
    setCompany: any;
    price: number;
    setPrice: any;
    labelPrefix: string;
    setLabelPrefix: any;

    aiModuleId: number;
    setAIModuleId: any;
    aiModuleList: any;
    setAIModuleList: any;

    aiDeviceCompanyId: number;
  setAIDeviceCompanyId: any;
  aiDeviceCompanyList: any;
  setAIDeviceCompanyList: any;

    errorMessage: any;

    updateAIDeviceTypeData: () => void;
}

const useAIDeviceTypeUpdate = (): hookMember => {
    const params = useParams();

    const navigate = useNavigate();
    const [aiDeviceTypeId, setAIDeviceTypeId] = useState<number>(0);
    const [modelName, setModelName] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [price, setPrice] = useState<number>(0);
    const [labelPrefix, setLabelPrefix] = useState<string>("");
    const [aiModuleId, setAIModuleId] = useState<number>(0);
    const [aiModuleList, setAIModuleList] = useState([]);

    const [aiDeviceCompanyId, setAIDeviceCompanyId] = useState<number>(0);
  const [aiDeviceCompanyList, setAIDeviceCompanyList] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.aiDeviceTypeId) {
                    const response = await fetch(`${process.env.REACT_APP_URL}/ai-device-type/one?id=${params.aiDeviceTypeId}`);
                    const jsonData = await response.json();

                    const aiModuleListResponse = await fetch(`${process.env.REACT_APP_URL}/ai-module/all`);
                    const aiModuleListData = await aiModuleListResponse.json();

                    const aiDeviceCompanyListReponse = await fetch(
                        `${process.env.REACT_APP_URL}/ai-device-company/all`
                      );
                      const aiDeviceCompanyListData = await aiDeviceCompanyListReponse.json();

                    const { aiDeviceTypeId, modelName, company, price, labelPrefix, aiModuleId, aiDeviceCompanyId} = jsonData.data;

                    setAIModuleId(aiModuleId);
                    setAIModuleList(aiModuleListData);

                    setAIDeviceCompanyId(aiDeviceCompanyId);
                    setAIDeviceCompanyList(aiDeviceCompanyListData);

                    setAIDeviceTypeId(aiDeviceTypeId);
                    setModelName(modelName);
                    setCompany(company);
                    setPrice(price);
                    setLabelPrefix(labelPrefix);
                }
            } catch(error) {
                console.log(error);
            }
        }; 
        fetchData();
    }, [params.aiDeviceTypeId]);

    const updateAIDeviceTypeData = () => {
        const result = {
            aiDeviceTypeId,
            modelName,
            company,
            price,
            labelPrefix,
            aiModuleId,
            aiDeviceCompanyId
        }
        
        if (modelName === "" || !aiModuleId || !aiDeviceCompanyId) {
            setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.');
            return;
        }

        const fetchData = async () => {
            const res = await fetch(`${process.env.REACT_APP_URL}/ai-device-type/update`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    aiDeviceTypeId,
                    modelName,
                    company,
                    price,
                    labelPrefix,
                    aiModuleId,
                    aiDeviceCompanyId
                }),
            });
            const result = res.json();
            return result;
        }
        fetchData().then((res) => {
            if (res.status == 200) {
              navigate('/aiComponent/aiDeviceType/aiDeviceTypeList')
            }
          });
    }

    return {
        aiDeviceTypeId,
        setAIDeviceTypeId,
        modelName,
        setModelName,
        company,
        setCompany,
        price,
        setPrice,
        labelPrefix,
        setLabelPrefix,
        updateAIDeviceTypeData,
        aiModuleId,
        setAIModuleId,
        aiModuleList,
        setAIModuleList,
        errorMessage,
        aiDeviceCompanyId,
        setAIDeviceCompanyId,
        aiDeviceCompanyList,
        setAIDeviceCompanyList
    }
}

export default useAIDeviceTypeUpdate;