import { useEffect, useState } from "react";

enum AiDeviceTypeProperties {
  MODEL_NAME = "모델명",
  "aiDeviceCompany.name" = "업체",
  PRICE = "가격",
  LABEL_PREFIX = "라벨 Prefix",
  "aiModule.name" = "AI 모듈",
}

const useAIDeviceTypeList = (initialPage: number) => {
  const transformData = (
    rawData: any[]
  ): Record<AiDeviceTypeProperties, any>[] => {
    return rawData.map((item) => ({
      [AiDeviceTypeProperties.MODEL_NAME]: item.modelName,
      [AiDeviceTypeProperties["aiDeviceCompany.name"]]:
        item.aiDeviceCompany?.name,
      [AiDeviceTypeProperties.PRICE]: item.price,
      [AiDeviceTypeProperties.LABEL_PREFIX]: item.labelPrefix,
      [AiDeviceTypeProperties["aiModule.name"]]: item.aiModule?.name,
    }));
  };

  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const handleDeleteClick = (aiDeviceTypeId: string) => {
    const result = window.confirm("삭제하시겠습니까?");
    if (result) {
      fetch(`${process.env.REACT_APP_URL}/ai-device-type/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ aiDeviceTypeId: aiDeviceTypeId }),
      })
        .then(async (response) => {
          if ((await response.json()) === 1451) {
            // 키 참조 에러처리
            alert("삭제에 실패했습니다.");
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          alert("삭제에 실패하였습니다.");
        });
    }
  };

  const fetchData = async (page: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/ai-device-type/list?page=${page}`
      );
      const jsonData = await response.json();

      const transformedData = transformData(jsonData.data.list || []);
      //원본 전체 데이터 보여지는데이터는 tableData로 관리
      setData((prevData) => [...prevData, ...transformedData]);

      if (columns.length === 0 && transformedData.length > 0) {
        const newColumns = Object.keys(transformedData[0]);
        setColumns(newColumns);
      }
      const countResponse = await fetch(
        `${process.env.REACT_APP_URL}/ai-device-type/list`
      );
      const countJsonData = await countResponse.json();
      const totalDataCount = countJsonData.data.count;
      setCount(countJsonData.data.count);

      if (
        transformedData.length === 0 ||
        totalDataCount <= data.length + transformedData.length
      ) {
        setIsFetchingComplete(true);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  return {
    data,
    loading,
    currentPage,
    nextPage,
    count,
    columns,
    tableData,
    setTableData,
    handleDeleteClick,
    isFetchingComplete,
  };
};

export default useAIDeviceTypeList;
