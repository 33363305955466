import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface hookMember {
  mediaNetworkId: number;
  setMediaNetworkId: any;
  mediaId: number;
  setMediaId: any;
  mediaList: any;
  setMediaList: any;
  date: string;
  setDate: any;
  success: number;
  setSuccess: any;
  fail: number;
  setFail: any;
  errorCount: number;
  setErrorCount: any;
  errorCount10: number;
  setErrorCount10: any;
  maxErrorTime: number;
  setMaxErrorTime: any;

  errorMessage: any;

  updateMediaNetworkData: () => void;
}

const useMediaNetworkUpdate = (): hookMember => {
  const params = useParams();

  const navigate = useNavigate();
  const [mediaNetworkId, setMediaNetworkId] = useState<number>(0);
  const [mediaId, setMediaId] = useState<number>(0);
  const [mediaList, setMediaList] = useState([]);
  const [date, setDate] = useState<string>("");
  const [success, setSuccess] = useState<number>(0);
  const [fail, setFail] = useState<number>(0);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [errorCount10, setErrorCount10] = useState<number>(0);
  const [maxErrorTime, setMaxErrorTime] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.mediaNetworkId) {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/media-network/one?id=${params.mediaNetworkId}`
          );
          const jsonData = await response.json();
          const mediaListResponse = await fetch(
            `${process.env.REACT_APP_URL}/media/all`
          );
          const mediaListData = await mediaListResponse.json();

          setMediaList(mediaListData);

          const {
            mediaNetworkId,
            mediaId,
            date,
            success,
            fail,
            errorCount,
            errorCount10,
            maxErrorTime,
          } = jsonData.data;

          setMediaNetworkId(mediaNetworkId);
          setMediaId(mediaId);
          setDate(date);
          setSuccess(success);
          setFail(fail);
          setErrorCount(errorCount);
          setErrorCount10(errorCount10);
          setMaxErrorTime(maxErrorTime);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.mediaNetworkId]);

  const updateMediaNetworkData = () => {
    const result = {
      mediaNetworkId,
      mediaId,
      date,
      success,
      fail,
      errorCount,
      errorCount10,
      maxErrorTime,
    };

    if (!mediaId) {
      setErrorMessage("필수 값이 입력되지 않아 저장되지 않았습니다.");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/media-network/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mediaNetworkId,
            mediaId,
            date,
            success,
            fail,
            errorCount,
            errorCount10,
            maxErrorTime,
          }),
        }
      );
      const result = res.json();
      return result;
    };
    fetchData().then((res) => {
      if (res.status == 200) {
        navigate("/mediaComponent/mediaNetwork/mediaNetworkList");
      }
    });
  };

  return {
    mediaNetworkId,
    setMediaNetworkId,
    mediaId,
    setMediaId,
    mediaList,
    setMediaList,
    date,
    setDate,
    success,
    setSuccess,
    fail,
    setFail,
    errorCount,
    setErrorCount,
    errorCount10,
    setErrorCount10,
    maxErrorTime,
    setMaxErrorTime,
    errorMessage,
    updateMediaNetworkData,
  };
};

export default useMediaNetworkUpdate;
