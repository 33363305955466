import React, { useState, useEffect } from 'react';
import LoginForm from './LoginForm';
import AppRoute from '../../../routes/AppRoute';
import { jwtDecode } from "jwt-decode";

const LoginPage: React.FC = () => {
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    const storedToken = sessionStorage.getItem('token');
    if (storedToken) {
      // 토큰 유효성 검사
      const decodedToken: any = jwtDecode(storedToken);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        // 토큰이 만료되었으면 sessionStorage에서 제거
        sessionStorage.removeItem('token');
      } else {
        setToken(storedToken);
      }
    }
  }, []);


  const handleLogin = async (credentials: { email: string; password: string; }) => {
    try {
      // 이전에 저장된 토큰 제거
      sessionStorage.removeItem('token');
      
      const response = await fetch(`${process.env.REACT_APP_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      if (!response.ok) {
        throw new Error('Invalid credentials');
      }
      const data = await response.json();
      if (data.status === 200) {
        sessionStorage.setItem('token', data.data);
        setToken(data.data);
        // 새로운 토큰 저장
      } else if (data.status === 400) {
        alert('아이디(이메일) 또는 비밀번호를 잘못 입력했습니다.\n입력하신 내용을 다시 확인해주세요.')
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div>
      {token ? (
        // 로그인이 성공했을 때 대시보드 페이지로 이동
        <AppRoute />
      ) : (
        // 로그인 폼을 렌더링
        <div>
        <LoginForm onSubmit={handleLogin} />
      </div>
      )}
    </div>
  );
}

export default LoginPage;