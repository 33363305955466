import { useEffect, useState } from "react";
import axios from "axios";
import { formatDate } from "../../../util/dateFormat";
import { useNavigate } from "react-router-dom";

interface MediaItem {
  name: string;
  authNumber: string;
  businessType: string;
  versionType: string;
  currentVersion: string;
  lastUpdated: string;
  [key: string]: string;
}

interface TableType {
  [key: string]: string;
}

interface HookMediaVersionHistory {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  mediaItems: MediaItem[];
  mediaTableData: TableType[];
  columns: string[];

  viewVersionHistory: (mediaItem: MediaItem) => void;
  viewResourceHistory: (mediaItem: MediaItem) => void;
  setMediaTableData: (data: TableType[]) => void;
}

export const useMediaVersionHistory = (): HookMediaVersionHistory => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);
  const [mediaTableData, setMediaTableData] = useState<TableType[]>([]);
  const [columns, setColumns] = useState<string[]>([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/media/with-version-history?page=0`)
      .then((response) => {
        const transformedData: MediaItem[] = response?.data?.map(
          (media: any) => ({
            name: media.name,
            authNumber: String(media.authNumber),
            fileName: media.version.fileName,
            isTest: media.version.isTest ? "True" : "False",
            versionType: media.version.versionType,
            currentVersion: `${media.version.major}.${media.version.minor}.${media.version.patch}`,
            lastUpdated: formatDate(
              new Date(media.version.uploadTime),
              "yyyy-mm-dd hh:MM:ss"
            ),
          })
        );

        setMediaItems(transformedData);
        if (columns.length === 0 && transformedData.length > 0) {
          const newColumns = Object.keys(transformedData[0]);
          setColumns(newColumns);
        }
      })
      .catch((error) => {
        console.error("Error fetching media data:", error);
      });
  }, []);

  const viewVersionHistory = (mediaItem: MediaItem) => {
    navigate(`/versionManager/history/${mediaItem.authNumber}`);
  };

  const viewResourceHistory = (mediaItem: MediaItem) => {
    navigate(`/versionManager/history/resource/${mediaItem.authNumber}`);
  };

  return {
    searchTerm,
    setSearchTerm,
    mediaItems,
    mediaTableData,
    setMediaTableData,
    columns,
    viewVersionHistory,
    viewResourceHistory,
  };
};
