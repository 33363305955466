import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface hookMember {
    modelName: string;
    setModelName: any;
    company: string;
    setCompany: any;
    frameWidthMax: number;
    setFrameWidthMax: any;
    frameHeightMax: number;
    setFrameHeightMax: any;
    connectionType: string;
    setConnectionType: any;
    maxFPS: number;
    setMaxFPS: any;
    price: number;
    setPrice: any;
    diagonalAngle: number;
    setDiagonalAngle: any;
    horizontalAngle: number;
    setHorizontalAngle: any;
    verticalAngle: number;
    setVerticalAngle: any;

    errorMessage: any;
    createCameraType: () => void;
}

const useCameraTypeCreate = (): hookMember => {
    const navigate = useNavigate();

    const [modelName, setModelName] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [frameWidthMax, setFrameWidthMax] = useState<number>(0);
    const [frameHeightMax, setFrameHeightMax] = useState<number>(0);
    const [connectionType, setConnectionType] = useState<string>("USB");
    const [maxFPS, setMaxFPS] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [diagonalAngle, setDiagonalAngle] = useState<number>(0);
    const [horizontalAngle, setHorizontalAngle] = useState<number>(0);
    const [verticalAngle, setVerticalAngle] = useState<number>(0); 

    const [errorMessage, setErrorMessage] = useState('');

    const createCameraType = async () => {
        try {
            if (modelName === "") {
                setErrorMessage('필수 값이 입력되지 않아 저장되지 않았습니다.');
            } else {
                const response = await fetch(`${process.env.REACT_APP_URL}/camera-type/create`, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    modelName,
                    company,
                    frameWidthMax,
                    frameHeightMax,
                    connectionType,
                    maxFPS,
                    price,
                    diagonalAngle,
                    horizontalAngle,
                    verticalAngle
                })
            })
            if (response.ok) {
                navigate('/cameraComponent/cameraType/cameraTypeList');
            } else {
                console.error('카메라 생성에 실패했습니다.');
            }
            }
        } catch (error) {
            console.error('카메라 생성 중 오류 발생:', error);
        }
    }

    return {
        modelName,
        setModelName,
        company,
        setCompany,
        frameWidthMax,
        setFrameWidthMax,
        frameHeightMax,
        setFrameHeightMax,
        connectionType,
        setConnectionType,
        maxFPS,
        setMaxFPS,
        price,
        setPrice,
        diagonalAngle,
        setDiagonalAngle,
        horizontalAngle,
        setHorizontalAngle,
        verticalAngle,
        setVerticalAngle,
        errorMessage,
        createCameraType
    }
}

export default useCameraTypeCreate;